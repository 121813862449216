/**
 * Style Name: SgmStyles
 * Description: Styles for SGM Layout
 * Param: void
 * Return: class styles
 * Author: Gian
 * Last Update By: Gian
 */
import { makeStyles } from '@material-ui/core/styles';

const SgmStyles = makeStyles(theme => ({
    graphOrder: {
        order: 2,
        [theme.breakpoints.up("sm")]: {
            order: 1
        }
    },
    billOrder: {
        order: 1,
        [theme.breakpoints.up("sm")]: {
            order: 2
        }
    },
    card: {
        padding: theme.spacing(3, 2),
        [theme.breakpoints.up("sm")]: {
            height: window.screen.height / 4
        },
        [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
            height: 'auto'
        }
    },
    select: {
        display: 'block'

    }
}));

export { SgmStyles };
