import axios from 'axios';
import {
  AUTH_SERVER_ADDRESS,
  AUTH_LOGIN_SUCCESS,
  AUTH_PASSWORD_REQUEST,
  AUTH_LOGIN_INVALID_CREDENTIALS,
  AUTH_LOGIN_INVALID_EMAIL_ADDRESS,
  AUTH_LOGIN_FAILED,
  AUTH_CHANGE_PASS_SUCCESS,
  AUTH_CHANGE_PASS_INVALID_CREDENTIALS,
  AUTH_CHANGE_PASS_INVALID_PASSWORD,
  AUTH_INVALID_PASSWORD_FORMAT,
  AUTH_CHANGE_PASS_FAILED,
  AUTH_FORCE_CHANGE_PASS_SUCCESS,
  AUTH_FORCE_CHANGE_PASS_FAILED,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILED,
  AUTH_UPDATE,
  AUTH_EXPIRY,
  AUTH_REFRESH_TOKEN_SUCCESS,
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_FORGOT_PASSWORD_REQUEST_FAIL,
  AUTH_FORGOT_PASSWORD_REQUEST_INVALID_EMAIL,
  MAXIMUM_FORGOT_PASSWORD_ATTEMPT,
  UNEXPECTED_ERROR_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_FORGOT_PASSWORD_INVALID_CODE,
  AUTH_FORGOT_PASSWORD_INVALID_NEW_PASS_FORMAT,
  AUTH_FORGOT_PASSWORD_USER_DOES_NOT_EXIST,
  AUTH_FORGOT_PASSWORD_MISSING_DETAILS,
  AUTH_FORGOT_PASSWORD_EXPIRED_CODE,
  ORGANIZATION_INACTIVE,
  SESSION_EXPIRY_MESSAGE,
} from './types';
import { errorHandler } from '../helpers';
import { persistor, store } from '../store';

const ROOT_URL = AUTH_SERVER_ADDRESS;

export const getHeaders = async () => {
  const HEADERS = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + store.getState().auth.AccessToken,
    },
  };
  return HEADERS;
};

/**
 * Action Name: login
 * Description:
 *      checks user credentials and if successful it will direct it to DashboardPage
 * Param: payload
 * Return: ChallengeName,
 *          AccessToken,
 *          ExpiresIn,
 *          TokenType,
 *          RefreshToken,
 *          IdToken,
 *          permissions,
 *          organization_id,
 *          authStatus
 *          with data from the payload
 * Author: Jeremiah
 * Last Update By: Everard
 */
export const login = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(`${ROOT_URL}/users/login`, payload);
    if (response.status === 200) {
      if (response.data.ChallengeName !== 'NEW_PASSWORD_REQUIRED') {
        dispatch({
          type: AUTH_LOGIN_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: AUTH_PASSWORD_REQUEST,
          payload: response.data,
        });
      }
    }
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({
        type: AUTH_LOGIN_INVALID_CREDENTIALS,
        error: error,
      });
    } else if (error.response.status === 400) {
      dispatch({
        type: AUTH_LOGIN_INVALID_EMAIL_ADDRESS,
        error: error,
      });
    } else if (error.response.status === 422) {
      dispatch({
        type: ORGANIZATION_INACTIVE,
        error: error,
      });
    } else {
      dispatch({
        type: AUTH_LOGIN_FAILED,
        error: error,
      });
    }
  }
};

/**
 * Action Name: forceChangePassword
 * Description:
 *      called on first login of user
 * Param: payload
 * Return: ChallengeName,
 *          AccessToken,
 *          ExpiresIn,
 *          TokenType,
 *          RefreshToken,
 *          IdToken,
 *          permissions,
 *          organization_id,
 *          authStatus
 * Author: Jeremiah
 * Last Update By: Everard
 */
export const forceChangePassword = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/users/initial-change-password`,
      payload
    );
    if (response.status === 200) {
      dispatch({
        type: AUTH_FORCE_CHANGE_PASS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: AUTH_FORCE_CHANGE_PASS_FAILED,
      error: error,
    });
  }
};

/**
 * Action Name: changePassword
 * Description:
 *      called on first login of user
 * Param: payload
 * Return: ChallengeName,
 *          AccessToken,
 *          ExpiresIn,
 *          TokenType,
 *          RefreshToken,
 *          IdToken,
 *          permissions,
 *          organization_id,
 *          authStatus
 * Author: Jeremiah
 * Last Update By: RJ
 */

export const changePassword = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/users/change-password`,
      payload,
      await getHeaders()
    );
    if (response.status === 204) {
      dispatch({
        type: AUTH_CHANGE_PASS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    if (error.response === undefined) {
      dispatch({
        type: AUTH_CHANGE_PASS_FAILED,
        error: error,
      });
    } else {
      if (error.response.status === 401) {
        if (error.response.data.error_code === 'INVALID_USER_CREDENTIALS') {
          dispatch({
            type: AUTH_CHANGE_PASS_INVALID_CREDENTIALS,
            payload: {
              error: error.response.data.error_code,
            },
          });
        } else if (error.response.data.error_code === 'INVALID_PASSWORD') {
          dispatch({
            type: AUTH_CHANGE_PASS_INVALID_PASSWORD,
            payload: {
              error: error.response.data.error_code,
            },
          });
        } else {
          dispatch(errorHandler('Authentication', error));
        }
      } else if (error.response.status === 400) {
        if (error.response.data.error_code === 'INVALID_PASSWORD_FORMAT') {
          dispatch({
            type: AUTH_INVALID_PASSWORD_FORMAT,
            payload: {
              error: error.response.data.error_code,
            },
          });
        }
      } else {
        dispatch({
          type: AUTH_CHANGE_PASS_FAILED,
          error: error,
        });
      }
    }
  }
};

/**
 * Action Name: logout
 * Description:
 *      logout user from the system
 * Param: void
 * Return: will empty ChallengeName,
 *          AccessToken,
 *          ExpiresIn,
 *          TokenType,
 *          RefreshToken,
 *          IdToken,
 *          permissions,
 *          organization_id,
 *          authStatus
 * Author: Jeremiah
 * Last Update By: RJ
 */
export const logout = (expired) => async (dispatch) => {
  if (expired) {
    dispatch({
      type: AUTH_EXPIRY,
      payload: {
        error: SESSION_EXPIRY_MESSAGE,
      },
    });
  } else {
    try {
      const response = await axios.post(
        `${ROOT_URL}/users/logout`,
        {},
        await getHeaders()
      );
      if (response.status === 204) {
        dispatch({
          type: AUTH_LOGOUT_SUCCESS,
          payload: response.data,
        });
        setTimeout(() => {
          persistor.purge();
        }, 1000);
      }
    } catch (error) {
      dispatch({
        type: AUTH_LOGOUT_FAILED,
        payload: error,
      });
    }
  }
};

export const forgotPasswordRequest = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/users/forgot-password`,
      payload
    );
    if (response.status === 204) {
      dispatch({
        type: AUTH_FORGOT_PASSWORD_REQUEST,
      });
    }
  } catch (error) {
    if (error.response.status === 400) {
      dispatch({
        type: AUTH_FORGOT_PASSWORD_REQUEST_INVALID_EMAIL,
        error: error.response.data.error_code,
      });
    } else if (error.response.status === 500) {
      dispatch({
        type: UNEXPECTED_ERROR_FORGOT_PASSWORD,
        error: error.response.error_message,
      });
    } else if (error.response.status === 422) {
      dispatch({
        type: MAXIMUM_FORGOT_PASSWORD_ATTEMPT,
        error: error.response.data.error_code,
      });
    } else {
      dispatch({
        type: AUTH_FORGOT_PASSWORD_REQUEST_FAIL,
        error: error.response.error_message,
      });
    }
  }
};

export const forgotPasswordConfirm = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/users/confirm-forgot-password`,
      payload
    );
    if (response.status === 204) {
      dispatch({
        type: AUTH_FORGOT_PASSWORD_SUCCESS,
      });
    }
  } catch (error) {
    switch (error.response.status) {
      case 422:
        if (error.response.data.error_code === 'INVALID_CODE') {
          dispatch({
            type: AUTH_FORGOT_PASSWORD_INVALID_CODE,
            payload: {
              error: error.response.data.error_code,
            },
          });
        } else if (error.response.data.error_code === 'EXPIRED_CODE') {
          dispatch({
            type: AUTH_FORGOT_PASSWORD_EXPIRED_CODE,
            payload: {
              error: error.response.data.error_code,
            },
          });
        } else if (error.response.data.error_code === 'INVALID_NEW_PASSWORD') {
          dispatch({
            type: AUTH_FORGOT_PASSWORD_INVALID_NEW_PASS_FORMAT,
            payload: {
              error: error.response.data.error_code,
            },
          });
        }
        break;
      case 404:
        dispatch({
          type: AUTH_FORGOT_PASSWORD_USER_DOES_NOT_EXIST,
          error: error.response.data.error_code,
        });
        break;
      case 400:
        dispatch({
          type: AUTH_FORGOT_PASSWORD_MISSING_DETAILS,
          error: error.response.data.error_code,
        });
        break;
      default:
        dispatch({
          type: AUTH_FORGOT_PASSWORD_REQUEST_FAIL,
          error: error.response.data.error_code,
        });
    }
  }
};

export const authRefreshToken = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/users/refresh-auth`,
      payload
    );
    if (response.status === 200) {
      dispatch({
        type: AUTH_REFRESH_TOKEN_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Authentication', e, 'REFRESH_TOKEN'));
  }
};

/**
 * Action Name: formUpdate
 * Description:
 *      changes prop values
 * Param: prop, value
 * Return: sets action.payload.value to action.payload.prop
 * Author: Jeremiah
 * Last Update By: Shirwyn
 */
export const formUpdate = ({ prop, value }) => {
  return {
    type: AUTH_UPDATE,
    payload: {
      prop,
      value,
    },
  };
};
