import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { BillComponentStyles } from '../../assets/styles';
import propTypes from 'prop-types';

const BillComponent = ({previousBill, outstandingBalance, toPay}) => {
    const classes = BillComponentStyles();
    return (
        <Grid>
            <Grid>
                <Typography className={classes.titleFont}>BILLS PAYMENT</Typography>
            </Grid>
            <Grid>
                <Typography className={classes.titleFont}>Unbilled amount</Typography>
            </Grid>
            <Grid>  
                <Typography id='unbilled-bal' className={classes.contentSize}>{`${'₱'} ${toPay}`}</Typography>
            </Grid>
            <Divider/>
            <Grid container className={classes.dataContainer}>
                <Grid item>
                    <Typography className={classes.titleFont}>Total Previous Bill: </Typography>
                </Grid>
                <Grid item>
                    <Typography id='previous-bal' className={classes.contentFont}>{`${'₱'} ${previousBill}`}</Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.dataContainer}>
                <Grid item>
                    <Typography className={classes.titleFont}>Outstanding Balance: </Typography>
                </Grid>
                <Grid item>
                    <Typography id='outstanding-bal' className={classes.contentFont}>{`${'₱'} ${outstandingBalance}`}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

BillComponent.propTypes = {
    previousBill: propTypes.string,
    lastPayment: propTypes.string,
    outstandingBalance: propTypes.string,
    paymentDate: propTypes.string,
    toPay: propTypes.string,
    loadingComponent: propTypes.bool
}
export { BillComponent }
