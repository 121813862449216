/**
 * Class Name: UserPage
 * Description: User container.
 * Param: void
 * Return: UserPage Component
 * Author: Shirwyn
 * Last Update: RJ
 */
import React, { Component } from 'react';
import {
  UserSubheader,
  UserForm,
  UserView,
  Loader,
  UserSideDrawer,
  OrganizationSuggest,
  CommonTable,
  Pagination,
  PageSnackBar
} from '../../components';
import { connect } from 'react-redux';
import {
  readOrganizations,
  readUsers,
  createUser,
  viewUser,
  updateUser,
  deleteUser,
} from '../../actions';
import { SideDrawerLoaderStyles } from '../../assets/styles';
import { OrgDropDownStyles } from '../../assets/styles';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/styles';
import {
  VIEW_USERS,
  VIEW_ORGANIZATIONS,
  DELETE_USERS,
  CREATE_USERS,
  trimString,
  removeSpecialCharacter,
  limitCharacter,
} from '../../helpers';
import {
  pp_blue_color_loader,
  white_color_loader,
} from '../../assets/styles/types';

const PAGE_SIZE = 10;

class UserPage extends Component {
  _isMounted = false;
  _isTableInitialLoad = true;

  constructor(props) {
    super(props);
    this.state = {
      createState: false,
      deleteState: false,
      isLoadingTable: true,
      isLoadingModal: false,
      loaderColor: pp_blue_color_loader,
      key: '',
      viewState: false,
      editState: false,
      users: [],
      organizations: [],
      userInput: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      email: '',
      role: '',
      formError: false,
      emailError: '',
      firstNameError: '',
      lastNameError: '',
      middleNameError: '',
      created_timestamp: '',
      errorStatus: 0,
      errorCode: '',
      disableViewUser: false,
      permissions: [],
      selectedOrgId: '',
      roleSelection: [],
      selectedOrganization: null,
      organization_names: [],
      selectedOrgStatus: null,
      selectedRole: null,
      formButtonLoadingState: false,
      organizationID: '',
      disableOrganizationSuggest: false,
      deleteError: '',
      isUserActive: null,
      isViewLoading: false,
      userFilterOptions: [],
      selectedUserFilter: {},
      selectedUserActiveFilter: {},
      snackBarOpen: false,
      hasAdminAccess: false,
      snackBarConfig: {
        status: '',
        message: '',
      },
      canHaveSuperAdmin: '',
    };
    this.clickedRow = this.clickedRow.bind(this);
    this.userInputChangeHandler = this.userInputChangeHandler.bind(this);
    this.createUser = this.createUser.bind(this);
    this.validateFormInputs = this.validateFormInputs.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.organizationSearchHandler = this.organizationSearchHandler.bind(this);
    this.roleSelectHandler = this.roleSelectHandler.bind(this);
    this.userNextPageHandler = this.userNextPageHandler.bind(this);
    this.userPreviousPageHandler = this.userPreviousPageHandler.bind(this);
    this.userFilterHandler = this.userFilterHandler.bind(this);
    this.userActiveFilterHandler = this.userActiveFilterHandler.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._isTableInitialLoad = true;
    const { activeOrg, inActiveOrg } = OrgDropDownStyles;

    this.setState(
      {
        permissions: this.props.permissions,
        userFilterOptions: [
          { label: 'First Name', value: 'first_name' },
          { label: 'Last Name', value: 'last_name' },
          { label: 'Middle Name', value: 'middle_name' },
          { label: 'Email', value: 'email' },
          { label: 'Date Created', value: 'created_timestamp' },
          { label: 'Created By', value: 'created_by' },
          { label: 'Active', value: 'active' },
        ],
        selectedUserFilter: {
          label: 'Date Created',
          value: 'created_timestamp',
        },
        selectedUserActiveFilter: { value: 'active', label: 'Active' },
      },
      () => {
        if (!this.state.permissions.includes(VIEW_USERS)) {
          if (this._isMounted) {
            this.setState({ disableViewUser: true });
          }
        }
        if (!this.state.permissions.includes(VIEW_ORGANIZATIONS)) {
          if (this._isMounted) {
            this.setState(
              {
                selectedOrgId: this.props.organizationId,
                hasAdminAccess: false,
                selectedOrgStatus: "true",
              },
              () => {
                this.props
                  .readUsers(
                    this.state.selectedOrgId,
                    this.state.selectedUserActiveFilter.value,
                    PAGE_SIZE,
                  )
                  .then(() => {                    
                    if (!this.props.error) {                      
                      if (this._isMounted) {
                        this.setState(
                          {
                            users: this.props.users,
                            organizationID: this.state.selectedOrgId,
                            roleSelection: this.props.creatableUserRoles,
                            loaderColor: white_color_loader,
                          },
                          () => {
                            setTimeout(() => {
                              if (this._isMounted) {
                                this._isTableInitialLoad = false;
                                this.setState({ isLoadingTable: false });
                              }
                            }, 500);
                          }
                        );
                      }
                    } else {
                      this.setState({ isLoadingTable: false });
                      if (this.props.system_error) {
                        this.handlePageSystemAlert("error", this.props.error);
                      }
                    }
                  });
              }
            );
          }
        } else {
          this.props.readOrganizations().then(() => {            
            if (this._isMounted) {              
              this.setState(
                {
                  organizations: this.props.organizations,
                  organizationID: this.props.organizations[0].org_id,
                  selectedOrgId: this.props.organizations[0].org_id,
                  organization_names: this.props.organizations.map((org) => {
                    let num = 17;
                    if (org.org_name.length >= num) {
                      return {
                        canHaveSuperAdmin: org.can_has_super_admin,
                        value: org.org_id,
                        status: org.active,
                        label:
                          org.active === "true" ? (
                            <>
                              {org.org_name.slice(0, num) + "..."}{" "}
                              {<span style={activeOrg}>A</span>}
                            </>
                          ) : (
                            <>
                              {org.org_name.slice(0, num) + "..."}{" "}
                              {<span style={inActiveOrg}>IN</span>}
                            </>
                          ),
                        org,
                      };
                    } else {
                      return {
                        canHaveSuperAdmin: org.can_has_super_admin,
                        value: org.org_id,
                        status: org.active,
                        label: (
                          <>
                            {org.org_name}
                            {org.active === "true" ? (
                              <span style={activeOrg}>A</span>
                            ) : (
                              <span style={inActiveOrg}>IN</span>
                            )}
                          </>
                        ),
                        org,
                      };
                    }
                  }),
                  hasAdminAccess: true,
                  roleSelection: this.props.creatableUserRoles,
                  selectedOrganization: this.state.organization_names[0],
                  selectedOrgStatus: this.props.organizations[0].active,
                },
                () => {
                  this.props
                    .readUsers(
                      this.state.organizationID,
                      this.state.selectedUserActiveFilter.value,
                      PAGE_SIZE,
                    )
                    .then(() => {                    
                      if (this._isMounted) {                        
                        this.setState(
                          {
                            users: this.props.users,
                            selectedOrganization: this.state
                              .organization_names[0],
                            selectedOrgStatus: this.props.organizations[0]
                              .active,
                          },
                          () => {
                            setTimeout(() => {
                              if (this._isMounted) {
                                this._isTableInitialLoad = false;
                                this.setState({ isLoadingTable: false });
                              }
                            }, 500);
                          }
                        );
                      }
                    });
                }
              );
            }
          });
        }
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Function Name: userPreviousPageHandler
   * Description: handler for previous button of Pagination
   * Param: e
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  userPreviousPageHandler(e) {
    e.preventDefault();

    if (this.props.current_page > 1) {
      if (this._isMounted) {
        this.setState(
          {
            isLoadingTable: true,
          },
          () => {
            this.props
              .readUsers(
                this.state.organizationID,
                this.state.selectedUserActiveFilter.value,
                PAGE_SIZE,
                this.props.current_page - 1
              )
              .then(() => {
                if (this._isMounted) {
                  this.setState({
                    users: this.props.users,
                    isLoadingTable: false,
                  });
                }
              });
          }
        );
      }
    }
  }

  /**
   * Function Name: userNextPageHandler
   * Description: handler for next button of Pagination
   * Param: e
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  userNextPageHandler(e) {
    e.preventDefault();

    if (this.props.current_page < this.props.page_count) {
      if (this._isMounted) {
        this.setState(
          {
            isLoadingTable: true,
          },
          () => {
            this.props
              .readUsers(
                this.state.organizationID,
                this.state.selectedUserActiveFilter.value,
                PAGE_SIZE,
                this.props.current_page + 1
              )
              .then(() => {
                if (this._isMounted) {
                  this.setState({
                    users: this.props.users,
                    isLoadingTable: false,
                  });
                }
              });
          }
        );
      }
    }
  }

  /**
   * Function Name: userFilterHandler
   * Description: handler for User table filter
   * Param: selectedUserFilter
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  userFilterHandler(selectedUserFilter) {
    if (this._isMounted) {
      this.setState({ selectedUserFilter });
    }
  }

  /**
   * Function Name: userActiveFilterHandler
   * Description: handler for User active status filter
   * Param: selectedUserActiveFilter
   * Return: void
   * Author: Everard
   * Last Update By: Everard
   */
  userActiveFilterHandler(selectedUserActiveFilter) {
    if (this._isMounted) {
      this.setState(
        {
          selectedUserActiveFilter,
          isLoadingTable: true,
        },
        () => {
          this.props
            .readUsers(
              this.state.organizationID,
              this.state.selectedUserActiveFilter.value,
              PAGE_SIZE
            )
            .then(() => {
              if (this._isMounted) {
                this.setState({
                  users: this.props.users,
                  isLoadingTable: false,
                });
              }
            });
        }
      );
    }
  }

  /**
   * Function Name: organizationSearchHandler
   * Description: Changes the value of the input/select field
   * Param: organizationSearchHandler
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  organizationSearchHandler(selectedOrganization) {
    if (this._isMounted) {
      this.setState(
        {
          selectedOrganization: selectedOrganization,
          organizationID: selectedOrganization.value,
          selectedOrgId: selectedOrganization.value,
          selectedOrgStatus: selectedOrganization.status,
          isLoadingTable: true,
        },
        () => {
          this.props
            .readUsers(
              this.state.organizationID,
              this.state.selectedUserActiveFilter.value,
              PAGE_SIZE
            )
            .then(() => {
              if (this._isMounted) {
                this.setState({ users: this.props.users }, () => {
                  setTimeout(() => {
                    if (this._isMounted) {
                      this.setState({ isLoadingTable: false });
                    }
                  }, 500);
                });
              }
            });
        }
      );
    }
  }

  /**
   * Function Name: validateFormInputs
   * Description: validate input fields for create and update before submitting
   * Param: e, form
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  validateFormInputs(e, form) {
    e.preventDefault();

    let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    let alphaNumeric = /^([a-zA-Z0-9 _-]+)$/;
    let hasError = false;

    if (this._isMounted) {
      this.setState({
        errorStatus: 0,
        emailError: '',
        firstNameError: '',
        lastNameError: '',
        middleNameError: '',
      }); // reset error status
    }

    if (
      this.state.first_name === '' ||
      this.state.last_name === '' ||
      this.state.middle_name === '' ||
      this.state.email === ''
    ) {
      if (this._isMounted) {
        this.setState({ formError: true });
      }
      hasError = true;
    }

    if (
      this.state.first_name.charAt(0) === ' ' ||
      this.state.last_name.charAt(0) === ' ' ||
      this.state.middle_name.charAt(0) === ' '
    ) {
      if (this._isMounted) {
        this.setState({ formError: true });
      }
      hasError = true;
    }

    if (!alphaNumeric.test(this.state.first_name)) {
      if (this._isMounted) {
        this.setState({
          formError: true,
          firstNameError: 'Does not accept special characters',
        });
      }
      hasError = true;
    }

    if (!alphaNumeric.test(this.state.last_name)) {
      if (this._isMounted) {
        this.setState({
          formError: true,
          lastNameError: 'Does not accept special characters',
        });
      }
      hasError = true;
    }

    if (!alphaNumeric.test(this.state.middle_name)) {
      if (this._isMounted) {
        this.setState({
          formError: true,
          middleNameError: 'Does not accept special characters',
        });
      }
      hasError = true;
    }

    if (this.state.email !== '' && !reg.test(this.state.email)) {
      if (this._isMounted) {
        this.setState({
          formError: true,
          emailError: 'Invalid email format',
        });
      }
      hasError = true;
    }

    if (!hasError) {
      if (form === 'create') {
        if (this._isMounted) {
          this.setState(
            {
              formButtonLoadingState: true,
              emailError: '',
              firstNameError: '',
              lastNameError: '',
              middleNameError: '',
            },
            () => {
              this.createUser();
            }
          );
        }
      } else if (form === 'update') {
        if (this._isMounted) {
          this.setState({ formButtonLoadingState: true }, () => {
            this.updateUser();
          });
        }
      } else {
        // nothing to do.
      }
    }
  }

  /**
   * Function Name: deleteUser
   * Description: calls deleteUser action
   * Param: void
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  deleteUser() {
    if (this.state.permissions.includes(DELETE_USERS)) {
      if (this._isMounted) {
        this.setState(
          {
            isLoadingModal: true,
            loaderColor: white_color_loader,
          },
          () => {
            this.props
              .deleteUser(
                this.state.organizationID,
                this.props.userInfo.user_id
              )
              .then(() => {
                if (!this.props.error) {
                  if (this._isMounted) {
                    this.setState(
                      {
                        isLoadingTable: true,
                        first_name: '',
                        last_name: '',
                        middle_name: '',
                        email: '',
                        role: '',
                        selectedRole: { label: 'Admin', value: 'admin' },
                      },
                      () => {
                        this.props
                          .readUsers(
                            this.state.organizationID,
                            this.state.selectedUserActiveFilter.value,
                            PAGE_SIZE
                          )
                          .then(() => {
                            if (this._isMounted) {
                              this.setState(
                                {
                                  users: this.props.users,
                                  viewState: false,
                                  deleteState: false,
                                  deleteError: '',
                                  isLoadingModal: false,
                                },
                                () => {
                                  setTimeout(() => {
                                    if (this._isMounted) {
                                      this.setState({
                                        isLoadingTable: false,
                                        snackBarOpen: true,
                                        snackBarConfig: {
                                          status: 'success',
                                          message: 'User deleted.',
                                        },
                                      });
                                    }
                                  }, 500);
                                }
                              );
                            }
                          });
                      }
                    );
                  }
                } else {
                  if (this._isMounted) {
                    this.setState({
                      viewState: true,
                      deleteState: false,
                      deleteError: this.props.error,
                      isLoadingModal: false,
                    });
                  }
                }
              });
          }
        );
      }
    }
  }

  /**
   * Function Name: updateUser
   * Description: calls updateUser action
   * Param: void
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  updateUser() {
    const userRoles = [];
    userRoles.push(this.state.selectedRole.value);
    const updatePayload = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      middle_name: this.state.middle_name,
      roles: userRoles,
    };
    this.props
      .updateUser(
        this.state.organizationID,
        this.props.userInfo.user_id,
        updatePayload
      )
      .then(() => {
        if (!this.props.error) {
          if (this._isMounted) {
            this.setState(
              {
                isLoadingTable: true,
              },
              () => {
                this.props
                  .readUsers(
                    this.state.organizationID,
                    this.state.selectedUserActiveFilter.value,
                    PAGE_SIZE,
                    this.props.current_page
                  )
                  .then(() => {
                    this.props
                      .viewUser(this.state.organizationID, this.state.key)
                      .then(() => {
                        if (this._isMounted) {
                          this.setState(
                            {
                              users: this.props.users,
                              editState: false,
                              viewState: false,
                              first_name: '',
                              last_name: '',
                              middle_name: '',
                              email: '',
                              role: '',
                              formError: false,
                              emailError: '',
                              firstNameError: '',
                              lastNameError: '',
                              middleNameError: '',
                              errorStatus: 0,
                              errorCode: '',
                              formButtonLoadingState: false,
                              selectedRole: Object.assign(
                                {},
                                ...this.props.userInfo.roles.map((role) => {
                                  return {
                                    value: role
                                      .toLowerCase()
                                      .split(' ')
                                      .join('_'),
                                    label: role
                                      .toLowerCase()
                                      .split(' ')
                                      .map(
                                        (w) =>
                                          w.substring(0, 1).toUpperCase() +
                                          w.substring(1)
                                      )
                                      .join(' '),
                                  };
                                })
                              ),
                            },
                            () => {
                              setTimeout(() => {
                                if (this._isMounted) {
                                  this.setState({
                                    isLoadingTable: false,
                                    snackBarOpen: true,
                                    snackBarConfig: {
                                      status: 'success',
                                      message: 'User updated.',
                                    },
                                  });
                                }
                              }, 500);
                            }
                          );
                        }
                      });
                  });
              }
            );
          }
        } else {
          this.setState({
            editState: true,
            isLoadingTable: false,
            errorStatus: this.props.status,
            error: this.props.error,
            errorCode: this.props.error_code,
            formButtonLoadingState: false,
          });
        }
      });
  }

  /**
   * Function Name: createUser
   * Description: calls createUser action
   * Param: void
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  createUser() {
    let userRoles = [];
    userRoles.push(this.state.selectedRole.value);
    const submitPayload = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      middle_name: this.state.middle_name,
      email: this.state.email,
      roles: userRoles,
    };
    if (this.state.permissions.includes(CREATE_USERS)) {
      this.props
        .createUser(this.state.organizationID, submitPayload)
        .then(() => {
          if (!this.props.error) {
            if (this._isMounted) {
              this.setState(
                {
                  isLoadingTable: true,
                },
                () => {
                  this.props
                    .readUsers(
                      this.state.organizationID,
                      this.state.selectedUserActiveFilter.value,
                      PAGE_SIZE
                    )
                    .then(() => {
                      if (this._isMounted) {
                        this.setState(
                          {
                            users: this.props.users,
                            first_name: '',
                            last_name: '',
                            middle_name: '',
                            email: '',
                            role: '',
                            createState: false,
                            formButtonLoadingState: false,
                            isLoadingTable: true,
                            formError: false,
                            emailError: '',
                            firstNameError: '',
                            lastNameError: '',
                            middleNameError: '',
                            errorStatus: 0,
                            errorCode: '',
                            selectedRole: { label: 'Admin', value: 'admin' },
                          },
                          () => {
                            setTimeout(() => {
                              if (this._isMounted) {
                                this.setState({
                                  isLoadingTable: false,
                                  snackBarOpen: true,
                                  snackBarConfig: {
                                    status: 'success',
                                    message: 'User created.',
                                  },
                                });
                              }
                            }, 500);
                          }
                        );
                      }
                    });
                }
              );
            }
          } else {
            if (this._isMounted) {
              this.setState({
                createState: true,
                isLoadingTable: false,
                errorStatus: this.props.status,
                errorCode: this.props.error_code,
                formButtonLoadingState: false,
              });
            }

            if (this.props.system_error) {
              this.handlePageSystemAlert('error', this.props.error);
            }
          }
        });
    }
  }

  /**
   * Function Name: userInputChangeHandler
   * Description: hanles onchange event of input fields for forms
   * Param: e (event)
   * Return: void
   * Author: Shirwyn
   * Last Update By: Raymart
   */
  userInputChangeHandler(e) {
    if (this._isMounted) {
      this.setState({
        [e.target.name]: limitCharacter(
          e.target.name !== 'email'
            ? removeSpecialCharacter(e.target.value)
            : e.target.value,
          50
        ),
        errorStatus: 0,
      });
    }
    e.preventDefault();
  }

  /**
   * Function Name: clickedRow
   * Description: triggered when a row is clicked on the table and will call viewUser action then open the sidedrawer for view
   * Param: id
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  clickedRow(id) {
    if (this._isMounted) {
      this.setState(
        {
          viewState: true,
          isViewLoading: true,
          key: id,
          loaderColor: pp_blue_color_loader,
        },
        () => {
          this.props
            .viewUser(this.state.organizationID, this.state.key)
            .then(() => {
              if (this._isMounted) {
                this.setState(
                  {
                    viewState: true,
                    first_name: this.props.userInfo.first_name,
                    last_name: this.props.userInfo.last_name,
                    middle_name: this.props.userInfo.middle_name,
                    email: this.props.userInfo.email,
                    isUserActive: this.props.userInfo.active,
                    selectedRole: Object.assign(
                      {},
                      ...this.props.userInfo.roles.map((role) => {
                        return {
                          value: role.toLowerCase().split(' ').join('_'),
                          label: role
                            .toLowerCase()
                            .split(' ')
                            .map(
                              (w) =>
                                w.substring(0, 1).toUpperCase() + w.substring(1)
                            )
                            .join(' '),
                        };
                      })
                    ),
                  },
                  () => {
                    setTimeout(() => {
                      if (this._isMounted) {
                        this.setState({ isViewLoading: false });
                      }
                    }, 500);
                  }
                );
              }
            });
        }
      );
    }
  }

  /**
   * Function Name: roleSelectHandler
   * Description: Changes the value of the input/select field
   * Param: roleSelectHandler
   * Return: void
   * Author: Shirwyn
   * Last Update By: RJ
   */
  roleSelectHandler = (selectedRole) => {
    if (this._isMounted) {
      this.setState({ selectedRole });
      this.setState({ errorCode: 0 });
    }
  };

  /**
   * Function Name: handleAlertOncClose
   * Description: Triggers once the alert component requests to be closed according to the duration time
   * Param: handleAlertOnClose
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */
  handleAlertOnClose = () => {
    this.setState({
      snackBarOpen: false,
    });
  };

  /**
   * Function Name: handlePageSystemAlert
   * Description: Triggers once the alert is a system alert
   * Param: type, message
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */

  handlePageSystemAlert = (type, message) => {
    this.setState({
      snackBarOpen: true,
      snackBarConfig: {
        status: type,
        message: message,
      },
    });
  };

  // /**
  //  * Function Name: formatInput
  //  * Description: To remove whitespaces on input
  //  * Param: n/a
  //  * Return: trimmed texts
  //  * Author: Nelly
  //  * Last Update By: RJ
  //  */
  formatInput = (e) => {
    const attribute = e.target.getAttribute('name');
    this.setState({
      [attribute]: trimString(removeSpecialCharacter(e.target.value)),
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <div id='userPage'>
        {!this.state.isLoadingTable ? (
          <UserSubheader
            id='UserSubheader'
            disabled={true}
            orgStatus={this.state.selectedOrgStatus}
            title={'Users'}
            btn={'Create User'}
            permissions={this.state.permissions}
            onCreate={() => {
              this.setState({
                createState: true,
                selectedRole: { label: 'Admin', value: 'admin' },
              });
            }}
            suggestComponent={
              this.state.disableOrganizationSuggest ? null : (
                <OrganizationSuggest
                  id='OrganizationSuggest'
                  organizationName={this.state.selectedOrganization}
                  changeHandler={this.organizationSearchHandler}
                  organizations={this.state.organization_names}
                  permissions={this.state.permissions}
                />
              )
            }
          />
        ) : ''}
        <div>
          {this._isTableInitialLoad ? null : (
            <Pagination
              id='Pagination'
              customSelectID='user-active-filter'
              filterList={this.state.userFilterOptions}
              selectedFilter={this.state.selectedUserFilter}
              filterChangeHandler={this.userFilterHandler}
              startingNumber={this.props.view_start}
              endingNumber={this.props.view_end}
              totalCount={this.props.total_count}
              previousButtonHandler={(e) => this.userPreviousPageHandler(e)}
              nextButtonHandler={(e) => this.userNextPageHandler(e)}
              isPreviousButtonDisabled={this.props.view_start <= 1}
              isNextButtonDisabled={
                this.props.total_count === this.props.view_end
              }
              // for filter
              filterOptions={[
                { value: 'all', label: 'All' },
                { value: 'active', label: 'Active' },
                { value: 'inactive', label: 'Inactive' },
              ]}
              filterSelectedOption={this.state.selectedUserActiveFilter}
              filterOnChangeHandler={this.userActiveFilterHandler}
            />
          )}
          {this.state.isLoadingTable ? (
            <Loader
              id='loader'
              adjustSmall={window.screen.width <= 720}
              color={this.state.loaderColor}
            />
          ) : (
              <CommonTable
                headerArray={[
                  'First Name',
                  'Last Name',
                  'Middle Name',
                  'Email',
                  'Role',
                  'Status',
                ]}
                arrangement={[
                  'first_name',
                  'last_name',
                  'middle_name',
                  'email',
                  'roles',
                  'active',
                ]}
                colWidth={['15%', '15%', '15%', '20%', '20%', '15%']}
                data={this.state.users}
                clickedRowHandler={this.clickedRow}
                page={'user'}
              />
            )}
        </div>
        {this.state.createState === true ? (
          <UserSideDrawer
            id='UserSideDrawer'
            type={'create'}
            title={'User'}
            drawerState={this.state.createState}
            showModal={this.state.deleteState}
            onBack={() => {
              this.setState({
                createState: false,
                formError: false,
                emailError: '',
                firstNameError: '',
                lastNameError: '',
                middleNameError: '',
                selectedRole: { label: 'Admin', value: 'admin' },
              });
            }}
          >
            <UserForm
              id='userForm'
              organizationName={this.state.selectedOrganization}
              adminAccess={this.state.hasAdminAccess}
              formatInput={this.formatInput}
              btnText={'Cancel'}
              handleInputChange={(e) => this.userInputChangeHandler(e)}
              submitUser={(e) => this.validateFormInputs(e, 'create')}
              hasFormError={this.state.formError}
              emailError={this.state.emailError}
              firstNameError={this.state.firstNameError}
              lastNameError={this.state.lastNameError}
              middleNameError={this.state.middleNameError}
              firstName={this.state.first_name}
              status={this.state.errorStatus}
              errorCode={this.state.errorCode}
              lastName={this.state.last_name}
              middleName={this.state.middle_name}
              email={this.state.email}
              roles={this.state.selectedRole}
              roleSelect={this.state.roleSelection}
              roleChangeHandler={this.roleSelectHandler}
              formType={'Submit'}
              isButtonLoading={this.state.formButtonLoadingState}
              onCancel={() => {
                this.setState({
                  createState: false,
                  first_name: '',
                  last_name: '',
                  middle_name: '',
                  email: '',
                  role: '',
                  selectedRole: { label: 'Admin', value: 'admin' },
                });
              }}
            />
          </UserSideDrawer>
        ) : null}
        {this.state.viewState === true &&
          this.state.disableViewUser === false ? (
            <UserSideDrawer
              id='userSideDrawer' 
              type={'view'}
              title={'User'}
              loaderColor={this.state.loaderColor}
              loadingModal={this.state.isLoadingModal}
              drawerState={this.state.viewState}
              getSelectedItem={this.state.email}
              permissions={this.state.permissions}
              isActive={this.state.isUserActive}
              deleteSelected={this.deleteUser}
              onBack={() => {
                this.setState({
                  viewState: false,
                  formError: false,
                  emailError: '',
                  firstNameError: '',
                  lastNameError: '',
                  middleNameError: '',
                  errorStatus: 0,
                  first_name: '',
                  last_name: '',
                  middle_name: '',
                  email: '',
                  role: '',
                  deleteError: '',
                });
              }}
              onEdit={() => {
                this.setState({
                  viewState: false,
                  editState: true,
                });
              }}
              onDelete={() => {
                this.setState({
                  deleteState: true,
                  emailError: '',
                  firstNameError: '',
                  lastNameError: '',
                  middleNameError: '',
                  errorStatus: 0,
                  deleteError: '',
                });
              }}
              showModal={this.state.deleteState}
              handleCloseModal={() => {
                this.setState({ deleteState: false });
              }}
            >
              {this.state.isViewLoading ? (
                <div className={classes.loader}>
                  <Loader
                    size={300}
                    adjustSmall={window.screen.width <= 720}
                    color={this.state.loaderColor}
                  />
                </div>
              ) : (
                  <UserView
                    id='UserView' 
                    email={this.props.userInfo.email}
                    first_name={this.props.userInfo.first_name}
                    last_name={this.props.userInfo.last_name}
                    middle_name={this.props.userInfo.middle_name}
                    org_name={this.props.userInfo.org_name}
                    role={this.props.userInfo.roles}
                    created_by={this.props.userInfo.created_by.name}
                    date_created={this.props.userInfo.created_timestamp}
                    active={this.props.userInfo.active}
                    error={this.state.deleteError}
                  />
                )}
            </UserSideDrawer>
          ) : null}
        {this.state.editState === true ? (
          <UserSideDrawer
            id='UserSideDrawer'
            type={'edit'}
            title={'User'}
            drawerState={this.state.editState}
            showModal={this.state.deleteState}
            onBack={() => {
              this.setState({
                editState: false,
                viewState: false,
                first_name: '',
                last_name: '',
                middle_name: '',
                email: '',
                errorStatus: 0
              });
            }}
          >
            <UserForm
              id='UserForm'
              adminAccess={this.state.hasAdminAccess}
              organizationName={this.state.selectedOrganization}
              formatInput={this.formatInput}
              btnText={'Cancel'}
              handleInputChange={(e) => this.userInputChangeHandler(e)}
              submitUser={(e) => this.validateFormInputs(e, 'update')}
              hasFormError={this.state.formError}
              formType={'Save Changes'}
              status={this.state.errorStatus}
              errorCode={this.state.errorCode}
              email={this.props.userInfo.email}
              firstName={this.state.first_name}
              lastName={this.state.last_name}
              middleName={this.state.middle_name}
              roles={this.state.selectedRole}
              createdBy={this.props.userInfo.created_by.name}
              dateCreated={this.props.userInfo.created_timestamp}
              roleSelect={this.state.roleSelection}
              roleChangeHandler={this.roleSelectHandler}
              isButtonLoading={this.state.formButtonLoadingState}
              onCancel={() => {
                this.setState({
                  editState: false,
                  first_name: '',
                  last_name: '',
                  middle_name: '',
                  email: '',
                  errorStatus: 0
                });
              }}
            />
          </UserSideDrawer>
        ) : null}

        <PageSnackBar
          open={this.state.snackBarOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={this.handleAlertOnClose}
          snackBarConfig={this.state.snackBarConfig}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ user, organization, auth }) => {
  const {
    users,
    userInfo,
    total_count,
    current_page,
    page_count,
    view_start,
    view_end,
    error,
    system_error,
    error_code,
    status,
    noUserFound,
  } = user;
  const { organizations } = organization;
  const { permissions, organizationId, creatableUserRoles } = auth;

  return {
    users,
    userInfo,
    total_count,
    current_page,
    page_count,
    view_start,
    view_end,
    error,
    system_error,
    error_code,
    status,
    organizations,
    permissions,
    organizationId,
    creatableUserRoles,
    noUserFound,
  };
};

export default compose(
  withStyles(SideDrawerLoaderStyles),
  connect(mapStateToProps, {
    readOrganizations,
    readUsers,
    createUser,
    viewUser,
    updateUser,
    deleteUser,
  })
)(UserPage);
