/**
 * Style Name: OrganizationModalStyles
 * Description: Styles for Organization Modal Component
 * Param: void
 * Return: class styles
 * Author: Shirwyn
 * Last Update By: Nelly
 */
import { makeStyles } from "@material-ui/core/styles";
import {
  black_color,
  error_color,
  green_color,
} from "../../assets/styles/types";

const OrganizationModalStyles = makeStyles(() => ({
  deleteButton: {
    textTransform: "capitalize",
    backgroundColor: error_color,
    color: "#fff",
    whiteSpace: "nowrap",
    width: window.screen.width <= 720 ? "55vw" : "20rem",
    "&:hover": {
      backgroundColor: error_color,
      borderColor: error_color,
    },
    "&:disabled:hover": {
      backgroundColor: error_color,
      borderColor: error_color,
    },
    height: "2.3rem",
  },
  cancelButton: {
    textTransform: "capitalize",
    color: black_color,
    opacity: "0.5",
    whiteSpace: "nowrap",
    backgroundColor: "#c0c0c0",
    width: window.screen.width <= 720 ? "55vw" : "20rem",
    margin: "1rem 0",
    "&:hover": {
      backgroundColor: "#c0c0c0",
    },
  },
  dialogTitle: {
    color: error_color,
    textAlign: "center",
    padding: "0.5rem 1rem",
  },
  activateTitle: {
    color: black_color,
    textAlign: "center",
    padding: "0.5rem 1rem",
  },
  content: {
    color: black_color,
    padding: "0.75rem",
    opacity: "0.5",
    maxWidth: "23.5rem",
    fontSize: "14px",
    textAlign: "center",
    alignSelf: "center",
  },
  container: {
    justifyContent: "center",
    overflowX: "hidden",
    padding: "1rem",
  },
  buttonContainer: {
    margin: "1rem 0",
    textAlign: "center",
  },
  closeButton: {
    padding: "0!important",
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  inputOrgName: {
    textTransform: "capitalize",
    color: "#fff",
    whiteSpace: "nowrap",
    padding: "6px 8px",
    width: "80%",
    height: "2.125rem",
    left: "2.3rem",
    right: "2rem",
  },
  disabledDeleteButton: {
    textTransform: "capitalize",
    backgroundColor: green_color,
    color: "#fff",
    whiteSpace: "nowrap",
    width: window.screen.width <= 720 ? "55vw" : "20rem",
    height: "2.3rem",
    "&:hover": {
      backgroundColor: green_color,
      borderColor: green_color,
    },
  },
  orgName: {
    fontWeight: "bold",
  },
}));

export { OrganizationModalStyles };
