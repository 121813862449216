/**
 * Class Name: Dashboard
 * Description: Renders Dashboard View
 * Param: void
 * Return: View
 * Author: Jeremiah
 * Last Update By: RJ
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  readOrganizations,
  readInvoices,
  invoiceUnbilled,
  readCampaignReports,
  readInvoiceList,
  viewInvoice,
  getOutstandingBalance,
  readSGMReports,
  readBothReports,
} from '../../actions';
import { INVOICE_SERVER_ADDRESS } from '../../actions/types';
import { OrgDropDownStyles } from '../../assets/styles';

import {
  DashboardLayout,
  BillComponent,
  BarGraph,
  Loader,
  InvoiceTableView,
  OrganizationSuggest,
  PageSnackBar,
  RefresherComponent,
} from '../../components';
import { formatAmount, VIEW_ORGANIZATIONS } from '../../helpers';
import { pp_blue_color_loader } from '../../assets/styles/types';

const INVOICE_LIST_LIMIT = 3;

class DashboardPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      invoices: [],
      selectedOrgId: '',
      loaderColor: pp_blue_color_loader,
      previousBill: '',
      lastPayment: '',
      paymentDate: '',
      outstandingBalance: '',
      toPay: '',
      graphComponentIsLoading: true,
      invoiceComponentIsLoading: true,
      billComponentIsLoading: true,
      selectedInterval: {},
      selectedGraph: {},
      root_url: INVOICE_SERVER_ADDRESS,
      selectedOrganization: null,
      organization_names: [],
      permissions: [],
      viewLoader: [],
      snackBarOpen: false,
      snackBarConfig: {
        status: '',
        message: '',
      },
    };
    this.onGraphIntervalChange = this.onGraphIntervalChange.bind(this);
    this.onGraphViewChange = this.onGraphViewChange.bind(this);
    this.clickedInvoice = this.clickedInvoice.bind(this);
    this.onOrganizationChange = this.onOrganizationChange.bind(this);
  }

  /**
   * Function Name: clickInvoice
   * Description: handler for handleDownload prop
   * Param: org_id, invoice_id
   * Return: void
   * Author: Jeremiah
   * Last Update By: Everard
   */
  clickedInvoice(org_id, invoice_id, index) {
    let list = this.state.viewLoader;
    list[index] = true;
    this.setState({ viewLoader: list });

    this.props.viewInvoice(org_id, invoice_id).then(() => {
      list[index] = false;
      setTimeout(() => {
        this.setState({ viewLoader: list });
      }, 500);

      const url = this.props.invoice_url;
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.click();
    });
  }

  /**
   * Description: Returns the billing values of the initial state of the organization drop down
   * Param: selectedOrgId
   * Return: previousBill, lastPayment, paymentDate, outstandingBalance, toPay
   * Author: Frances
   * Last Update By: Everard
   */
  componentDidMount() {
    const { activeOrg, inActiveOrg } = OrgDropDownStyles;
    this._isMounted = true;
    let right = document.getElementById('billOrderPaper').offsetHeight;
    let left = document.getElementById('graphOrderPaper').offsetHeight;

    let height = right;
    if (right < left) {
      height = left;
    }

    this.setState(
      {
        permissions: this.props.permissions,
        selectedInterval: { value: 'Daily', label: 'Daily' },
        selectedGraph: { value: 'View Both', label: 'View Both' },
      },
      () => {
        if (!this.props.permissions.includes(VIEW_ORGANIZATIONS)) {
          if (this._isMounted) {
            this.setState(
              {
                selectedOrgId: this.props.organizationId,
              },
              () => {
                this.renderBarGraphComponent(this.state.selectedOrgId);
                this.renderBillingComponent(this.state.selectedOrgId);
                this.renderInvoiceListComponent(this.state.selectedOrgId);
              }
            );
          }
        } else {
          this.props.readOrganizations().then(() => {
            if (this._isMounted) {
              this.setState(
                {
                  organizations: this.props.organizations,
                  selectedOrgId: this.props.organizations[0].org_id,
                  selectedOrganization: {
                    value: this.props.organizations[0].org_id,
                    label: this.props.organizations[0].org_name,
                  },
                  organization_names: this.props.organizations.map((org) => {
                    let num = 17;
                    if (org.org_name.length >= num) {
                      return {
                        value: org.org_id,
                        status: org.active,
                        label: (
                          <>
                            {org.org_name.slice(0, num) + '...'}{' '}
                            {org.active === 'true' ? (
                              <span style={activeOrg}>A</span>
                            ) : (
                              <span style={inActiveOrg}>IN</span>
                            )}
                          </>
                        ),
                      };
                    } else {
                      return {
                        value: org.org_id,
                        status: org.active,
                        label: (
                          <>
                            {org.org_name}
                            {org.active === 'true' ? (
                              <span style={activeOrg}>A</span>
                            ) : (
                              <span style={inActiveOrg}>IN</span>
                            )}
                          </>
                        ),
                      };
                    }
                  }),
                },
                () => {
                  this.setState({
                    selectedOrganization: this.state.organization_names[0],
                  });
                  this.renderBarGraphComponent(this.state.selectedOrgId);
                  this.renderBillingComponent(this.state.selectedOrgId);
                  this.renderInvoiceListComponent(this.state.selectedOrgId);
                }
              );
            }
          });
        }
      }
    );
    document.getElementById('graphOrderPaper').style.height = height + 'px';
    document.getElementById('billOrderPaper').style.height = height + 'px';
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Function Name: onOrganizationChange
   * Description: handler for OrganizationSuggest select component
   * Param: selectedOrganization
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  onOrganizationChange(selectedOrganization) {
    if (this._isMounted) {
      this.setState(
        {
          selectedOrganization: selectedOrganization,
          selectedOrgId: selectedOrganization.value,
          graphComponentIsLoading: true,
          billComponentIsLoading: true,
          invoiceComponentIsLoading: true,
        },
        () => {
          this.renderBarGraphComponent(this.state.selectedOrgId);
          this.renderBillingComponent(this.state.selectedOrgId);
          this.renderInvoiceListComponent(this.state.selectedOrgId);
        }
      );
    }
  }

  /**
   * Function Name: onGraphIntervalChange
   * Description: Handler in changing the date interval of SMS Bar Graph component
   * Param: selectedInterval
   * Return: void
   * Author: Frances
   * Last Update By: Everard
   */
  onGraphIntervalChange(selectedInterval) {
    if (this._isMounted) {
      this.setState(
        {
          selectedInterval: selectedInterval,
          graphComponentIsLoading: true,
        },
        () => {
          this.renderBarGraphComponent(this.state.selectedOrgId);
        }
      );
    }
  }

  /**
   * Function Name: onGraphIntervalChange
   * Description: Handler in changing the view of SMS Bar Graph component
   * Param: selectedGraph
   * Return: void
   * Author: RJ
   * Last Update By: RJ
   */
  onGraphViewChange(view) {
    if (this._isMounted) {
      this.setState(
        {
          selectedGraph: view,
          graphComponentIsLoading: true,
        },
        () => {
          this.renderBarGraphComponent(this.state.selectedOrgId);
        }
      );
    }
  }

  /**
   * Function Name: renderBarGraphComponent
   * Description: Reusable function for readReports API calls
   * Param: orgId
   * Return: Reports
   * Author: Shirwyn
   * Last Update By: RJ
   */
  renderBarGraphComponent(orgId) {
    const { selectedGraph, selectedInterval } = this.state;
    const { readCampaignReports, readSGMReports, readBothReports } = this.props;

    // Filters out which graph report should it render
    const getReport = (date, interval) => {
      return selectedGraph.value === 'View Campaign'
        ? readCampaignReports(orgId, date, interval).then(() => {
            setTimeout(() => {
              if (this._isMounted) {
                this.setState({ graphComponentIsLoading: false });
              }
            }, 500);
          })
        : selectedGraph.value === 'View SGM'
        ? readSGMReports(orgId, date, interval).then(() => {
            setTimeout(() => {
              if (this._isMounted) {
                this.setState({ graphComponentIsLoading: false });
              }
            }, 500);
          })
        : readBothReports(orgId, date, interval).then(() => {
            setTimeout(() => {
              if (this._isMounted) {
                this.setState({ graphComponentIsLoading: false });
              }
            }, 500);
          });
    };

    // Date Intervals
    const DAILYINTERVAL = 7;
    const WEEKLYINTERVAL = 4;
    const MONTHLYINTERVAL = 12;

    switch (selectedInterval.label) {
      case 'Daily':
        return getReport('daily', DAILYINTERVAL);
      case 'Weekly':
        return getReport('weekly', WEEKLYINTERVAL);
      case 'Monthly':
        return getReport('monthly', MONTHLYINTERVAL);
      default:
        return null;
    }
  }

  /**
   * Function Name: renderBillingComponent
   * Description: Render the content of Billing component
   * Param: orgId
   * Return: void
   * Author: Everard
   * Last Update By: RJ
   */
  renderBillingComponent(orgId) {
    this.props.readInvoices(orgId).then(() => {
      this.props.invoiceUnbilled(orgId).then(() => {
        this.props.getOutstandingBalance(orgId).then(() => {
          if (this._isMounted && !this.props.invoice.error) {
            this.setState(
              {
                previousBill: this.props.invoiceInfo.AmountDue,
                outstandingBalance: this.props.outstanding_balance,
                toPay: this.props.unbilled_total,
              },
              () => {
                setTimeout(() => {
                  if (this._isMounted) {
                    this.setState({
                      billComponentIsLoading: false,
                    });
                  }
                }, 500);
              }
            );
          } else {
            this.setState({
              billComponentIsLoading: false,
            });
            this.handlePageSystemAlert('error', this.props.invoice.error);
          }
        });
      });
    });
  }

  /**
   * Function Name: renderInvoiceListComponent
   * Description: Render the invoice list table component
   * Param: orgId
   * Return: void
   * Author: Everard
   * Last Update By: RJ
   */
  renderInvoiceListComponent(orgId) {
    this.props.readInvoiceList(orgId, INVOICE_LIST_LIMIT).then(() => {
      if (this._isMounted && !this.props.invoice.error) {
        this.setState({
          invoices: this.props.invoices,
          invoiceComponentIsLoading: false,
        });
      } else {
        this.setState({
          invoiceComponentIsLoading: false,
        });
        this.handlePageSystemAlert('error', this.props.invoice.error);
      }
    });
  }

  /**
   * Function Name: handleAlertOncClose
   * Description: Triggers once the alert component requests to be closed according to the duration time
   * Param: handleAlertOnClose
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */

  handleAlertOnClose = () => {
    this.setState({
      snackBarOpen: false,
    });
  };

  /**
   * Function Name: handlePageSystemAlert
   * Description: Triggers once the alert is a system alert
   * Param: type, message
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */

  handlePageSystemAlert = (type, message) => {
    this.setState({
      snackBarOpen: true,
      snackBarConfig: {
        status: type,
        message: message,
      },
    });
  };

  /**
   * Function Name: totalCountResult
   * Description: calculates and shows the values of success and failed sms counts
   * Param: none
   * Return: successResult, failedResult
   * Author: RJ
   * Last Update By: RJ
   */

  totalCountResult = () => {
    const {
      SGMSentCount,
      SGMFailedCount,
      campaignSentCount,
      campaignFailedCount,
    } = this.props;
    const totalSuccess = SGMSentCount.map(function (num, idx) {
      return num + campaignSentCount[idx];
    });
    const totalFailed = SGMFailedCount.map(function (num, idx) {
      return num + campaignFailedCount[idx];
    });

    const successResult =
      this.state.selectedGraph.value === 'View SGM'
        ? SGMSentCount
        : this.state.selectedGraph.value === 'View Campaign'
        ? campaignSentCount
        : totalSuccess;

    const failedResult =
      this.state.selectedGraph.value === 'View SGM'
        ? SGMFailedCount
        : this.state.selectedGraph.value === 'View Campaign'
        ? campaignFailedCount
        : totalFailed;

    return { successResult, failedResult };
  };

  /**
   * Function Name: onHandleBillRefresh
   * Description: handles the refresher for billing component
   * Param: none
   * Return: sets loader for billing to true and recalls renderBillingComponent()
   * Author: RJ
   * Last Update By: RJ
   */

  onHandleBillRefresh = () => {
    this.setState({
      billComponentIsLoading: true,
    });
    // calls the api to re-render
    setTimeout(() => {
      this.renderBillingComponent(this.state.selectedOrgId);
      /**
       * The 10 Seconds interval was the minimum time needed ( in some cases ) to make another request from the Xero microservices
       * based on manual testing ).
       * In order for the interval to have a dynamic value, the backend should give the Retry-After HTTP header response and must handle it here.
       * for further explanation on handling the Xero Microservice Request Limitation.
       * https://developer.xero.com/documentation/guides/oauth2/limits/#uncertified-app-limits
       *  */
    }, [10000]);
  };

  /**
   * Function Name: onHandleInvoiceRefresh
   * Description: handles the refresher for invoice component
   * Param: none
   * Return: sets loader for invoice to true and recalls renderInvoiceListComponent()
   * Author: RJ
   * Last Update By: RJ
   */

  onHandleInvoiceRefresh = () => {
    this.setState({
      invoiceComponentIsLoading: true,
    });
    // calls the api to re-render
    setTimeout(() => {
      this.renderInvoiceListComponent(this.state.selectedOrgId);
      /**
       * The 10 Seconds interval was the minimum time needed ( in some cases ) to make another request from the Xero microservices
       * based on manual testing ).
       * In order for the interval to have a dynamic value, the backend should give the Retry-After HTTP header response and must handle it here.
       * for further explanation on handling the Xero Microservice Request Limitation.
       * https://developer.xero.com/documentation/guides/oauth2/limits/#uncertified-app-limits
       *  */
    }, [10000]);
  };

  handleServerStatus = (status) => {
    const INTERNAL_SERVER_ERROR_STATUS = 500;
    return status === INTERNAL_SERVER_ERROR_STATUS;
  };

  render() {
    const { labels, invoices } = this.props;

    return (
      <>
        <DashboardLayout
          permissions={this.state.permissions}
          graphComponent={
            this.state.graphComponentIsLoading ? (
              <Loader
                id='graph-Loading'
                adjustSmall={true}
                size={250}
                color={this.state.loaderColor}
              />
            ) : (
              <BarGraph
                id='graph-Layout'
                graphChange={this.onGraphViewChange}
                sortSelect={this.onGraphIntervalChange}
                selectedSort={this.state.selectedInterval}
                selectedGraph={this.state.selectedGraph}
                label={labels}
                sentCount={this.totalCountResult().successResult}
                failedCount={this.totalCountResult().failedResult}
              />
            )
          }
          billComponent={
            this.state.billComponentIsLoading ? (
              <Loader
                id='bill-Loading'
                adjustSmall={true}
                size={250}
                color={this.state.loaderColor}
              />
            ) : (
              <RefresherComponent
                refresher={this.onHandleBillRefresh}
                component={() => (
                  <BillComponent
                    id='bill-Layout'
                    previousBill={formatAmount(this.state.previousBill)}
                    lastPayment={formatAmount(this.state.lastPayment)}
                    paymentDate={this.state.paymentDate}
                    outstandingBalance={formatAmount(
                      this.state.outstandingBalance
                    )}
                    toPay={formatAmount(this.state.toPay)}
                  />
                )}
                status={this.handleServerStatus(
                  this.props.invoice.status.billStatus
                )}
              />
            )
          }
          invoiceComponent={
            this.state.invoiceComponentIsLoading ? (
              <Loader
                id='invoice-Loading'
                size={300}
                adjustSmall={window.screen.width <= 520}
                color={this.state.loaderColor}
              />
            ) : (
              <RefresherComponent
                refresher={this.onHandleInvoiceRefresh}
                component={() => (
                  <InvoiceTableView
                    id='invoice-Layout'
                    data={invoices}
                    orgId={this.state.selectedOrgId}
                    handleDownload={this.clickedInvoice}
                    permissions={this.state.permissions}
                    viewLoader={this.state.viewLoader}
                  />
                )}
                status={this.handleServerStatus(
                  this.props.invoice.status.invoiceStatus
                )}
              />
            )
          }
          suggestComponent={
            <OrganizationSuggest
              id='org-Selected'
              organizationName={this.state.selectedOrganization}
              changeHandler={this.onOrganizationChange}
              organizations={this.state.organization_names}
              permissions={this.state.permissions}
            />
          }
        />
        <PageSnackBar
          id='message-Handler'
          open={this.state.snackBarOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={this.handleAlertOnClose}
          snackBarConfig={this.state.snackBarConfig}
        />
      </>
    );
  }
}

const mapStatetoProps = ({ auth, organization, report, invoice }) => {
  const { AccessToken, permissions, organizationId } = auth;
  const { organizations } = organization;
  const {
    labels,
    campaignSentCount,
    campaignFailedCount,
    SGMSentCount,
    SGMFailedCount,
    error,
    system_error,
    status,
  } = report;
  const {
    invoiceInfo,
    unbilled_total,
    invoices,
    invoice_url,
    outstanding_balance,
    statusError,
  } = invoice;
  return {
    invoice,
    statusError,
    AccessToken,
    permissions,
    organizationId,
    organizations,
    labels,
    campaignSentCount,
    campaignFailedCount,
    SGMSentCount,
    SGMFailedCount,
    invoiceInfo,
    unbilled_total,
    invoices,
    invoice_url,
    outstanding_balance,
    error,
    system_error,
    status,
  };
};

export default connect(mapStatetoProps, {
  readOrganizations,
  readCampaignReports,
  readSGMReports,
  readBothReports,
  readInvoices,
  invoiceUnbilled,
  readInvoiceList,
  viewInvoice,
  getOutstandingBalance,
})(DashboardPage);
