/**
 * Component Name: Loader
 * Description: Renders Loader component
 * Param: size(otional)
 * Return: Component
 * Author: Frances
 * Last Update By: Frances
 */
import React from 'react';
import Lottie from 'lottie-react-web';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
//this is the design of the actual loader
import animation from '../../assets/animation.json';
import { LoaderStyles } from '../../assets/styles';
import hexToRGBA from "../../helpers/hexToRGBA";

//Loader Component has optional props size
//the default size of the loder is 500
const Loader = ({ size, adjustSmall, color }) => {
    let loaderSize = 500;
    if (size) {
        loaderSize = size
    }
    const classes = LoaderStyles();

    var loader = animation;
    // Layer Color Override
    loader.layers[0].shapes[0].it[2].c.k = hexToRGBA(color)
    loader.layers[1].shapes[0].it[2].c.k = hexToRGBA(color)
    loader.layers[2].shapes[0].it[2].c.k = hexToRGBA(color)
    loader.layers[3].shapes[0].it[2].c.k = hexToRGBA(color)
    loader.layers[4].shapes[0].it[2].c.k = hexToRGBA(color)

    return (

        <Grid container className={classes.conatiner}>
            <Grid item className={classes.item}>
                <Lottie
                    fillStyle='#000'
                    loop
                    options={{
                        animationData: animation
                    }}
                    width={adjustSmall ? loaderSize/2 : loaderSize}
                    height={loaderSize}
                />
            </Grid>
            
        </Grid>
        
    );
};
//Define the type of props used by loader
Loader.propTypes = {
    animation: PropTypes.any,
    color:PropTypes.any,
    size: PropTypes.number,
    adjustSmall: PropTypes.bool
};


export { Loader };