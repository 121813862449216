import {
  AUTH_LOGIN_SUCCESS,
  AUTH_PASSWORD_REQUEST,
  AUTH_LOGIN_INVALID_CREDENTIALS,
  AUTH_LOGIN_INVALID_EMAIL_ADDRESS,
  AUTH_LOGIN_FAILED,
  AUTH_CHANGE_PASS_SUCCESS,
  AUTH_CHANGE_PASS_INVALID_CREDENTIALS,
  AUTH_CHANGE_PASS_INVALID_PASSWORD,
  AUTH_INVALID_PASSWORD_FORMAT,
  AUTH_CHANGE_PASS_FAILED,
  AUTH_FORCE_CHANGE_PASS_SUCCESS,
  AUTH_FORCE_CHANGE_PASS_FAILED,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILED,
  AUTH_UPDATE,
  AUTH_EXPIRY,
  AUTH_REFRESH_TOKEN_SUCCESS,
  AUTH_REFRESH_TOKEN_FAILED,
  AUTH_REFRESH_TOKEN_MISSING_REFRESH_TOKEN,
  AUTH_REFRESH_TOKEN_BLANK_REFRESH_TOKEN,
  AUTH_REFRESH_TOKEN_INVALID_USER_CREDENTIALS,
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_FORGOT_PASSWORD_REQUEST_FAIL,
  AUTH_FORGOT_PASSWORD_REQUEST_INVALID_EMAIL,
  MAXIMUM_FORGOT_PASSWORD_ATTEMPT,
  UNEXPECTED_ERROR_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_FORGOT_PASSWORD_INVALID_CODE,
  AUTH_FORGOT_PASSWORD_INVALID_NEW_PASS_FORMAT,
  AUTH_FORGOT_PASSWORD_USER_DOES_NOT_EXIST,
  AUTH_FORGOT_PASSWORD_EXPIRED_CODE,
  AUTH_FORGOT_PASSWORD_MISSING_DETAILS,
  ORGANIZATION_INACTIVE,
} from '../actions/types';

const INITIAL_STATE = {
  email: '',
  password: '',
  firstName: '',
  currentUserRole: '',
  error: '',
  ChallengeName: '',
  IsForgotPassword: false,
  Session: '',
  AccessToken: '',
  ExpiresIn: '',
  TokenType: '',
  RefreshToken: '',
  IdToken: '',
  authStatus: '',
  permissions: [],
  organizationId: '',
  creatableUserRoles: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_UPDATE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        AccessToken: action.payload.authentication_result.AccessToken,
        ExpiresIn: action.payload.authentication_result.ExpiresIn,
        TokenType: action.payload.authentication_result.TokenType,
        RefreshToken: action.payload.authentication_result.RefreshToken,
        IdToken: action.payload.authentication_result.IdToken,
        permissions: action.payload.permissions,
        organizationId: action.payload.organization_id,
        authStatus: 'authorized',
        error: '',
        email: action.payload.user_details.email,
        firstName: action.payload.user_details.first_name,
        currentUserRole: action.payload.user_details.roles[0],
        creatableUserRoles: action.payload.creatable_user_roles,
      };
    case AUTH_PASSWORD_REQUEST:
      return {
        ...state,
        ChallengeName: action.payload.ChallengeName,
        Session: action.payload.Session,
        authStatus: 'authorized',
      };
    case AUTH_LOGIN_INVALID_CREDENTIALS:
      return {
        ...state,
        ChallengeName: '',
        Session: '',
        AccessToken: '',
        ExpiresIn: '',
        TokenType: '',
        RefreshToken: '',
        IdToken: '',
        authStatus: 'unauthorized',
        error: 'Invalid Credentials',
      };
    case AUTH_LOGIN_INVALID_EMAIL_ADDRESS:
      return {
        ...state,
        ChallengeName: '',
        Session: '',
        AccessToken: '',
        ExpiresIn: '',
        TokenType: '',
        RefreshToken: '',
        IdToken: '',
        authStatus: 'unauthorized',
        error: 'Invalid email address',
      };
    case AUTH_LOGIN_FAILED:
      return {
        ...state,
        ChallengeName: '',
        Session: '',
        AccessToken: '',
        ExpiresIn: '',
        TokenType: '',
        RefreshToken: '',
        IdToken: '',
        authStatus: 'unauthorized',
        error: 'login failed',
      };
    case ORGANIZATION_INACTIVE:
      return {
        ...state,
        ChallengeName: '',
        Session: '',
        AccessToken: '',
        ExpiresIn: '',
        TokenType: '',
        RefreshToken: '',
        IdToken: '',
        authStatus: 'unauthorized',
        error: 'inactive organization',
      };
    case AUTH_CHANGE_PASS_SUCCESS:
      return {
        ...state,
        ChallengeName: '',
        AccessToken: '',
        ExpiresIn: '',
        TokenType: '',
        RefreshToken: '',
        IdToken: '',
        permissions: [],
        organizationId: '',
        authStatus: '',
        firstName: '',
        currentUserRole: '',
        creatableUserRoles: [],
        error: '',
      };
    case AUTH_CHANGE_PASS_INVALID_CREDENTIALS:
      return {
        ...state,
        authStatus: 'change_pass_invalid_credentials',
        error: 'INVALID_USER_CREDENTIALS',
      };
    case AUTH_CHANGE_PASS_INVALID_PASSWORD:
      return {
        ...state,
        authStatus: 'change_pass_invalid_password',
        error: 'INVALID_PASSWORD',
      };
    case AUTH_INVALID_PASSWORD_FORMAT:
      return {
        ...state,
        authStatus: 'invalid_password_format',
        error: 'INVALID_PASSWORD_FORMAT',
      };
    case AUTH_CHANGE_PASS_FAILED:
      return {
        ...state,
        authStatus: 'change_pass_failed',
        error: 'LIMIT_EXCEEDED',
      };
    case AUTH_FORCE_CHANGE_PASS_SUCCESS:
      return {
        ...state,
        AccessToken: action.payload.authentication_result.AccessToken,
        ExpiresIn: action.payload.authentication_result.ExpiresIn,
        TokenType: action.payload.authentication_result.TokenType,
        RefreshToken: action.payload.authentication_result.RefreshToken,
        IdToken: action.payload.authentication_result.IdToken,
        permissions: action.payload.permissions,
        organizationId: action.payload.organization_id,
        authStatus: 'authorized',
        firstName: action.payload.user_details.first_name,
        currentUserRole: action.payload.user_details.roles[0],
        creatableUserRoles: action.payload.creatable_user_roles,
      };
    case AUTH_FORCE_CHANGE_PASS_FAILED:
      return {
        ...state,
        AccessToken: '',
        ExpiresIn: '',
        TokenType: '',
        RefreshToken: '',
        IdToken: '',
        authStatus: 'change_pass_failed',
        error: 'change pass failed',
      };
    case AUTH_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        IsForgotPassword: true,
        authStatus: 'forgot_password_verified',
      };
    case AUTH_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        IsForgotPassword: true,
        authStatus: 'forgot_password_changed',
      };
    case AUTH_FORGOT_PASSWORD_REQUEST_FAIL:
      return {
        ...state,
        IsForgotPassword: true,
        authStatus: 'forgot_password_not_verified',
      };
    case AUTH_FORGOT_PASSWORD_INVALID_CODE:
      return {
        ...state,
        IsForgotPassword: true,
        error: action.payload.error,
        authStatus: 'forgot_password_invalid_code',
      };
    case AUTH_FORGOT_PASSWORD_INVALID_NEW_PASS_FORMAT:
      return {
        ...state,
        IsForgotPassword: true,
        error: 'forgot_password_invalid_new_password',
      };
    case AUTH_FORGOT_PASSWORD_USER_DOES_NOT_EXIST:
      return {
        ...state,
        IsForgotPassword: true,
        error: 'forgot_password_user_does_not_exist',
      };
    case AUTH_FORGOT_PASSWORD_EXPIRED_CODE:
      return {
        ...state,
        IsForgotPassword: true,
        error: action.payload.error,
        authStatus: 'forgot_password_expired_code',
      };
    case AUTH_FORGOT_PASSWORD_MISSING_DETAILS:
      return {
        ...state,
        IsForgotPassword: true,
        error: 'forgot_password_something_went_wrong',
      };
    case AUTH_FORGOT_PASSWORD_REQUEST_INVALID_EMAIL:
      return {
        ...state,
        IsForgotPassword: true,
        authStatus: 'forgot_password_invalid_email',
      };
    case MAXIMUM_FORGOT_PASSWORD_ATTEMPT:
      return {
        ...state,
        IsForgotPassword: true,
        authStatus: 'forgot_password_maximum_attempt',
      };
    case UNEXPECTED_ERROR_FORGOT_PASSWORD:
      return {
        ...state,
        IsForgotPassword: true,
        error: 'forgot_password_unexpected_error',
      };
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        ChallengeName: '',
        IsForgotPassword: false,
        AccessToken: '',
        ExpiresIn: '',
        TokenType: '',
        RefreshToken: '',
        IdToken: '',
        permissions: [],
        creatableUserRoles: [],
        organizationId: '',
        firstName: '',
        currentUserRole: '',
        authStatus: '',
        error: '',
      };
    case AUTH_LOGOUT_FAILED:
      return {
        ...state,
        authStatus: 'logout_failed',
        error: action.payload,
      };

    case AUTH_EXPIRY:
      return {
        ...state,
        ChallengeName: '',
        IsForgotPassword: false,
        AccessToken: '',
        ExpiresIn: '',
        TokenType: '',
        RefreshToken: '',
        IdToken: '',
        permissions: [],
        creatableUserRoles: [],
        organizationId: '',
        firstName: '',
        currentUserRole: '',
        authStatus: 'expired',
        error: action.payload.error,
      };
    case AUTH_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        AccessToken: action.payload.authentication_result.AccessToken,
        ExpiresIn: action.payload.authentication_result.ExpiresIn,
        TokenType: action.payload.authentication_result.TokenType,
        IdToken: action.payload.authentication_result.IdToken,
        authStatus: 'authorized',
      };
    case AUTH_REFRESH_TOKEN_FAILED:
    case AUTH_REFRESH_TOKEN_MISSING_REFRESH_TOKEN:
    case AUTH_REFRESH_TOKEN_BLANK_REFRESH_TOKEN:
    case AUTH_REFRESH_TOKEN_INVALID_USER_CREDENTIALS:
      return {
        ...state,
        AccessToken: '',
        ExpiresIn: '',
        TokenType: '',
        IdToken: '',
        authStatus: 'refresh_token_failed',
        error: 'refresh token failed',
      };
    default:
      return state;
  }
}
