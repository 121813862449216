/**
 * Function Name: trimString
 * Description: removes leading and trailing spaces, replaces multiple spaces with one space
 * Param: date, interval
 * Return: new date format
 * Author: Shirwyn
 * Last Update By: Raymart
 */

const trimString = (el) => {
  el = el.replace(/(^\s*)|(\s*$)/gi, "").replace(/[ ]{2,}/gi, " ").replace(/\n +/, "\n");
  return el;
}

const removeSpecialCharacter = (el) => {
  el = el.replace(/[^-.\w\s]/gi, "").replace(/_/g, '');
  return el;
}

const limitCharacter = (el, limit) => {
  el = el.slice(0, limit);
  return el;
}

export { trimString, removeSpecialCharacter, limitCharacter };
