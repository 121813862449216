/**
 * Style Name: DashboardPageStyles
 * Description: Styles for Dashboard Layout
 * Param: void
 * Return: class styles
 * Author: Je
 * Last Update By: Toni
 */
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

const FormInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "240px",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    },
    marginTop: "10px"
  }
}))(InputBase);

export { FormInput };
