/**
 * Component Name: CommonTable
 * Description: Renders Reusable Table
 * Param: data, type, handleClickedRow
 * Return: CommonTable Component
 * Author: Shirwyn
 * Last Update By: Everard
 */

import React from 'react';
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { TableViewStyles } from '../../assets/styles';
import PropTypes from 'prop-types';

const CommonTable = ({
  headerArray,
  data,
  clickedRowHandler,
  arrangement,
  colWidth,
  page,
}) => {
  const classes = TableViewStyles();
  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <colgroup>
          {colWidth.map((cw, i) => {
            return <col key={'colWidth_' + i} width={cw} />;
          })}
        </colgroup>
        <TableHead className={classes.tableHead}>
          <TableRow>{getHeader(headerArray)}</TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            getRowsData(data, clickedRowHandler, arrangement, page)
          ) : (
            <TableRow>
              <TableCell colSpan={headerArray.length}>
                No records found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const getHeader = (headerArray) => {
  const classes = TableViewStyles();
  return headerArray.map((header) => {
    return (
      <TableCell
        key={header}
        className={
          header === 'Active' ? classes.activeHeader : classes.tableCellHead
        }
      >
        {header}
      </TableCell>
    );
  });
};

const RenderRow = (props) => {
  const classes = TableViewStyles();
  const dataArrangement = props.type;
  return dataArrangement.map((cell) => {
    return props.keys.map((key) => {
      if (cell === key) {
        if (props.data[key] === 'true') {
          return (
            <TableCell key={props.data[key]}>
              <span className={classes.activefilter}>Active</span>
            </TableCell>
          );
        } else if (props.data[key] === 'false') {
          return (
            <TableCell key={props.data[key]}>
              <span className={classes.inactivefilter}>Inactive</span>
            </TableCell>
          );
        } else {
          return (
            <TableCell key={props.data[key]}>
              {props.data[key]} {key === 'rate' && `/ ${props.data.rate_sgm}`}
            </TableCell>
          );
        }
      } else {
        return null;
      }
    });
  });
};

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  let textArray = this.split('_');
  let newTextArray = [];
  for (let i = 0; i < textArray.length; i++) {
    newTextArray.push(
      textArray[i].charAt(0).toUpperCase() + textArray[i].slice(1)
    );
  }
  let text = newTextArray.join(' ');
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const getRowsData = (data, clickedRowHandler, arrangement, page) => {
  const classes = TableViewStyles();
  const keys = Object.keys(data[0]);
  return data.map((row, index) => {
    if (page === 'campaign') {
      return (
        <TableRow
          key={index}
          onClick={() => clickedRowHandler(row.id, row.organization_id)}
          className={classes.tableRow}
        >
          <TableCell className={classes.tableCell}>
            {row.campaign_name}
          </TableCell>
          <TableCell>{row.status}</TableCell>
          <TableCell>
            {row.progress === undefined
              ? '?/?'
              : `${row.progress.completed_count}/${row.progress.all_count}`}
          </TableCell>
          <TableCell>{row.created_by}</TableCell>
          <TableCell>
            {!row.completed_timestamp ? '---' : row.completed_timestamp}
          </TableCell>
          <TableCell>{row.created_timestamp}</TableCell>
        </TableRow>
      );
    } else {
      if (page === 'user') {
        // format roles to user readable format
        if (row['roles'] && Array.isArray(row['roles'])) {
          row['roles'] = row['roles'].join(', ').capitalize();
        }
      }
      return (
        <TableRow
          key={index}
          onClick={() => clickedRowHandler(row.id)}
          className={classes.tableRow}
        >
          <RenderRow type={arrangement} data={row} keys={keys} />
        </TableRow>
      );
    }
  });
};

CommonTable.propTypes = {
  headerArray: PropTypes.array,
  data: PropTypes.array,
  clickedRowHandler: PropTypes.func,
  arrangement: PropTypes.array,
  colWidth: PropTypes.array,
  page: PropTypes.string,
};

export { CommonTable };
