import { makeStyles } from '@material-ui/core/styles';

const SideMenuStyles = makeStyles(theme =>({
    navLinkDecoration:{
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: '#6cf',
          opacity: '0.3'
       }
      
      },
      navLinkActiveDecoration:{
        '& li': {
          backgroundColor: '#6cf !important',
        }
      },
      pColor:{
        color:'white'
      },
      menuItemStyle:{
        padding: '0.1rem 1rem',
        color:'#fff',
        fontSize: '14px'
      },
      image: {
        height: '5.75rem',
        width: '10.337rem',
        margin: '1.187rem 1.582rem',
        display: 'block'
    
      }
}));

export default SideMenuStyles;