import {
    ORGANIZATION_READ_SUCCESS,
    ORGANIZATION_CREATE_SUCCESS,
    ORGANIZATION_DELETE_SUCCESS,
    ORGANIZATION_ERROR,
    ORGANIZATION_UPDATE_SUCCESS,
    ORGANIZATION_VIEW_SUCCESS,
    SYSTEM_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    organizationInfo: {
        org_id: '',
        aws_secret: '',
        org_name: '',
        date_created: '',
        created_by: '',
        created_timestamp: '',
        active: '',
        rate: ''
    },
    organizations: [],
    total_count: 0,
    current_page: 1,
    page_count: 1,
    view_start: 1,
    view_end: 1,
    error: "",
    system_error: false
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ORGANIZATION_READ_SUCCESS:
            return {
                ...state,
                organizations: action.payload.org_list,
                total_count: action.payload.total_count,
                current_page: action.payload.current_page,
                page_count: action.payload.page_count,
                view_start: action.payload.view_start,
                view_end: action.payload.view_end,
                error: "",
                system_error: false
            };
        case ORGANIZATION_CREATE_SUCCESS:
            return {
                ...state,
                organizations: [{
                    ...state.organizations,
                    ...action.payload
                }],
                error: "",
                status: "",
                system_error: false
            };
        case ORGANIZATION_DELETE_SUCCESS:
            return {
                ...state,
                organizations: state.organizations.filter(
                    organization => organization.org_id !== action.payload
                )
            };
        case ORGANIZATION_UPDATE_SUCCESS:
            return {
                ...state,
                organizationInfo: action.payload,
                error: "",
                status: "",
                system_error: false,
                organizations: state.organizations.map(organization =>
                    organization.org_id === action.payload.org_id ? action.payload : organization
                )
            };
        case ORGANIZATION_VIEW_SUCCESS:
            return {
                ...state,
                organizationInfo: action.payload
            };
        case ORGANIZATION_ERROR:
            return {
                ...state,
                error: action.payload.error,
                status: action.payload.status,
                system_error: false,
            };
        case SYSTEM_ERROR:
            return {
                ...state,
                error: action.payload.error,
                status: action.payload.status,
                system_error: true
            };
        default:
            return state;
    }
}