import {
  SGM_READ_SUCCESS,
  SGM_ERROR,
  SGM_FILTER_SUCCESS,
  UNAUTHORIZED_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
  sgmData: {
    completed_timestamp: '',
    created_timestamp: '',
    failure_reason: null,
    is_priority: true,
    message_id: '',
    organization_id: '',
    rate: '',
    retry_count: '',
    status: '',
    masked_mobile_number: '',
  },
  sgm: [],
  filteredMessages: [],
  total_count: 0,
  message_id: '',
  created_timestamp: '',
  error: '',
  is_last_page: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SGM_FILTER_SUCCESS:
      return {
        ...state,
        filteredMessages: action.payload.sgm_list,
        total_count: action.payload.total_count,
        message_id: action.payload.MESSAGE_ID,
        created_timestamp: action.payload.CREATED_TIMESTAMP,
        is_last_page: action.payload.is_last_page,
      };
    case SGM_READ_SUCCESS:
      return {
        ...state,
        sgmData: action.payload,
      };
    case SGM_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: 'unable_to_retrieve_sgm',
      };
    case UNAUTHORIZED_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    default:
      return state;
  }
}
