/**
 * Style Name: TableViewStyles
 * Description: Styles for Table View Component
 * Param: void
 * Return: class styles
 * Author: Shirwyn
 * Last Update By: Frances
 */
import { makeStyles } from '@material-ui/core/styles';
import { pp_blue_light_color, pp_blue_color, black_color } from './types';
const TableViewStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    textAlign: 'left',
  },
  tableRow: {
    borderLeft: '5px solid #fff',
    '&:hover': {
      backgroundColor: pp_blue_light_color,
      borderColor: pp_blue_color,
    },
  },
  tableCellHead: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: black_color,
    opacity: '0.5',
  },
  activefilter: {
    display: 'inline-block',
    borderRadius: '3px',
    textAlign: 'center',
    color: '#fff',
    textTransform: 'capitalize',
    backgroundColor: '#45cb5a',
    width: '9rem',
    padding: '.50em 0',
  },
  inactivefilter: {
    display: 'inline-block',
    borderRadius: '3px',
    textAlign: 'center',
    color: '#fff',
    textTransform: 'capitalize',
    backgroundColor: '#c9c9c9',
    width: '9rem',
    padding: '.50em 0',
  },
  activeHeader: {
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 'bold',
    color: black_color,
    opacity: '0.5',
  },
  tableCell: {
    width: 500,
    maxWidth: 500,
    wordBreak: 'break-all',
  },
}));

export { TableViewStyles };
