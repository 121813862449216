/**
 * Style Name: CommonStyles
 * Description: Common Styles
 * Param: void
 * Return: class styles
 * Author: Frances
 * Last Update By: RJ
 */
import { makeStyles } from '@material-ui/core/styles';

const BarGraphStyles = makeStyles({
  root: {
    display: 'inherit',
    height: '15rem',
  },
  select: {
    width: '8rem',
  },
  customSelect: {
    width: '100%',
  },
});

export { BarGraphStyles };
