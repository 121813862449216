import React from "react";
import { Fade, Backdrop, Modal } from "@material-ui/core";
import { DesktopAccessDisabled } from "@material-ui/icons";
import { ModalStyles } from "../../assets/styles";

/**
 * Function Name: getModalStyle
 * Description: Centers modal
 * Return: top, left and transform properties
 * Author: Toni
 * Last Update: Toni
 */

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
};

const SessionModal = ({ isVisible, handleClose }) => {
  const classes = ModalStyles();
  const [modalStyle] = React.useState(getModalStyle);
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isVisible}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isVisible}>
        <div style={modalStyle} className={classes.modal}>
          <DesktopAccessDisabled fontSize="large">
            Session Expiry
          </DesktopAccessDisabled>
          <h2 className={classes.modalTitle}> Session Expired </h2>
          <p className={classes.modaldescription}>
            Your session has expired, please login again.
          </p>
        </div>
      </Fade>
    </Modal>
  );
};

export { SessionModal, getModalStyle };
