/**
 * Class Name: OrganizationModal
 * Description: OrganizationModal component.
 * Param: N/A
 * Return: OrganizationModal Component
 * Author: Shirwyn
 * Last Update: Nelly
 */
import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { OrganizationModalStyles } from "./OrganizationModalStyles";
import { Loader } from "../../components";

const OrganizationModal = ({
  isOpen,
  activePage,
  closeModal,
  selectedItem,
  deleteItem,
  activateItem,
  isLoadingModal,
  loaderColor,
  inputChange,
  orgName,
  isActive,
}) => {
  const classes = OrganizationModalStyles();
  const isEnabled = orgName === selectedItem;

  return (
    <Dialog open={isOpen} maxWidth="xs" id="organization-delete-modal">
      <Grid container direction="row" className={classes.container}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <DialogTitle
            className={isActive ? classes.dialogTitle : classes.activateTitle}
            id="delete-modal-title"
          >
            {isActive ? "Disable " : "Enable "}
            {activePage}
          </DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            className={classes.closeButton}
            onClick={closeModal}
            id="delete-modal-close-button"
            disabled={isLoadingModal}
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>

      <Typography
        variant="body1"
        className={classes.content}
        id="delete-modal-content"
      >
        Are you sure you want to {isActive ? "disable " : "enable "}{" "}
        <span className={classes.orgName}>{selectedItem}</span>? Please confirm
        and type in the Organization name before{" "}
        {isActive ? "disabling" : "enabling"} the organization.
      </Typography>

      <TextField
        variant="outlined"
        placeholder="Organization Name"
        name="organizationName"
        value={orgName}
        onChange={inputChange}
        className={classes.inputOrgName}
        autoFocus
      />

      <Grid container direction="row" className={classes.buttonContainer}>
        <Grid item xs>
          <Button
            className={
              isActive ? classes.deleteButton : classes.disabledDeleteButton
            }
            variant="contained"
            color="primary"
            onClick={isActive ? deleteItem : activateItem}
            id="delete-modal-confirm-button"
            disabled={isLoadingModal || !isEnabled}
          >
            {isLoadingModal ? (
              <div className={classes.loader}>
                <Loader
                  size={70}
                  adjustSmall={window.screen.width <= 520 ? true : false}
                  color={loaderColor}
                />
              </div>
            ) : isActive ? (
              "Yes, Disable "
            ) : (
              "Yes, Enable "
            )}{" "}
            Organization
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            onClick={closeModal}
            className={classes.cancelButton}
            id="delete-modal-cancel-button"
            disabled={isLoadingModal}
          >
            {isActive ? "No, Keep " : "No, Keep disable "} {activePage}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
//Define proptypes of OrganizationModal
OrganizationModal.propTypes = {
  isOpen: PropTypes.bool,
  activePage: PropTypes.string,
  closeModal: PropTypes.func,
  selectedItem: PropTypes.string,
  deleteItem: PropTypes.func,
};

export { OrganizationModal };
