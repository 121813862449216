/**
 * Class Name: OrganizationSubheader
 * Description: OrganizationSubheader component.
 * Param: N/A
 * Return: OrganizationSubheader Component
 * Author: Shirwyn
 * Last Update: Shirwyn
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Button} from '@material-ui/core';
import {OrganizationSubheaderStyles} from './OrganizationSubheaderStyles';
import {CREATE_ORGANIZATIONS} from "../../helpers";

const OrganizationSubheader = ({title, btn, onCreate, permissions}) => {
    const classes = OrganizationSubheaderStyles();

    return (
        <div id='organization-subheader' className={classes.subHeader}>
            <Typography variant='h4' gutterBottom>{title}</Typography>
            {
                permissions.includes(CREATE_ORGANIZATIONS) ?
                    <Button
                        id='organization-subheader-create-button'
                        onClick={onCreate}
                        variant='outlined'
                        className={classes.button}
                    >
                        {btn}
                    </Button> : null
            }
        </div>
    );
}
//Define OrganizationSubheader proptypes
OrganizationSubheader.propTypes = {
    title: PropTypes.string,
    btn: PropTypes.string,
    onCreate: PropTypes.func
}

export {OrganizationSubheader};
