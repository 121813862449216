/**
 * Component Name: ForgotPasswordCode
 * Description: Displays the forgot password code
 * Param: onSubmitCode, isCodeSubmitted, onChangeCodeInput, code, new_password, onChangeNewPass, confirm_new_password, onChangeConfirmNewPass, isPassCodeInvalid, codeError, isNewPassInvalid, newPassError, isConfirmNewPassInvalid, confirmNewPassError, buttonLabel, errorHandler
 * Return: component
 * Author: Gian
 * Last Update By: Gian
 */
import React from 'react';
import {
    Paper,
    InputLabel,
    FormControl,
    Button, List, ListItem

} from '@material-ui/core';
import {FormInput} from "../../assets/styles";
import {ForgotPasswordCodeStyles} from "./ForgotPasswordCodeStyles";
import FormHelperText from "@material-ui/core/FormHelperText";

const ForgotPasswordCode = ({
                                onSubmitCode,
                                isCodeSubmitted,
                                onChangeCodeInput,
                                code,
                                new_password,
                                onChangeNewPass,
                                confirm_new_password,
                                onChangeConfirmNewPass,
                                isPassCodeInvalid,
                                codeError,
                                isNewPassInvalid,
                                newPassError,
                                isConfirmNewPassInvalid,
                                confirmNewPassError,
                                buttonLabel,
                                errorHandler,
                                loaderForChangePass
                            }) => {
    const classes = ForgotPasswordCodeStyles();
    return (
        <Paper className={classes.formContainer} id="forgot-page-container">
            <img
                src={require("../../assets/sms-full.png")}
                alt="pearlpay"
                className={classes.image}
            />
            <form>
                <div>
                    <FormControl className={classes.margin} id="email-input-container">
                        <InputLabel
                            shrink
                            className={classes.inputLabel}
                        >
                            Code
                        </InputLabel>
                        <FormInput
                            name="code"
                            value={code}
                            onChange={onChangeCodeInput}
                        />
                        {
                            isPassCodeInvalid ? <FormHelperText error>{codeError}</FormHelperText> : null
                        }
                    </FormControl>
                </div>
                <div>
                    <FormControl className={classes.margin} id="email-input-container">
                        <InputLabel
                            shrink
                            className={classes.inputLabel}
                        >
                            New Password
                        </InputLabel>
                        <FormInput
                            name="new_password"
                            type="password"
                            value={new_password}
                            onChange={onChangeNewPass}
                        />
                        {
                            isNewPassInvalid ? <FormHelperText error>{newPassError}</FormHelperText> : null
                        }
                    </FormControl>
                </div>
                <div>
                    <FormControl className={classes.margin} id="email-input-container">
                        <InputLabel
                            shrink
                            htmlFor="bootstrap-input"
                            className={classes.inputLabel}
                        >
                            Confirm New Password
                        </InputLabel>
                        <FormInput
                            name="confirm_new_password"
                            type="password"
                            value={confirm_new_password}
                            onChange={onChangeConfirmNewPass}
                        />
                        {
                            isConfirmNewPassInvalid ?
                                <FormHelperText error>{confirmNewPassError}</FormHelperText> : null
                        }
                    </FormControl>
                </div>
                {errorHandler}
                <InputLabel shrink htmlFor="bootstrap-input" className={classes.inputLabel2}>
                    Minimum length is 6 characters, maximum is 98.
                </InputLabel>
                <List className={classes.guideLabel} id="guide-container">
                    <ListItem className={classes.listItem}>
                        - Must contain at least one number.
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        - Must contain at least one special character (e.g. !, @, #, $ etc.) -- spaces do not count.
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        - Must contain at least one lowercase and one uppercase alphabet.
                    </ListItem>
                </List>
                <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    color="primary"
                    className={classes.loginButton}
                    onClick={(event) => onSubmitCode(event)}
                    disabled={isCodeSubmitted}
                >
                    {
                        isCodeSubmitted ? <div className={classes.loader}>{loaderForChangePass}</div> : "Submit"
                    }
                </Button>
            </form>
        </Paper>
    );
}

export {ForgotPasswordCode};