/**
 * Style Name: OrgDropDownStyles
 * Description: Styles for org dropdown in users & campaign
 * Param: void
 * Return: class styles
 * Author: Nelly
 * Last Update By:
 */

const OrgDropDownStyles = {
  activeOrg: {
    backgroundColor: '#45cb5a',
    display: 'inline-block',
    borderRadius: '3.5px',
    textAlign: 'center',
    color: '#fff',
    textTransform: 'capitalize',
    width: '1rem',
    float: 'right',
    height: '1rem',
    fontSize: '.75rem',
    padding: '.1rem',
  },
  inActiveOrg: {
    backgroundColor: '#c9c9c9',
    display: 'inline-block',
    borderRadius: '3.5px',
    textAlign: 'center',
    color: '#fff',
    textTransform: 'capitalize',
    width: '1rem',
    float: 'right',
    height: '1rem',
    fontSize: '.65rem',
    padding: '.1rem',
  },
};

export { OrgDropDownStyles };
