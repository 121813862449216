import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from "redux-persist";
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import reduxThunk from 'redux-thunk';

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import reducers from '../reducers';

const encryptor = createEncryptor({
    secretKey: 'p3@r1p@y-th3-b3tt3r-w@y'
});

const saveSubsetBlacklistFilter = createBlacklistFilter('auth', ['authStatus']);

const persistConfig = {
    key: 'state',
    storage: storage,
    stateReconciler: autoMergeLevel2,
    transforms: [saveSubsetBlacklistFilter, encryptor]
};

const pReducer = persistReducer(persistConfig, reducers);

export const store = createStore(pReducer, {}, applyMiddleware(reduxThunk));
export const persistor = persistStore(store);