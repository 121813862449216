import { makeStyles } from '@material-ui/core/styles';
import {black_color} from "../../assets/styles/types";

const SgmSideDrawerStyles = makeStyles(() => ({
    backArrow: {
        fontSize: '24px',
        color: black_color,
        opacity: '0.5'
    },
    sideDrawer: {
        width: window.screen.width <= 720 ? '100vw' : '30vw',
        overflowX: 'hidden'
    },
    backButton: {
        fontSize: '14px ',
        color: black_color,
        opacity: '0.5',
        textTransform: 'capitalize',
        margin: '1rem 1.5rem 0.25rem',
        padding: '0.25rem',
        '&:hover': {
            backgroundColor: '#fff'
        }
    },
    sgmFormHeader: {
        margin: '0 1rem 0 2rem !important'
    },
    sgmTitle: {
        fontSize: '25px',
        fontWeight: 'bold'
    },
    divider: {
        margin: '1.5rem 0'
    },
    container: {
        justifyContent: window.screen.width <= 720 ? '' : 'space-between',
        padding: window.screen.width <= 720 ? '0' :'1rem 2rem 0'
    },
    gridItem: {
        flex: window.screen.width <= 720 ? 'auto': '',
        display: 'flex'
    }
}));

export default SgmSideDrawerStyles;