import { makeStyles } from '@material-ui/core/styles';
import {black_color} from "../../assets/styles/types";

const SgmViewStyles = makeStyles(() => ({
    backButton: {
        fontSize: '14px ',
        color: black_color,
        opacity: '0.5',
        textTransform: 'capitalize',
        margin: '0.75rem 2rem 0',
        '&:hover': {
            backgroundColor: '#fff'
        }
    },
    backArrow: {
        fontSize: '24px',
        color: black_color,
        opacity: '0.5'
    },
    sideDrawer: {
        width: window.screen.width <= 720 ? '100vw' : '30vw'
    },
    sideDrawerTitle:{
        fontSize: '15px',
        color: black_color,
        opacity: '0.5',
        fontWeight: 'bold',
        margin: '0 0 1rem 2rem !important'
    },
    sideDrawerData:{
        fontSize: '15px',
        fontWeight: 'bold',
        margin: '0 0 2.5rem 2rem !important'
    },
    sgmTitle: {
        fontSize: '25px',
        fontWeight: 'bold',
        margin: '0 0 2.5rem 2rem !important'
    },
    sgmViewHeader: {
        margin: '0 0 2.5rem 2rem !important'
    },
    sgmAlign: {
        padding: '1rem 0 0 0.5rem'
    }
}));

export default SgmViewStyles;