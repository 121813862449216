/**
 * Style Name: BillingCommonStyles
 * Description: Billing Common Styles
 * Param: void
 * Return: class styles
 * Author: Jeremiah
 * Last Update By: Jeremiah
 */
import { makeStyles } from '@material-ui/core/styles';

const BillingCommonStyles = makeStyles( theme => ({
    fluid: {
        width: '100%'
    },
    inputTitle: {
        padding: '0.5rem'
    }
  }));

export { BillingCommonStyles };
