/**
 * Style Name: DashboardPageStyles
 * Description: Styles for Dashboard Layout
 * Param: void
 * Return: class styles
 * Author: Frances
 * Last Update By: Jeremiah
 */
import { makeStyles } from '@material-ui/core/styles';

const DashboardPageStyles = makeStyles(theme => ({
  graphOrder: {
    order: 2,
    [theme.breakpoints.up("sm")]: {
      order: 1
    }
  },
  billOrder: {
    order: 1,
    [theme.breakpoints.up("sm")]: {
      order: 2
    }
  },
  card: {
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up("sm")]: {
      height: window.screen.height / 4
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      height: 'auto'
    }
  },
  cardHeight:{
    height: 'auto',
    '@media (max-width: 1024px)': {
      height: 'auto !important'
    }
  },
  select: {
    display: 'block'
  },
  suggestions: {
    position: 'absolute',
    margin: 0,
    padding: 0,
    zIndex: 1,
    width: '14rem'
  },
  menuItem: {
    paddingRight: '0.6rem'
  }
}));

export { DashboardPageStyles };
