import axios from 'axios';
import {
  USER_READ_SUCCESS,
  USER_VIEW_SUCCESS,
  USER_CREATE_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_SERVER_ADDRESS,
} from './types';
import { convertTimestamp, errorHandler } from '../helpers';
import { getHeaders } from './AuthActions';

const ROOT_URL = USER_SERVER_ADDRESS;

/**
 * Action Name: readUsers
 * Description:
 *      retrieve all users from a specific organization
 * Param: org_id, active, page_size, page_number
 * Return: array of users
 * Author: Shirwyn
 * Last Update By: Raymart
 */
export const readUsers = (
  org_id,
  active = 'all',
  page_size = 0,
  page_number = 1
) => async (dispatch) => {
  const users = [];
  try {
    let response;
    if (page_size > 0) {
      response = await axios.get(
        `${ROOT_URL}/organizations/${org_id}/users?page=${page_number}&page_size=${page_size}&active_status=${active}`,
        await getHeaders()
      );
    } else {
      response = await axios.get(
        `${ROOT_URL}/organizations/${org_id}/users`,
        await getHeaders()
      );
    }
    if (response.status === 200) {
      response.data.requested_page.forEach((user) => {
        user.id = user.user_id;
        user.active = user.active.toString();
        user.created_by = user.created_by.name;
        user.created_timestamp = convertTimestamp(user.created_timestamp);
        users.push(user);
      });
      dispatch({
        type: USER_READ_SUCCESS,
        payload: {
          users_list: users,
          total_count: response.data.total_count,
          current_page: response.data.current_page,
          page_count: response.data.page_count,
          view_start: response.data.view_start,
          view_end: response.data.view_end,
        },
      });
    }
  } catch (e) {
    dispatch(errorHandler('User', e));
  }
};

/**
 * Action Name: viewUsers
 * Description:
 *      retrieve specific user
 * Param: org_id, user_id
 * Return: object with user_id,
 *          email,
 *          first_name,
 *          last_name,
 *          middle_name,
 *          organization_id,
 *          roles,
 *          created_by,
 *          created_timestamp,
 *          active
 * Author: Shirwyn
 * Last Update By: Raymart
 */
export const viewUser = (org_id, user_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${org_id}/users/${user_id}`,
      await getHeaders()
    );
    if (response.status === 200) {
      const data = {
        ...response.data,
        roles: response.data.roles.map((role) =>
          role
            .toLowerCase()
            .split('_')
            .map((str) => str.charAt(0).toUpperCase() + str.substring(1))
            .join(' ')
        ),
      };

      dispatch({
        type: USER_VIEW_SUCCESS,
        payload: data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('User', e, 'VIEW_USER'));
  }
};

/**
 * Action Name: createUsers
 * Description:
 *      create a new user for an organization
 * Param: org_id, payload
 * Return: new single data of user
 * Author: Shirwyn
 * Last Update By: Nelly
 */
export const createUser = (org_id, payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/organizations/${org_id}/users`,
      payload,
      await getHeaders()
    );
    if (response.status === 201) {
      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('User', e, 'CREATE_USER'));
  }
};

/**
 * Action Name: updateUsers
 * Description:
 *      updates an specific user
 * Param: org_id, user_id, payload
 * Return: updated user
 * Author: Shirwyn
 * Last Update By: Raymart
 */
export const updateUser = (org_id, user_id, payload) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${ROOT_URL}/organizations/${org_id}/users/${user_id}`,
      payload,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('User', e, 'UPDATE_USER'));
  }
};

/**
 * Action Name: deleteUsers
 * Description:
 *      deletes a specific user
 * Param: org_id, user_id
 * Return: array without the deleted user
 * Author: Shirwyn
 * Last Update By: Nelly
 */
export const deleteUser = (org_id, user_id) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${ROOT_URL}/organizations/${org_id}/users/${user_id}`,
      await getHeaders()
    );
    if (response.status === 204) {
      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: user_id,
      });
    }
  } catch (e) {
    dispatch(errorHandler('User', e, 'DELETE_USER'));
  }
};
