/**
 * Class Name: UserForm
 * Description: UserForm component.
 * Param: N/A
 * Return: UserForm Component
 * Author: Shirwyn
 * Last Update: RJ
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Typography,
  TextField,
  Button,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import { CustomSelect, Loader } from '../../components';
import { convertTimestamp } from '../../helpers';
import { UserFormStyles } from './UserFormStyles';
import { pp_blue_color_loader } from '../../assets/styles/types';

const UserForm = ({
  status,
  errorCode,
  submitUser,
  formType,
  createdBy,
  dateCreated,
  handleInputChange,
  email,
  firstName,
  lastName,
  middleName,
  roles,
  hasFormError,
  emailError,
  firstNameError,
  lastNameError,
  middleNameError,
  roleSelect,
  roleChangeHandler,
  isButtonLoading,
  onCancel,
  btnText,
  formatInput,
  adminAccess,
  organizationName,
}) => {
  const classes = UserFormStyles();
  const type = formType === 'Submit' ? 'create' : 'edit';

  const roleToRemove = 'super_admin';

  const filteredRole = roleSelect.filter((item) => item.value !== roleToRemove);

  return (
    <FormControl className={classes.form}>
      <Grid container item direction='column' xs={12}>
        <Grid item xs className={classes.gridMargin}>
          <Typography variant='body1' className={classes.label}>
            First Name
          </Typography>
          <TextField
            autoFocus
            className={classes.textField}
            name='first_name'
            value={firstName}
            onChange={handleInputChange}
            margin='normal'
            variant='outlined'
            placeholder='First Name'
            error={
              (firstName === '' && type !== 'create') ||
              (firstName === '' && type === 'create' && hasFormError)
            }
            id='first-name-input'
            onBlur={formatInput}
            initvalue='Pedro'
          />
          {(firstName === '' && type !== 'create') ||
          (firstName === '' && type === 'create' && hasFormError) ? (
            <FormHelperText id="firstNameError" error>First Name is required</FormHelperText>
          ) : null}
          {firstNameError !== '' && firstName !== '' ? (
            <FormHelperText error>{firstNameError}</FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs className={classes.gridMargin}>
          <Typography variant='body1' className={classes.label}>
            Last Name
          </Typography>
          <TextField
            className={classes.textField}
            name='last_name'
            value={lastName}
            onChange={handleInputChange}
            margin='normal'
            variant='outlined'
            placeholder='Last Name'
            error={
              (lastName === '' && type !== 'create') ||
              (lastName === '' && type === 'create' && hasFormError)
            }
            id='last-name-input'
            onBlur={formatInput}
            initvalue='Penduko'
          />
          {(lastName === '' && type !== 'create') ||
          (lastName === '' && type === 'create' && hasFormError) ? (
            <FormHelperText id="lastNameError" error>Last Name is required</FormHelperText>
          ) : null}
          {lastNameError !== '' && lastName !== '' ? (
            <FormHelperText error>{lastNameError}</FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs className={classes.gridMargin}>
          <Typography variant='body1' className={classes.label}>
            Middle Name
          </Typography>
          <TextField
            className={classes.textField}
            name='middle_name'
            value={middleName}
            onChange={handleInputChange}
            margin='normal'
            variant='outlined'
            placeholder='Middle Name'
            error={
              (middleName === '' && type !== 'create') ||
              (middleName === '' && type === 'create' && hasFormError)
            }
            id='middle-name-input'
            onBlur={formatInput}
            initvalue='DEF Bank'
          />
          {(middleName === '' && type !== 'create') ||
          (middleName === '' && type === 'create' && hasFormError) ? (
            <FormHelperText error>Middle Name is required</FormHelperText>
          ) : null}
          {middleNameError !== '' && middleName !== '' ? (
            <FormHelperText error>{middleNameError}</FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs className={classes.gridMargin}>
          <Typography variant='body1' className={classes.label}>
            Email
          </Typography>
          {type === 'create' ? (
            <TextField
              className={classes.textField}
              name='email'
              value={email}
              onChange={handleInputChange}
              type='email'
              margin='normal'
              variant='outlined'
              placeholder='Email'
              error={
                (email === '' && type === 'create' && hasFormError) ||
                (status === 422 && errorCode === 'USERNAME_ALREADY_TAKEN') ||
                emailError !== ''
              }
              id='email-input'
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.preventDefault();
                }
              }}
              initvalue='sample@mail.com'
            />
          ) : (
            <Typography
              variant='body1'
              className={classes.data}
              id='display-only-email'
            >
              {email}
            </Typography>
          )}
          {(email === '' && type !== 'create') ||
          (email === '' && type === 'create' && hasFormError) ? (
            <FormHelperText id="idBlankError" error>Email address is required</FormHelperText>
          ) : null}
          {status === 422 && errorCode === 'USERNAME_ALREADY_TAKEN' ? (
            <FormHelperText id="emailExist" error>Email already exists</FormHelperText>
          ) : null}
          {emailError !== '' && email !== '' ? (
            <FormHelperText error>{emailError}</FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs className={classes.gridMargin}>
          <Typography variant='body1' className={classes.label}>
            Role
          </Typography>
          <CustomSelect
            customSelectID='role-input-select'
            customName='roles'
            customClassName={classes.select}
            selectedValue={roles}
            onChangeHandler={roleChangeHandler}
            customOptions={
              adminAccess && organizationName.canHaveSuperAdmin ? roleSelect : filteredRole
            }
            searchable={false}
            error={status === 422 && errorCode === 'CANNOT_HOST_SUPER_ADMIN'}
            initvalue='admin'
          />
          {(roles === '' && type !== 'create') ||
          (roles === '' && type === 'create' && hasFormError) ? (
            <FormHelperText error>Role is required</FormHelperText>
          ) : null}
          {status === 422 && errorCode === 'CANNOT_HOST_SUPER_ADMIN' ? (
            <FormHelperText id="orgAdminInvalid" error>
              Organization cannot host super admin
            </FormHelperText>
          ) : null}
          {status === 422 && errorCode === 'ORGANIZATION_INACTIVE' ? (
            <FormHelperText id="orgInactiveError" error>
              Can't create user. Organization is inactive
            </FormHelperText>
          ) : null}
        {status === 403 && errorCode === 'INADEQUATE_PERMISSION'? (
            <FormHelperText id="userNotAuthorized" error>
            You are not authorized to update this user
            </FormHelperText>
          ) : null}
        </Grid>
        {type === 'edit' ? (
          <div>
            <Typography variant='body1' className={classes.label}>
              Created by
            </Typography>
            <Typography
              variant='body1'
              className={classes.data}
              id='createdby-data'
            >
              {createdBy}
            </Typography>
            <Typography variant='body1' className={classes.label}>
              Date Created
            </Typography>
            <Typography
              variant='body1'
              className={classes.data}
              id='date-created-data'
            >
              {convertTimestamp(dateCreated)}
            </Typography>
          </div>
        ) : null}
        <div className={classes.divBtn}>
          <Button
            id='user-form-button'
            variant='contained'
            className={classes.button}
            onClick={submitUser}
            disabled={isButtonLoading}
          >
            {isButtonLoading ? (
              <div className={classes.buttonLoader}>
                <Loader
                  size={60}
                  adjustSmall={window.screen.width <= 520}
                  color={pp_blue_color_loader}
                />
              </div>
            ) : (
              formType
            )}
          </Button>
          <Button
            id='oncancel-button'
            onClick={onCancel}
            variant='contained'
            className={classes.CancelBtn}
          >
            {btnText}
          </Button>
        </div>
        {status === 400 ? (
          <FormHelperText id="regError" error>
            Registration failed! Please check your inputs then try again.
          </FormHelperText>
        ) : null}
      </Grid>
    </FormControl>
  );
};

//Define proptypes used by UserForm
UserForm.propTypes = {
  submitUser: PropTypes.func,
  formType: PropTypes.string,
  createdBy: PropTypes.string,
  dateCreated: PropTypes.string,
  handleInputChange: PropTypes.func,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  roles: PropTypes.object,
  hasFormError: PropTypes.bool,
  emailError: PropTypes.string,
  firstNameError: PropTypes.string,
  lastNameError: PropTypes.string,
  middleNameError: PropTypes.string,
  status: PropTypes.number,
  roleSelect: PropTypes.array,
  roleChangeHandler: PropTypes.func,
  isButtonLoading: PropTypes.bool,
};

export { UserForm };
