import { makeStyles } from "@material-ui/core/styles";
import { pp_blue_color, black_color } from "./types";

const modalWidth = "15rem";
const ModalStyles = makeStyles(theme => ({
  modal: {
    position: "absolute",
    width: "15rem",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${modalWidth}px)`
    },
    backgroundColor: "#fff",
    textAlign: "center",
    padding: theme.spacing(3),
    borderRadius: "10px",
    border: "none",
    color: black_color
  },
  modalTitle: {
    color: pp_blue_color
  }
}));

export { ModalStyles };
