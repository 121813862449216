import { createMuiTheme } from '@material-ui/core/styles';

const CampaignFormOverrideStyles = createMuiTheme({
  palette: {
    primary: {
      main: '#6cf',
      
    },
    secondary: {
      main: '#fff',
      
    }
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: '0.75rem !important',
      },
    },
  },
});

export default CampaignFormOverrideStyles;
