import {makeStyles} from '@material-ui/core/styles';
import {black_color, pp_blue_color} from '../../assets/styles/types'

const HeaderStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    popOver: {
        marginTop: '40px'
    },
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: black_color,
        width: '100%',
        alignItems: 'center'
    },
    userNameLabel:{
        fontSize: '0.5rem'
    },
    accountIcon: {
        '&:hover': {
            color: pp_blue_color
        },
        marginRight: '6px'
    },
    iconButton: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
        fontSize: '1.2rem',
        textTransform: 'capitalize'
    },
    popOverButton:{
        width: '-webkit-fill-available'
    },
    userRole: {
        backgroundColor: '#2061E2',
        color: '#fff',
        textTransform: 'capitalize'
    }
}));

export default HeaderStyles;