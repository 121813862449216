/**
 * Class Name: PageSnackBar
 * Description: Page Alert
 * Param: open, anchorOrigin, autoHideDuration, onClose, snackBarConfig = []
 * Return: Pagination Component
 * Author: Raymart
 * Last Update: Raymart
 */

import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const PageSnackBar = ({open, anchorOrigin, autoHideDuration, onClose, snackBarConfig}) => {
    const { status, message } = snackBarConfig;
    return (
        <Snackbar open={open} anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} onClose={onClose}>
            <Alert id='msg-alert' onClose={onClose} variant="filled" severity={status}>
                { message }
            </Alert>
        </Snackbar>
    )
}

export {PageSnackBar};
