import {
  CAMPAIGNS_READ_SUCCESS,
  CAMPAIGNS_CREATE_SUCCESS,
  CAMPAIGN_ERROR,
  CAMPAIGN_VIEW_SUCCESS,
  CAMPAIGN_CREATE_VALIDATE_SUCCESS,
  CAMPAIGN_UPDATE,
  CAMPAIGN_BILLING,
  SYSTEM_ERROR
} from '../actions/types';

const INITIAL_STATE = {
  campaignInfo: {
    campaign_id: '',
    campaign_name: '',
    completed_timestamp: '',
    created_by: '',
    created_timestamp: '',
    organization_id: '',
    progress: {
      all_count: '',
      completed_count: '',
    },
    status: '',
    messages: [],
  },
  campaigns: [],
  total_count: 0,
  current_page: 1,
  page_count: 1,
  view_start: 1,
  view_end: 1,
  error: '',
  fileValidation: false,
  expected_cost: '',
  system_error: false,
  error_url: ''
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CAMPAIGN_UPDATE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case CAMPAIGNS_READ_SUCCESS:
      return {
        ...state,
        campaigns: action.payload.campaigns_list,
        total_count: action.payload.total_count,
        current_page: action.payload.current_page,
        page_count: action.payload.page_count,
        view_start: action.payload.view_start,
        view_end: action.payload.view_end,
        error: "",
        system_error: false,
      };
    case CAMPAIGNS_CREATE_SUCCESS:
      return {
        ...state,
        campaigns: [
          {
            ...state.campaigns,
            ...action.payload,
          },
        ],
        error: "",
        system_error: false,
        error_url: ''
      };
    case CAMPAIGN_VIEW_SUCCESS:
      return {
        ...state,
        campaignInfo: action.payload,
        expected_cost: action.payload.expected_cost,
      };
    case CAMPAIGN_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        system_error: false,
        error_url: action.payload.error_url,
        error_message: action.payload.error_message
      };
    case CAMPAIGN_CREATE_VALIDATE_SUCCESS:
      return {
        ...state,
        fileValidation: true,
        status: '',
        error_url:'',
        campaignInfo: action.payload,
      };
    case CAMPAIGN_BILLING:
      return {
        ...state,
        expected_cost: action.payload.expected_cost,
      };
    case SYSTEM_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        system_error: true
      };
    default:
      return state;
  }
}
