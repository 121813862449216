import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {SgmStyles} from "../../assets/styles";
import {VIEW_ORGANIZATIONS} from "../../helpers";
import PropTypes from 'prop-types';

const SgmSubHeader = ({
                          title,
                          organizationSelect,
                          permissions
                      }) => {
    const classes = SgmStyles();
    return (
        <div>
            <Typography variant='h4' id='sgmsubheader-title'>{title}</Typography>
            {
                permissions.includes(VIEW_ORGANIZATIONS) ?
                    <Grid container item className={classes.select}>
                        { organizationSelect }
                    </Grid> : null
            }
        </div>
    );
};

SgmSubHeader.propTypes = {
    title: PropTypes.string,
    organizationSelect: PropTypes.any
};

export {SgmSubHeader};