/**
 * Style Name: OrganizationSuggestStyles
 * Description: Styles for Dashboard Layout
 * Param: void
 * Return: class styles
 * Author: Frances
 * Last Update By: RJ
 */
import { makeStyles } from '@material-ui/core/styles';

const OrganizationSuggestStyles = makeStyles(() => ({
  suggestions: {
    position: 'absolute',
    margin: 0,
    padding: 0,
    zIndex: 1,
    width: '14rem',
  },
  menuItem: {
    paddingRight: '0.6rem',
  },
  selectDropdown: {
    width: '17rem',
  },
  custom: { textTransform: 'capitalize' },
}));

export { OrganizationSuggestStyles };
