/**
 * Class Name: UserModal
 * Description: UserModal component.
 * Param: N/A
 * Return: UserModal Component
 * Author: Shirwyn
 * Last Update: Jeremiah
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { UserModalStyles } from './UserModalStyles';
import { Loader } from '../Loader';

const UserModal = ({
  isOpen,
  activePage,
  closeModal,
  selectedItem,
  deleteItem,
  isLoadingModal,
  loaderColor,
}) => {
  const classes = UserModalStyles();
  return (
    <Dialog open={isOpen} maxWidth='xs' id='user-delete-modal'>
      <Grid container direction='row' className={classes.container}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <DialogTitle className={classes.dialogTitle}>
            Delete {activePage}
          </DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            className={classes.closeButton}
            onClick={closeModal}
            id='delete-modal-close-button'
            disabled={isLoadingModal}
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Typography
        variant='body1'
        className={classes.content}
      >
        Are you sure you want to delete {selectedItem}? This action cannot be
        undone.
      </Typography>
      <Grid container direction='row' className={classes.buttonContainer}>
        <Grid item xs>
          <Button
            variant='contained'
            color='primary'
            onClick={deleteItem}
            className={classes.deleteButton}
            id='delete-modal-confirm-button'
            disabled={isLoadingModal}
          >
            {isLoadingModal ? (
              <div className={classes.loader}>
                <Loader
                  size={70}
                  adjustSmall={window.screen.width <= 520 ? true : false}
                  color={loaderColor}
                />
              </div>
            ) : (
              'Yes, Delete ' + activePage
            )}
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            onClick={closeModal}
            className={classes.cancelButton}
            id='delete-modal-cancel-button'
            disabled={isLoadingModal}
          >
            Cancel, Keep {activePage}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
//Define proptypes of UserModal
UserModal.propTypes = {
  isOpen: PropTypes.bool,
  activePage: PropTypes.string,
  closeModal: PropTypes.func,
  selectedItem: PropTypes.string,
  deleteItem: PropTypes.func,
};

export { UserModal };
