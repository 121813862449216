import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Route } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  Button,
  Paper,
  FormHelperText,
  Link,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { FormInput, LoginPageStyles } from '../../assets/styles';
import {
  SessionModal,
  ForceChangePassword,
  Loader,
  ForgotPassword,
  ForgotPasswordCode,
  ChangePassModal,
} from '../../components';
import { pp_blue_color_loader } from '../../assets/styles/types';

class LoginPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      isLoginButtonPressed: false,
      onError: false,
      isVisible: true,
      auth: false,
      regexp: true,
      isLoading: true,
      loaderColor: pp_blue_color_loader,
      isChangingPassword: false,
      toForgotPassword: false,
      isEmailSubmitted: false,
      errorEmail: false,
      isModalVisible: false,
      errorEmailMessage: '',
      emailDuplicatedValue: '',
    };
    this.handleClickedChangePassword = this.handleClickedChangePassword.bind(
      this
    );
    this.handleRegExp = this.handleRegExp.bind(this);
    this.handlePress = this.handlePress.bind(this);
    this.handlePressChangePass = this.handlePressChangePass.bind(this);
    this.renderForgotPassword = this.renderForgotPassword.bind(this);
    this.renderPassChange = this.renderPassChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.authStatus === 'expired') {
      if (this._isMounted) {
        this.setState({
          isVisible: true,
        });
      }
    } else if (this.props.authStatus === 'change_password_success') {
      if (this._isMounted) {
        this.setState({
          isModalVisible: true,
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          isVisible: false,
          isModalVisible: false,
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Function Name: handleRegExp
   * Description: handler for the characters of change password
   * Param: string
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  handleRegExp(newPass, confirmPass) {
    let strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\\w\\s])(?=.{6,98})'
    );
    let isCorrectFormat = strongRegex.test(confirmPass);

    if (!isCorrectFormat) {
      if (this._isMounted) {
        this.setState({
          regexp: false,
          auth: false,
        });
      }
    } else if (newPass !== confirmPass) {
      if (this._isMounted) {
        this.setState({
          auth: false,
        });
      }
    } else {
      if (this._isMounted) {
        this.setState(
          {
            auth: true,
            regexp: true,
          },
          () => {
            this.props.forceChangePass();
          }
        );
      }
    }
  }

  /**
   * Function Name: handlePress
   * Description: handler for pressing input field
   * Param: void
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  handlePress() {
    if (this._isMounted) {
      this.setState({
        isLoginButtonPressed: false,
      });
    }
  }

  /**
   * Function Name: handleLoginSubmit
   * Description: handler for pressing enter key or login button
   * Param: void
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  handleLoginSubmit = (event) => {
    event.preventDefault();
    const { email, password, onLogin } = this.props;

    if (
      password === '' ||
      email === '' ||
      password === null ||
      email === null
    ) {
      if (this._isMounted) {
        this.setState({
          isLoginButtonPressed: true,
          isLoading: false,
        });
      }
    } else {
      if (this._isMounted) {
        this.setState(
          {
            isLoginButtonPressed: true,
            isLoading: true,
          },
          () => {
            onLogin();
          }
        );
      }
    }
  };

  /**
   * Function Name: handlePressChangePass
   * Description: handler for pressing change password button
   * Param: void
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  handlePressChangePass() {
    if (this._isMounted) {
      this.setState({
        isChangingPassword: false,
      });
    }
  }

  /**
   * Function Name: handleClickedChangePassword
   * Description: handler for pressing password field
   * Param: void
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  handleClickedChangePassword(event) {
    event.preventDefault();
    const { newPass, confirmPass } = this.props;

    if (this._isMounted) {
      this.setState({
        isChangingPassword: true,
        regexp: true,
      });
    }
    this.handleRegExp(newPass, confirmPass);
  }

  /**
   * Function Name: renderForgotPassword
   * Description: Function prop of forgot password link on App.js
   * Param: void
   * Return: void
   * Author: Gian
   * Last Update By: Gian
   */
  renderForgotPassword() {
    const { onForgotPassword } = this.props;
    onForgotPassword();
  }

  /**
   * Function Name: renderPassChange
   * Description: Function prop of submit code button on App.js
   * Param: void
   * Return: void
   * Author: Gian
   * Last Update By: Gian
   */
  renderPassChange(event) {
    event.preventDefault();
    const { onPassChange } = this.props;
    onPassChange();

    if (this._isMounted) {
      this.setState({
        isModalVisible: true,
      });
    }
  }

  render() {
    const {
      classes,
      email,
      password,
      authStatus,
      error,
      newPass,
      confirmPass,
      ChallengeName,
      IsForgotPassword,
      onEmailChange,
      onPasswordChange,
      onForceChangePassword,
      type,
      isEmailSubmitted,
      onChangeCodeInput,
      isCodeSubmitted,
      new_password,
      onChangeNewPass,
      confirm_new_password,
      onChangeConfirmNewPass,
      code,
      loaderForButton,
      isPassCodeInvalid,
      codeError,
      isNewPassInvalid,
      newPassError,
      isConfirmNewPassInvalid,
      confirmNewPassError,
      loaderForChangePass,
      errorEmailMessage,
    } = this.props;
    if (
      (!ChallengeName && !IsForgotPassword && authStatus !== 'authorized') ||
      (IsForgotPassword && authStatus === 'forgot_password_changed') ||
      authStatus === ''
    ) {
      return (
        <div className={classes.root}>
          <Paper className={classes.formContainer} id="login-page-container">
            <img
              id="img-Logo"
              src={require("../../assets/sms-full.png")}
              alt="pearlpay"
              className={classes.image}
            />
            <form
              id="form-container"
              onSubmit={this.handleLoginSubmit}
              onKeyDown={this.handlePress}
              className={classes.form}
            >
              <div>
                <FormControl
                  className={classes.margin}
                  id="email-input-container"
                >
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.inputLabel}
                  >
                    Email
                  </InputLabel>
                  <FormInput
                    id="bootstrap-email-input"
                    type="text"
                    value={this.state.email}
                    onChange={onEmailChange}
                    onClick={this.handlePress}
                  />
                </FormControl>
                {(email === null || email === "") &&
                this.state.isLoginButtonPressed === true ? (
                  <FormHelperText
                    id="email-null-error"
                    error
                    className={classes.errorMessage}
                  >
                    Email Address is required
                  </FormHelperText>
                ) : null}
              </div>

              <div>
                <FormControl
                  className={classes.margin}
                  id="password-input-container"
                >
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.inputLabel}
                  >
                    Password
                  </InputLabel>
                  <FormInput
                    id="bootstrap-password-input"
                    type="password"
                    value={this.state.password}
                    onChange={onPasswordChange}
                    onClick={this.handlePress}
                  />
                </FormControl>
                {(password === null || password === "") &&
                this.state.isLoginButtonPressed === true ? (
                  <FormHelperText
                    id="password-null-error"
                    error
                    className={classes.errorMessage}
                  >
                    Password is required
                  </FormHelperText>
                ) : null}
              </div>

              {authStatus === "unauthorized" &&
              (password && email) !== "" &&
              error === "Invalid Credentials" ? (
                <FormHelperText
                  id="invalid-info"
                  error
                  className={classes.errorMessage}
                >
                  Invalid email/password
                </FormHelperText>
              ) : null}

              {authStatus === "unauthorized" &&
              (password && email) !== "" &&
              error === "login failed" ? (
                <FormHelperText
                  id="login-failed"
                  error
                  className={classes.errorMessage}
                >
                  Login failed. Please try again.
                </FormHelperText>
              ) : null}

              {authStatus === "unauthorized" &&
              (password && email) !== "" &&
              error === "Invalid email address" ? (
                <FormHelperText
                  id="inavild-email"
                  error
                  className={classes.errorMessage}
                >
                  Please use email address.
                </FormHelperText>
              ) : null}
              {authStatus === "unauthorized" &&
              (password && email) !== "" &&
              error === "inactive organization" ? (
                <FormHelperText
                  id="inactive-org"
                  error
                  className={classes.errorMessage}
                >
                  Your Organization is currently inactive.
                  <br />
                  Please contact administrator.
                </FormHelperText>
              ) : null}
              {authStatus === "maximum_attempt" ? (
                <FormHelperText
                  id="max-attempt"
                  error
                  className={classes.errorMessage}
                >
                  Maximum attempts has already been reached
                </FormHelperText>
              ) : null}
              <Route
                id="route"
                render={({ history }) => (
                  <Button
                    id="submit-btn"
                    variant="contained"
                    type="submit"
                    size="large"
                    color="primary"
                    className={classes.loginButton}
                    onClick={this.handleLoginSubmit}
                    disabled={
                      this.state.isLoginButtonPressed === true &&
                      this.state.isLoading &&
                      authStatus !== "unauthorized"
                    }
                  >
                    {this.state.isLoginButtonPressed === true &&
                    this.state.isLoading &&
                    authStatus !== "unauthorized" ? (
                      <div className={classes.loader}>
                        <Loader
                          size={80}
                          adjustSmall={window.screen.width <= 520}
                          color={this.state.loaderColor}
                        />
                      </div>
                    ) : (
                      type
                    )}
                    {this.state.isLoginButtonPressed === true &&
                    !this.state.isLoading &&
                    authStatus === "authorized"
                      ? type
                      : null}
                  </Button>
                )}
              />
              <p>
                Forgot Password?
                <Link
                  className={classes.forgotPasslink}
                  id="forgot-password-link"
                  onClick={this.renderForgotPassword}
                >
                  Click Here
                </Link>
              </p>
            </form>
          </Paper>
          <div className={classes.modalContainer}>
            {authStatus === "expired" ? (
              <SessionModal
                isVisible={this.state.isVisible}
                handleClose={() =>
                  this.setState({
                    isVisible: false,
                  })
                }
              />
            ) : (
              <ChangePassModal
                isModalVisible={this.state.isModalVisible}
                handleClose={() =>
                  this.setState({
                    isModalVisible: false,
                  })
                }
              />
            )}
          </div>
        </div>
      );
    } else if (ChallengeName === 'NEW_PASSWORD_REQUIRED') {
      return (
        <div className={classes.root}>
          <ForceChangePassword
            type={'Change Password'}
            loaderColor={this.state.loaderColor}
            newPass={newPass}
            confirmPass={confirmPass}
            isSubmitPassword={this.state.isChangingPassword}
            onSubmitChangePasswordInput={this.handlePressChangePass}
            onSubmitChangePassword={this.handleClickedChangePassword}
            onChangePasswordInput={onForceChangePassword}
            regExpValidator={this.state.regexp}
            isAuthenticating={this.state.auth}
            hasError={error}
          />
        </div>
      );
    } else if (IsForgotPassword) {
      if (
        authStatus === 'forgot_password_maximum_attempt' ||
        authStatus === 'forgot_password_change' ||
        authStatus === 'forgot_password_invalid_email'
      ) {
        return (
          <div className={classes.root}>
            <ForgotPassword
              email={email}
              isEmailSubmitted={isEmailSubmitted}
              onSubmitEmail={this.props.renderEnterCode}
              onChangeEmailInput={this.props.handleEmailChange}
              loaderForButton={loaderForButton}
              buttonLabel={'Send Code'}
              errorEmailMessage={errorEmailMessage}
            />
          </div>
        );
      } else if (authStatus === 'forgot_password_verified') {
        return (
          <div className={classes.root}>
            <ForgotPasswordCode
              id="password-verified-component"
              code={code}
              onChangeCodeInput={onChangeCodeInput}
              new_password={new_password}
              onChangeNewPass={onChangeNewPass}
              confirm_new_password={confirm_new_password}
              onChangeConfirmNewPass={onChangeConfirmNewPass}
              isCodeSubmitted={isCodeSubmitted}
              isPassCodeInvalid={isPassCodeInvalid}
              codeError={codeError}
              isNewPassInvalid={isNewPassInvalid}
              newPassError={newPassError}
              isConfirmNewPassInvalid={isConfirmNewPassInvalid}
              confirmNewPassError={confirmNewPassError}
              loaderForChangePass={loaderForChangePass}
              onSubmitCode={(event) => this.renderPassChange(event)}
            />
          </div>
        );
      } else if (
        this.props.error === 'forgot_password_something_went_wrong' &&
        this.props.authStatus !== 'forgot_password_not_verified'
      ) {
        return (
          <div className={classes.root}>
            <ForgotPasswordCode
              id="something-went-wrong-component"
              code={code}
              onChangeCodeInput={onChangeCodeInput}
              new_password={new_password}
              onChangeNewPass={onChangeNewPass}
              confirm_new_password={confirm_new_password}
              onChangeConfirmNewPass={onChangeConfirmNewPass}
              isCodeSubmitted={isCodeSubmitted}
              isPassCodeInvalid={isPassCodeInvalid}
              codeError={codeError}
              isNewPassInvalid={isNewPassInvalid}
              newPassError={newPassError}
              isConfirmNewPassInvalid={isConfirmNewPassInvalid}
              confirmNewPassError={confirmNewPassError}
              loaderForChangePass={loaderForChangePass}
              errorHandler={
                <FormHelperText id="went-wrong-error" error className={classes.errorMessage}>
                  Something went wrong, please try again
                </FormHelperText>
              }
              onSubmitCode={(event) => this.renderPassChange(event)}
            />
          </div>
        );
      } else if (
        authStatus === 'forgot_password_invalid_code' ||
        authStatus === 'forgot_password_expired_code' ||
        authStatus === 'forgot_password_not_verified'
      ) {
        return (
          <div className={classes.root}>
            <ForgotPasswordCode
              id="not-verified-component"
              code={code}
              onChangeCodeInput={onChangeCodeInput}
              new_password={new_password}
              onChangeNewPass={onChangeNewPass}
              confirm_new_password={confirm_new_password}
              onChangeConfirmNewPass={onChangeConfirmNewPass}
              isCodeSubmitted={isCodeSubmitted}
              isPassCodeInvalid={isPassCodeInvalid}
              codeError={codeError}
              isNewPassInvalid={isNewPassInvalid}
              newPassError={newPassError}
              loaderForChangePass={loaderForChangePass}
              isConfirmNewPassInvalid={isConfirmNewPassInvalid}
              confirmNewPassError={confirmNewPassError}
              onSubmitCode={(event) => this.renderPassChange(event)}
            />
          </div>
        );
      } else if (this.props.error === 'forgot_password_user_does_not_exist') {
        return (
          <div className={classes.root}>
            <ForgotPasswordCode
              id="user-does-not-exist"
              code={code}
              onChangeCodeInput={onChangeCodeInput}
              new_password={new_password}
              onChangeNewPass={onChangeNewPass}
              confirm_new_password={confirm_new_password}
              onChangeConfirmNewPass={onChangeConfirmNewPass}
              isCodeSubmitted={isCodeSubmitted}
              isPassCodeInvalid={isPassCodeInvalid}
              codeError={codeError}
              isNewPassInvalid={isNewPassInvalid}
              newPassError={newPassError}
              loaderForChangePass={loaderForChangePass}
              isConfirmNewPassInvalid={isConfirmNewPassInvalid}
              confirmNewPassError={confirmNewPassError}
              errorHandler={
                <FormHelperText id="user-not-found-error" error className={classes.errorMessage}>
                  User does not exist
                </FormHelperText>
              }
              onSubmitCode={(event) => this.renderPassChange(event)}
            />
          </div>
        );
      }
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({ auth }) => {
  const { authStatus, error } = auth;

  return {
    authStatus,
    error,
  };
};

/**
 * Function Name: compose
 * Description: Enables connect and withStyles to work at the same time
 * Return: Container with style attributes and connection
 * Author: Toni
 * Last Update: Toni
 */

export default compose(
  withStyles(LoginPageStyles),
  connect(mapStateToProps, {})
)(LoginPage);
