/**
 * Component Name: UserView
 * Description: Renders UserView component
 * Param: void
 * Return: UserView component
 * Author: Shirwyn
 * Last Update By: Everard
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, FormHelperText } from '@material-ui/core';
import { convertTimestamp } from '../../helpers';
import { UserViewStyles } from './UserViewStyles';

const UserView = ({
  email,
  created_by,
  date_created,
  first_name,
  last_name,
  middle_name,
  role,
  active,
  error,
}) => {
  const classes = UserViewStyles();
  return (
    <Grid container direction='column' className={classes.container}>
      {error !== '' ? <FormHelperText error>{error}</FormHelperText> : null}
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='first-name-label'
        >
          First Name
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='first-name-data'
        >
          {first_name}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='last-name-label'
        >
          Last Name
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='last-name-data'
        >
          {last_name}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='middle_name-label'
        >
          Middle Name
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='middle_name-data'
        >
          {middle_name}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='email-label'
        >
          Email
        </Typography>
        <Typography variant='body1' className={classes.label} id='email-data'>
          {email}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='role-label'
        >
          Role
        </Typography>
        <Typography variant='body1' className={classes.label} id='role-data'>
          {role}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='createdby-label'
        >
          Created By
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='createdby-data'
        >
          {created_by}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='date-created-label'
        >
          Date Created
        </Typography>
        <Typography
          variant='body1'
          className={classes.label}
          id='date-created-data'
        >
          {convertTimestamp(date_created)}
        </Typography>
      </Grid>
      <Grid item xs className={classes.items}>
        <Typography
          variant='body1'
          className={`${classes.label} ${classes.labelTitle}`}
          id='active-label'
        >
          Status
        </Typography>
        <Typography
          variant='body1'
          className={active === true ? classes.active : classes.inactive}
          id='active-data'
        >
          {active ? 'Active' : 'Inactive'}
        </Typography>
      </Grid>
    </Grid>
  );
};
//Define UserView proptypes
UserView.propTypes = {
  email: PropTypes.string,
  created_by: PropTypes.string,
  date_created: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  middle_name: PropTypes.string,
  role: PropTypes.array,
  active: PropTypes.bool,
  error: PropTypes.string,
};

export { UserView };
