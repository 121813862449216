import { makeStyles } from '@material-ui/core/styles';

const BillComponentStyles = makeStyles( theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
      },
      titleFont: {
        fontSize: '15px',
        fontWeight: 'bold',
        color: '#2c2c2c',
        opacity: '0.5'
      },
      contentSize: {
        fontSize: window.screen.width <= 720 ? '25px': '55px',
        fontWeight: 'bold',
        textAlign: 'end'
      },
      contentFont: {
        fontSize: '15px',
        fontWeight: 'bold'
      },
      dataContainer: {
        justifyContent : 'space-between',
        margin: '8px 0 8px 0'
      }
  }));

export { BillComponentStyles };