/**
 * Style Name: ChangePasswordSideDrawerStyles
 * Description: Styles for change password form Component
 * Param: void
 * Return: class styles
 * Author: Jeremiah
 * Last Update By: Jeremiah
 */
import {makeStyles} from '@material-ui/core/styles';
import {pp_blue_color, black_color, white_color, dark_blue_color} from '../../assets/styles/types';

const ChangePasswordSideDrawerStyles = makeStyles(() => ({
    backArrow: {
        fontSize: '24px',
        color: black_color,
        opacity: '0.5'
    },
    sideDrawer: {
        width: window.screen.width <= 720 ? '100vw' : '30vw',
        overflowX: 'hidden'
    },
    backButton: {
        fontSize: '14px ',
        color: '#2c2c2c ',
        opacity: '0.5',
        textTransform: 'capitalize',
        margin: '1rem 1.5rem 0.25rem',
        padding: '0.25rem',
        '&:hover': {
            backgroundColor: white_color
        }
    },
    formContainer: {
        justifyContent: "center",
        alignItems: "center",
        padding: "40px",
        borderRadius: "10px",
        maxWidth: "350px",
        textAlign: "center",
        fontSize: "14px",
        margin: 'auto'
    },
    campaignFormHeader: {
        margin: '0 1rem 0 2rem !important'
    },
    title: {
        display: 'flex'
    },
    textField: {
        marginTop: 'auto'
    },
    changePasswordTitle: {
        fontSize: '25px',
        fontWeight: 'bold'
    },
    errorMessage: {
        margin: 0
    },
    autoLogoutMessage: {
        textAlign: "center",
        fontSize: '17px',
        color: dark_blue_color
    },
    divider: {
        margin: '1.5rem 0'
    },
    gridItem: {
        flex: window.screen.width <= 720 ? 'auto' : ''
    },
    gridFormItem: {
        display: 'inline-block',
        marginTop: '16px'
    },
    errorMessagesGrid: {
        display: 'inline-block',
    },
    form: {
        maxWidth: '262px',
        margin: 'auto'
    },
    container: {
        width: window.screen.width <= 720 ? '100vw' : '30vw',
        '@media (max-width: 1024px)': {
            width: window.screen.width <= 720 ? '100vw' : '45vw'
        },
        overflowX: 'hidden',
        height: '100%'
    },
    changePasswordButton: {
        width: "220px",
        height: "50px",
        marginTop: "20px",
        backgroundColor: pp_blue_color,
        color: white_color,
        fontWeight: "bold",
        fontSize: "18px",
        textTransform: "capitalize",
        '&:hover': {
            backgroundColor: pp_blue_color
        }
    },
    loader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)"
    },
    inputLabel2: {
        marginTop: "1rem",
        textAlign: "left",
        fontWeight: "bold",
        marginLeft: "2rem",
        fontSize: "1rem",
        lineHeight: "1.2",
        maxHeight: "1.5rem",
        width: "100%"
    },
    listItem: {
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    guideLabel: {
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "17rem",
        textAlign: "left",
        fontSize: ".75rem",
        fontFamily: '"Avenir", sans-serif',
        marginLeft: "1rem",
        color: "rgba(0, 0, 0, 0.54)"
    }
}));

export {ChangePasswordSideDrawerStyles};