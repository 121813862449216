/**
 * Class Name: UserSubheader
 * Description: UserSubheader component.
 * Param: N/A
 * Return: UserSubheader Component
 * Author: Shirwyn
 * Last Update: Nelly
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { UserSubheaderStyles } from './UserSubheaderStyles';
import { CREATE_USERS } from '../../helpers';

const UserSubheader = ({
  title,
  btn,
  onCreate,
  suggestComponent,
  permissions,
  orgStatus,
  disabled
}) => {
  const classes = UserSubheaderStyles();
  const userCreateBtn = () => {
    if (permissions.includes(CREATE_USERS)) {
        return (
          <Button
            id='user-subheader-create-button'
            disabled={orgStatus === "true" ? !disabled : disabled}
            onClick={onCreate}
            className={orgStatus === "true" ? classes.button : classes.inActiveOrg}
          >
            {btn}
          </Button>
        );
    } else {
      return null;
    }
  };

  return (
    <div id='user-subheader' className={classes.subHeader}>
      <Typography variant='h4'>{title}</Typography>
      <div>
        {suggestComponent}
        {userCreateBtn()}
      </div>
    </div>
  );
};

UserSubheader.propTypes = {
  title: PropTypes.string,
  btn: PropTypes.string,
  onCreate: PropTypes.func,
  suggestComponent: PropTypes.any,
};

export { UserSubheader };
