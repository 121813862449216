/**
 * Component Name: Header
 * Description: Renders Header component
 * Param: username, onLogout
 * Return: Component
 * Author: Jeremiah
 * Last Update By: RJ
 */

import React from 'react';
import { Route } from 'react-router-dom';
import { MenuItem, Button, Popover, Typography, Chip } from '@material-ui/core';
import { AccountCircle, ArrowDropDown } from '@material-ui/icons';
import LoginPage from '../../containers/LoginPage';
import HeaderStyles from './HeaderStyles';

const Header = ({
  username,
  currentUserRole,
  onLogout,
  isViewSideDrawer,
  isViewDropDown,
  isCloseDropDown,
  viewDropDown,
}) => {
  const classes = HeaderStyles();

  return (
    <div className={classes.container}>
      <nav>
        <Chip
          label={currentUserRole?.replace(/_/g, ' ')}
          className={classes.userRole}
        />
        <Button
          onClick={isViewDropDown}
          className={classes.iconButton}
          id='icon-button'
        >
          <AccountCircle className={classes.accountIcon} />
          <div>Hi, {username}!</div>
          <ArrowDropDown />
        </Button>
        <Popover
          id='simple-menu'
          keepMounted
          open={Boolean(viewDropDown)}
          onClose={isCloseDropDown}
          anchorEl={viewDropDown}
          className={classes.popOver}
        >
          <MenuItem className={classes.typography} onClick={isViewSideDrawer}>
            <Typography className={classes.popOverButton}>
              Change Password
            </Typography>
          </MenuItem>
          <MenuItem className={classes.typography} onClick={onLogout}>
            <Typography className={classes.popOverButton}>Log Out</Typography>
          </MenuItem>
        </Popover>
      </nav>
      <Route exact path='/login' component={LoginPage} />
    </div>
  );
};

export { Header };
