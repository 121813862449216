import axios from 'axios';
import {
  INVOICE_READ_SUCCESS,
  INVOICE_PRICE,
  INVOICE_UNBILLED_TOTAL,
  INVOICE_SERVER_ADDRESS,
  INVOICE_READ_LIST_SUCCESS,
  VIEW_INVOICE_SUCCESS,
  GET_OUTSTANDING_BALANCE_SUCCESS,
  GET_OUTSTANDING_BALANCE_ERROR,
  INVOICE_READ_LIST_ERROR,
} from './types';
import { getHeaders } from './AuthActions';
import { errorHandler } from '../helpers';

const ROOT_URL = INVOICE_SERVER_ADDRESS;

/**
 * Action Name: readInvoices
 * Description:
 *      Retrieves an arrray of invoices from a specific organization id
 * Param: organization id, token
 * Return: Array of invoices
 * Author: Gian
 * Last Update By: Raymart
 */
export const readInvoices = (organization_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${organization_id}/invoices/latest`,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: INVOICE_READ_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Invoice', e, 'READ_INVOICES'));
  }
};

/**
 * Action Name: readInvoiceList
 * Description:
 *      Retrieves the all list of invoices
 * Param: orgId, limit
 * Return: invoice list
 * Author: Jeremiah
 * Last Update By: RJ
 */

export const readInvoiceList = (orgId, limit) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${orgId}/invoices?limit=${limit}`,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: INVOICE_READ_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    if (e.response?.status === 500) {
      dispatch({
        type: INVOICE_READ_LIST_ERROR,
        payload: {
          status: e.response.status,
          error: "Can't retrieve all invoice",
        },
      });
    } else {
      dispatch(errorHandler('Invoice', e, 'READ_INVOICE_LIST'));
    }
  }
};

/**
 * Action Name: readLimitlessInvoice
 * Description: Retrieves the all list of invoices
 * Param: orgId, limit
 * Return: limitless invoice list
 * Author: Jeremiah
 * Last Update By: RJ
 */
export const readLimitlessInvoice = (orgId) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${orgId}/invoices`,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: INVOICE_READ_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    if (e.response.status === 500) {
      dispatch({
        type: INVOICE_READ_LIST_ERROR,
        payload: {
          status: e.response.status,
          error: "Can't retrieve all invoice",
        },
      });
    } else {
      dispatch(errorHandler('Invoice', e, 'READ_LIMIT_LESS_INVOICE'));
    }
  }
};

/**
 * Action Name: invoicePrice
 * Description:
 *      Retrieves the total price of an uploaded campaign of a specific organization
 * Param: organization id, payload
 * Return: total price
 * Author: Gian
 * Last Update By: Raymart
 */
export const invoicePrice = (payload, organization_id) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/organizations/${organization_id}/invoices/expected-cost`,
      payload,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: INVOICE_PRICE,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Invoice', e, 'COMPUTE_INVOICE_PRICE'));
  }
};

/**
 * Action Name: invoiceUnbilled
 * Description:
 *      Retrieves a total of unbilled invoices of a specific campaign
 * Param: organization id, token
 * Return: total unbilled invoice of a specific campaign
 * Author: Gian
 * Last Update By: Raymart
 */
export const invoiceUnbilled = (organization_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${organization_id}/invoices/unbilled-total`,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: INVOICE_UNBILLED_TOTAL,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Invoice', e, 'COMPUTE_INVOICE_UNBILLED'));
  }
};

/**
 * Action Name: viewInvoice
 * Description: Returns the url for viewing the invoice.
 * Param: organization_id, invoice_id
 * Return: url
 * Author: Everard
 * Last Update By: Raymart
 */
export const viewInvoice =
  (organization_id, invoice_id) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${ROOT_URL}/organizations/${organization_id}/invoices/${invoice_id}/url`,
        await getHeaders()
      );
      if (response.status === 200) {
        dispatch({
          type: VIEW_INVOICE_SUCCESS,
          payload: response.data,
        });
      }
    } catch (e) {
      dispatch(errorHandler('Invoice', e, 'VIEW_INVOICE'));
    }
  };

/**
 * Action Name: getOutstandingBalance
 * Description: Retreive the outstanding balance from xero.
 * Param: organization_id
 * Return: url
 * Author: Everard
 * Last Update By: RJ
 */
export const getOutstandingBalance = (organization_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${organization_id}/outstanding-balance`,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: GET_OUTSTANDING_BALANCE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    if (e.response?.status === 500) {
      dispatch({
        type: GET_OUTSTANDING_BALANCE_ERROR,
        payload: {
          error: "Can't retrieve outstanding balance",
          status: e.response.status,
        },
      });
    } else {
      dispatch(errorHandler('Invoice', e, 'GET_OUTSTANDING_BALANCE'));
    }
  }
};
