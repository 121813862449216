import {
    USER_READ_SUCCESS,
    USER_VIEW_SUCCESS,
    USER_CREATE_SUCCESS,
    USER_UPDATE_SUCCESS,
    USER_DELETE_SUCCESS,
    USER_DELETE_ERROR,
    USER_ERROR, USER_NOT_FOUND,
    SYSTEM_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    userInfo: {
        user_id: '',
        email: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        organization_id: '',
        roles: [],
        created_by: '',
        created_timestamp: '',
        active: ''
    },
    users: [],
    total_count: 0,
    current_page: 1,
    page_count: 1,
    view_start: 1,
    view_end: 1,
    error: '',
    status: '',
    error_code: '',
    noUserFound: false,
    system_error: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USER_READ_SUCCESS:
            return {
                ...state,
                users: action.payload.users_list,
                total_count: action.payload.total_count,
                current_page: action.payload.current_page,
                page_count: action.payload.page_count,
                view_start: action.payload.view_start,
                view_end: action.payload.view_end,
                noUserFound: false,
                error: '',
                system_error: false
            };
        case USER_VIEW_SUCCESS:
            return {
                ...state,
                userInfo: action.payload
            };
        case USER_CREATE_SUCCESS:
            return {
                ...state,
                users: [{
                    ...state.users,
                    ...action.payload
                }],
                error: '',
                system_error: false,
                status: '',
            };
        case USER_UPDATE_SUCCESS:
            return {
                ...state,
                userInfo: action.payload,
                users: state.users.map(user =>
                    user.user_id === action.payload.user_id ? action.payload : user
                ),
                error: '',
                system_error: false
            };
        case USER_DELETE_SUCCESS:
            return {
                ...state,
                users: state.users.filter(
                    user => user.user_id !== action.payload
                ),
                error: '',
                system_error: false,
                status: ''
            };
        case USER_DELETE_ERROR:
        case USER_ERROR:
            return {
                ...state,
                status: action.payload.status,
                error: action.payload.error,
                error_code: action.payload.error_code
            };
        case USER_NOT_FOUND:
            return {
                noUserFound: true
            };
        case SYSTEM_ERROR:
            return {
                ...state,
                error: action.payload.error,
                status: action.payload.status,
                system_error: true
            };
        default:
            return state;
    }
}