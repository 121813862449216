/**
 * Style Name: UserSubheaderStyles
 * Description: Styles for User Subheader Component
 * Param: void
 * Return: class styles
 * Author: Shirwyn
 * Last Update By: Shirwyn
 */
import { makeStyles } from '@material-ui/core/styles';
import { pp_blue_color } from '../../assets/styles/types';

const UserSubheaderStyles = makeStyles(() => ({
  button: {
    backgroundColor: pp_blue_color,
    borderColor: pp_blue_color,
    color: '#fff',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: pp_blue_color,
      borderColor: pp_blue_color,
    },
    margin: '1rem 0 0',
  },
  subHeader: {
    width: '100%',
  },
  inActiveOrg: {
    color: '#fff !important',
    textTransform: 'capitalize',
    backgroundColor: '#c9c9c9',
    cursor: 'default',
    margin: '1rem 0 0',
    '&:hover': {
      backgroundColor: '#c9c9c9',
      borderColor: '#c9c9c9',
    },
  },
}));

export { UserSubheaderStyles };
