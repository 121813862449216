/**
 * Component Name: CampaignSubHeader
 * Description: Renders CampaignSubHeader component
 * Param:
 *   title,
 *   btn,
 *   onCreate,
 *   onRefresh
 *   suggestField
 * Return: CampaignSubHeader component
 * Author: Gian
 * Last Update By: RJ
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import CampaignSubHeaderStyles from './CampaignSubHeaderStyles';
import { CREATE_CAMPAIGNS } from '../../helpers';

const CampaignSubHeader = ({
  title,
  btn,
  onCreate,
  onRefresh,
  suggestField,
  permissions,
  orgStatus,
  disabled,
}) => {
  const classes = CampaignSubHeaderStyles();
  const campaignCreateBtn = () => {
    if (permissions.includes(CREATE_CAMPAIGNS)) {     
 
        return (
          <Button
            id="campaign-subheader-create-button"
            disabled={orgStatus === 'true' ? !disabled : disabled}
            onClick={onCreate}
            className={orgStatus === 'true' ? classes.button : classes.inActiveOrg}
          >
            {btn}
          </Button>
        );      
    } else {
      return null;      
    }
  };

  return (
    <div>
      <Typography variant='h4'>
        {title}
      </Typography>
      {suggestField}
      {campaignCreateBtn()}
      <Button
        className={classes.button}
        onClick={onRefresh}
        id='campaignsubheader-button-refresh'
      >
        Refresh
      </Button>
    </div>
  );
};

CampaignSubHeader.propTypes = {
  title: PropTypes.string,
  btn: PropTypes.string,
  onCreate: PropTypes.func,
  onRefresh: PropTypes.func,
  suggestField: PropTypes.any,
  permissions: PropTypes.array,
};

export { CampaignSubHeader };
