import axios from "axios";
import {
  ORGANIZATION_SERVER_ADDRESS,
  ORGANIZATION_CREATE_SUCCESS,
  ORGANIZATION_DELETE_SUCCESS,
  ORGANIZATION_UPDATE_SUCCESS,
  ORGANIZATION_READ_SUCCESS,
  ORGANIZATION_VIEW_SUCCESS,
} from './types';
import { convertTimestamp, errorHandler } from '../helpers';
import { getHeaders } from './AuthActions';

const ROOT_URL = ORGANIZATION_SERVER_ADDRESS;

/**
 * Action Name: readOrganizations
 * Description:
 *      retrieve all organization
 * Param: void
 * Return: array of organizations
 * Author: Shirwyn
 * Last Update By: Raymart
 */
export const readOrganizations = (active = 'all', page_size = 0, page_number = 1) => async (
  dispatch
) => {
  const data = [];
  try {
    let response;
    if (page_size > 0) {
      response = await axios.get(`${ROOT_URL}/organizations?page=${page_number}&page_size=${page_size}&active_status=${active}`,
        await getHeaders());
    } else {
      response = await axios.get(`${ROOT_URL}/organizations`,
        await getHeaders()
      );
    }

    if (response.status === 200) {
      response.data.requested_page.forEach((org) => {
        org.id = org.org_id;
        org.created_by = org.created_by.name;
        org.active = org.active.toString();
        org.created_timestamp = convertTimestamp(org.created_timestamp);
        data.push(org);
      });

      dispatch({
        type: ORGANIZATION_READ_SUCCESS,
        payload: {
          org_list: data,
          total_count: response.data.total_count,
          current_page: response.data.current_page,
          page_count: response.data.page_count,
          view_start: response.data.view_start,
          view_end: response.data.view_end,
        },
      });
    }
  } catch (e) {
    dispatch(errorHandler('Organization', e));
  }
};

/**
 * Action Name: createOrganizations
 * Description:
 *      creates a new organization
 * Param: payload
 * Return: a new single organization data
 * Author: Shirwyn
 * Last Update By: Nelly
 */
export const createOrganization = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/organizations`,
      payload,
      await getHeaders()
    );
    if (response.status === 201) {
      dispatch({
        type: ORGANIZATION_CREATE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Organization', e));
  }
};

/**
 * Action Name: updateOrganizations
 * Description:
 *      updates an organizations information
 * Param: id, payload
 * Return: updated organization information
 * Author: Shirwyn
 * Last Update By: Nelly
 */
export const updateOrganization = (id, payload) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${ROOT_URL}/organizations/${id}`,
      payload,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: ORGANIZATION_UPDATE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Organization', e));
  }
};

/**
 * Action Name: deleteOrganizations
 * Description:
 *      deletes a specific organization based on id
 * Param: id
 * Return: array without the deleted organization
 * Author: Shirwyn
 * Last Update By: Nelly
 */
export const deleteOrganization = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${ROOT_URL}/organizations/${id}`,
      await getHeaders()
    );
    if (response.status === 204) {
      dispatch({
        type: ORGANIZATION_DELETE_SUCCESS,
        payload: id,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Organization', e));
  }
};

/**
 * Action Name: viewOrganizations
 * Description:
 *      retrieve a specific
 * Param: payload
 * Return: object with org_id,
 *          aws_secret,
 *          org_name,
 *          date_created,
 *          created_by,
 *          created_timestamp,
 *          active,
 *          rate
 * Author: Shirwyn
 * Last Update By: Nelly
 */
export const viewOrganization = (payload) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${payload}`,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: ORGANIZATION_VIEW_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Organization', e));
  }
};
