/**
 * Component Name: SideMenu
 * Description: Renders SideMenu component
 * Param: void
 * Return: Component
 * Author: Jeremiah
 * Last Update By: Jeremiah
 */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { MenuList, MenuItem } from '@material-ui/core/';
import HeaderStyles from './SideMenuStyles';
import {
  VIEW_CAMPAIGNS,
  VIEW_USERS,
  VIEW_INVOICES,
  VIEW_ORGANIZATIONS,
  VIEW_SGM,
} from '../../helpers';

const SideMenu = ({ permission }) => {
  const classes = HeaderStyles();
  return (
    <MenuList id='side-menu'>
      <img
        src={require('../../assets/sms-white.png')}
        alt='pp-logo'
        className={classes.image}
      />
      <NavLink
        exact
        strict
        to='/dashboard'
        className={classes.navLinkDecoration}
        activeClassName={classes.navLinkActiveDecoration}
        id='dashboard-item-container'
      >
        <MenuItem className={classes.menuItem}>
          <p className={classes.pColor}>Dashboard</p>
        </MenuItem>
      </NavLink>
      <NavLink
        exact
        strict
        to='/campaigns'
        className={classes.navLinkDecoration}
        activeClassName={classes.navLinkActiveDecoration}
        id='campaigns-item-container'
      >
        {permission.includes(VIEW_CAMPAIGNS) ? (
          <MenuItem className={classes.menuItem}>
            <p className={classes.pColor}>Campaigns</p>
          </MenuItem>
        ) : null}
      </NavLink>
      <NavLink
        exact
        strict
        to='/billing'
        className={classes.navLinkDecoration}
        activeClassName={classes.navLinkActiveDecoration}
        id='billing-item-container'
      >
        {permission.includes(VIEW_INVOICES) ? (
          <MenuItem className={classes.menuItem}>
            <p className={classes.pColor}>Billing</p>
          </MenuItem>
        ) : null}
      </NavLink>
      <NavLink
        exact
        strict
        to='/organizations'
        className={classes.navLinkDecoration}
        activeClassName={classes.navLinkActiveDecoration}
        id='organizations-item-container'
      >
        {permission.includes(VIEW_ORGANIZATIONS) ? (
          <MenuItem className={classes.menuItem}>
            <p className={classes.pColor}>Organizations</p>
          </MenuItem>
        ) : null}
      </NavLink>
      <NavLink
        exact
        strict
        to='/users'
        className={classes.navLinkDecoration}
        activeClassName={classes.navLinkActiveDecoration}
        id='users-item-container'
      >
        {permission.includes(VIEW_USERS) ? (
          <MenuItem className={classes.menuItem}>
            <p className={classes.pColor}>Users</p>
          </MenuItem>
        ) : null}
      </NavLink>
      <NavLink
        exact
        strict
        to='/sgm'
        className={classes.navLinkDecoration}
        activeClassName={classes.navLinkActiveDecoration}
        id='otp-item-container'
      >
        {permission.includes(VIEW_SGM) ? (
          <MenuItem className={classes.menuItem}>
            <p className={classes.pColor}>SGM</p>
          </MenuItem>
        ) : null}
      </NavLink>
    </MenuList>
  );
};

export { SideMenu };
