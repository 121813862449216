/**
 * Style Name: OrganizationSideDrawerStyles
 * Description: Styles for Organization SideDrawer Component
 * Param: void
 * Return: class styles
 * Author: Shirwyn
 * Last Update By: Shirwyn
 */
import { makeStyles } from '@material-ui/core/styles';
import { black_color } from '../../assets/styles/types';

const OrganizationSideDrawerStyles = makeStyles(() => (
    {
        button: {
            textTransform: 'capitalize',
            justifyContent: 'normal',
            color: black_color,
            margin: '1rem 1.5rem 0.25rem',
            padding: '0.25rem',
            opacity: '0.5',
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0)',
            }
        },
        divider: {
            margin: '1.5rem 0'
        },
        drawer: {
            width: window.screen.width <= 720 ? '100vw' : '30vw',
            overflowX: 'hidden'
        },
        title: {
            fontWeight: 'bold',
            paddingLeft: window.screen.width <= 720 ? '1rem' : '0',
            marginLeft: window.screen.width <= 720 ? '1rem' : '0',
            alignSelf: window.screen.width <= 720 ? 'flex-end' : ''
        },
        container: {
            justifyContent: window.screen.width <= 720 ? '' : 'space-between',
            padding: window.screen.width <= 720 ? '0' : '1rem 2rem 0'
        },
        gridItem: {
            flex: window.screen.width <= 720 ? 'auto' : '',
            display: 'flex'
        }
    }));

export { OrganizationSideDrawerStyles };