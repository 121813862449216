/**
 * Component Name: ChangePasswordSideDrawer
 * Description: Displays the change password form
 * Param: open,
         closeDrawer,
         onInputChangePassword,
         submitChangePassword,
         oldPassword,
         newPassword,
         confirmPassword,
         isChangePassword,
         hasError,
         loaderColor,
         regExp,
         isLoading
 * Return: component
 * Author: Jeremiah
 * Last Update By: Jeremiah
 */

import { ChangePasswordSideDrawerStyles } from './ChangePasswordSideDrawerStyles';
import {
  Button,
  Divider,
  Drawer,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
} from '@material-ui/core';
import React from 'react';
import { KeyboardBackspace } from '@material-ui/icons';
import { Loader } from '../../components';

const ChangePasswordSideDrawer = ({
  open,
  closeDrawer,
  onInputChangePassword,
  submitChangePassword,
  oldPassword,
  newPassword,
  confirmPassword,
  isChangePassword,
  hasError,
  loaderColor,
  regExp,
  isLoading,
}) => {
  const classes = ChangePasswordSideDrawerStyles();
  return (
    <Drawer
      id='campaignsidedrawer'
      className={classes.sideDrawer}
      open={open}
      anchor='right'
      onClose={closeDrawer}
    >
      <div className={classes.container}>
        <Grid>
          <Grid item className={classes.gridItem}>
            <Grid>
              <Button className={classes.backButton} onClick={closeDrawer}>
                <KeyboardBackspace className={classes.backArrow} />
                Back
              </Button>
            </Grid>
            <Grid className={classes.campaignFormHeader}>
              <Typography gutterBottom className={classes.changePasswordTitle}>
                Change Password
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {isChangePassword &&
          isLoading &&
          regExp &&
          !hasError &&
          newPassword === confirmPassword &&
          (newPassword && confirmPassword && oldPassword) !== null &&
          (newPassword && confirmPassword && oldPassword) !== '' ? (
            <FormHelperText className={classes.autoLogoutMessage}>
              Automatic Logout Due to Changing Password
            </FormHelperText>
          ) : null}
          <Grid
            className={classes.formContainer}
            id='change-password-container'
          >
            <form onSubmit={submitChangePassword} className={classes.form}>
              <Grid item className={classes.gridFormItem}>
                <Typography gutterBottom className={classes.title}>
                  Old Password
                </Typography>
                <TextField
                  className={classes.textField}
                  type='password'
                  name='oldPass'
                  onChange={onInputChangePassword}
                  value={oldPassword || ''}
                  margin='normal'
                  variant='outlined'
                  placeholder='Old Password'
                  id='old-password'
                />
                {isChangePassword &&
                (oldPassword === null || oldPassword === '') ? (
                  <FormHelperText id='old-password-error' error className={classes.errorMessage}>
                    Old password is required
                  </FormHelperText>
                ) : null}
                {isChangePassword &&
                regExp &&
                ( hasError === 'INVALID_USER_CREDENTIALS' || hasError === 'INVALID_PASSWORD') &&
                newPassword &&
                confirmPassword ? (
                  <FormHelperText id='invalid-old-password' error className={classes.errorMessage}>
                    Incorrect old password
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item className={classes.gridFormItem}>
                <Typography gutterBottom className={classes.title}>
                  New Password
                </Typography>
                <TextField
                  className={classes.textField}
                  type='password'
                  name='newPass'
                  onChange={onInputChangePassword}
                  value={newPassword || ''}
                  margin='normal'
                  variant='outlined'
                  placeholder='New Password'
                  id='new-password'
                />
                {isChangePassword &&
                (newPassword === null || newPassword === '') ? (
                  <FormHelperText error className={classes.errorMessage}>
                    New password is required
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item className={classes.gridFormItem}>
                <Typography gutterBottom className={classes.title}>
                  Confirm Password
                </Typography>
                <TextField
                  className={classes.textField}
                  type='password'
                  name='confirmPass'
                  onChange={onInputChangePassword}
                  value={confirmPassword || ''}
                  margin='normal'
                  variant='outlined'
                  placeholder='Confirm Password'
                  id='confirm-password'
                />
                {isChangePassword &&
                  (confirmPassword === null || confirmPassword === '') ? (
                    <FormHelperText error className={classes.errorMessage}>
                      Confirm password is required
                    </FormHelperText>
                  ) : null}           
              </Grid>
              <Grid item className={classes.errorMessagesGrid}>             
                  {isChangePassword &&
                  !regExp &&
                  newPassword !== '' &&
                  newPassword !== null &&
                  confirmPassword !== '' &&
                  confirmPassword !== null ? (
                    <FormHelperText error className={classes.errorMessage}>
                      Invalid password format
                    </FormHelperText>
                  ) : null}
                  {isChangePassword &&
                  newPassword !== confirmPassword &&
                  newPassword !== '' &&
                  newPassword !== null &&
                  confirmPassword !== '' &&
                  confirmPassword !== null ? (
                    <FormHelperText error className={classes.errorMessage}>
                      Password do not match
                    </FormHelperText>
                  ) : null}
                  {isChangePassword &&
                  regExp &&
                  hasError === 'LIMIT_EXCEEDED' &&
                  newPassword &&
                  confirmPassword &&
                  oldPassword ? (
                    <FormHelperText id='change-pass-failed' error className={classes.errorMessage}>
                      Too many failed attempts. Please try again later.
                    </FormHelperText>
                  ) : null}
                  {isChangePassword &&
                  regExp &&
                  hasError === 'INVALID_PASSWORD_FORMAT' &&
                  newPassword &&
                  confirmPassword &&
                  oldPassword ? (
                    <FormHelperText id="max-length" error className={classes.errorMessage}>
                      New password exceeded maximum length.
                    </FormHelperText>
                  ) : null}
              </Grid>
              <Grid item className={classes.gridItem}>
                <InputLabel
                  shrink
                  htmlFor='bootstrap-input'
                  className={classes.inputLabel2}
                >
                  Minimum length is 6 characters, maximum is 98.
                </InputLabel>
                <List className={classes.guideLabel} id='guide-container'>
                  <ListItem className={classes.listItem}>
                    - Must contain at least one number.
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    - Must contain at least one special character (e.g. !, @, #,
                    $ etc.) -- spaces do not count.
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    - Must contain at least one lowercase and one uppercase
                    alphabet.
                  </ListItem>
                </List>
                <Button
                  type={'submit'}
                  variant='contained'
                  onClick={submitChangePassword}
                  className={classes.changePasswordButton}
                  disabled={
                    isChangePassword &&
                    isLoading &&
                    regExp &&
                    !hasError &&
                    newPassword === confirmPassword &&
                    (newPassword && confirmPassword && oldPassword) !== null &&
                    (newPassword && confirmPassword && oldPassword) !== ''
                  }
                >
                  {isChangePassword &&
                  isLoading &&
                  regExp &&
                  !hasError &&
                  newPassword === confirmPassword &&
                  (newPassword && confirmPassword && oldPassword) !== null &&
                  (newPassword && confirmPassword && oldPassword) !== '' ? (
                    <div className={classes.loader}>
                      <Loader
                        size={80}
                        adjustSmall={window.screen.width <= 520}
                        color={loaderColor}
                      />
                    </div>
                  ) : (
                    'Change Password'
                  )}
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

export { ChangePasswordSideDrawer };
