/**
 * Style Name: OrganizationViewStyles
 * Description: Styles for Organization View Component
 * Param: void
 * Return: class styles
 * Author: Shirwyn
 * Last Update By: Shirwyn
 */
import { makeStyles } from '@material-ui/core/styles';
import { black_color } from '../../assets/styles/types';

const OrganizationViewStyles = makeStyles(() => ({
    container: {
        padding: '0 3.5rem'
    },
    items: {
        padding: '1rem 0'
    },
    label: {
        fontWeight: '550'
    },
    labelTitle: {
        fontWeight: 'bold',
        padding: '0.75rem 0',
        color: black_color,
        opacity: '0.5'
    },
    inactive: {
        color: '#f00',
        fontWeight: '550'
    },
    active: {
        color: '#0f0',
        fontWeight: '550'
    }
}));

export { OrganizationViewStyles };