import { makeStyles } from '@material-ui/core/styles';

const CampaignViewStyles = makeStyles(() => ({
  backButton: {
    fontSize: '14px ',
    color: '#2c2c2c ',
    opacity: '0.5',
    textTransform: 'capitalize',
    margin: '0.75rem 2rem 0',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  backArrow: {
    fontSize: '24px',
    color: '#2c2c2c',
    opacity: '0.5',
  },
  sideDrawer: {
    width: window.screen.width <= 720 ? '100vw' : '30vw',
  },
  sideDrawerTitle: {
    fontSize: '15px',
    color: '#2c2c2c ',
    opacity: '0.5',
    fontWeight: 'bold',
    margin: '0 0 1rem 2rem !important',
  },
  sideDrawerData: {
    fontSize: '15px',
    fontWeight: 'bold',
    margin: '0 0 2.5rem 2rem !important',
  },
  campaignTitle: {
    fontSize: '25px',
    fontWeight: 'bold',
    margin: '0 0 2.5rem 2rem !important',
  },
  campaignViewHeader: {
    margin: '0 0 2.5rem 2rem !important',
  },
  campaignAlign: {
    padding: '1rem 0 0 1rem',
  },
}));

export default CampaignViewStyles;
