import axios from 'axios';
import {
  CAMPAIGN_SERVER_ADDRESS,
  CAMPAIGNS_CREATE_SUCCESS,
  CAMPAIGNS_READ_SUCCESS,
  CAMPAIGN_VIEW_SUCCESS,
  CAMPAIGN_CREATE_VALIDATE_SUCCESS,
  CAMPAIGN_ERROR
} from './types';
import { convertTimestamp, errorHandler } from '../helpers';
import { getHeaders } from './AuthActions';

const ROOT_URL = CAMPAIGN_SERVER_ADDRESS;

/**
 * Action Name: readCampaigns
 * Description:
 *      get all campaigns from a specific organization
 * Param: org_id, page_size, page_number
 * Return: array of campaign list
 * Author: Gian
 * Last Update By: Raymart
 */
export const readCampaigns = (org_id, page_size = 0, page_number = 1) => async (
  dispatch
) => {
  const campaigns = [];
  try {
    let response;
    if (page_size > 0) {
      response = await axios.get(
        `${ROOT_URL}/organizations/${org_id}/campaigns?page=${page_number}&page_size=${page_size}`,
        await getHeaders()
      );
    } else {
      response = await axios.get(
        `${ROOT_URL}/organizations/${org_id}/campaigns`,
        await getHeaders()
      );
    }
    if (response.status === 200) {
      response.data.requested_page.forEach((campaign) => {
        campaign.id = campaign.campaign_id;
        campaign.created_by = campaign.created_by.name;
        campaign.created_timestamp = convertTimestamp(
          campaign.created_timestamp
        );
        campaign.completed_timestamp = campaign.completed_timestamp
          ? convertTimestamp(campaign.completed_timestamp)
          : null;
        campaigns.push(campaign);
      });

      dispatch({
        type: CAMPAIGNS_READ_SUCCESS,
        payload: {
          campaigns_list: campaigns,
          total_count: response.data.total_count,
          current_page: response.data.current_page,
          page_count: response.data.page_count,
          view_start: response.data.view_start,
          view_end: response.data.view_end,
        },
      });
    }
  } catch (e) {
    dispatch(errorHandler('Campaign', e));
  }
};

/**
 * Action Name: createCampaigns
 * Description:
 *      creates campaign for a specific organization
 * Param: payload, organization_id
 * Return: a new single campaign data
 * Author: Gian
 * Last Update By: Nelly
 */
export const createCampaigns = (payload, organization_id) => async (
  dispatch
) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/organizations/${organization_id}/campaigns`,
      payload,
      await getHeaders()
    );
    if (response.status === 201) {
      dispatch({
        type: CAMPAIGNS_CREATE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Campaign', e));
  }
};

/**
 * Action Name: viewCampaign
 * Description:
 *      get all campaigns from a specific organization
 * Param: campaign_id, organization_id
 * Return: object with campaign_name.
 *          created_by,
 *          date_created,
 *          date_completed,
 *          status
 * Author: Gian
 * Last Update By: Nelly
 */
export const viewCampaign = (campaign_id, organization_id) => async (
  dispatch
) => {
  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${organization_id}/campaigns/${campaign_id}`,
      await getHeaders()
    );
    if (response.status === 200) {
      dispatch({
        type: CAMPAIGN_VIEW_SUCCESS,
        payload: response.data
      });
    }
  } catch (e) {
    dispatch(errorHandler('Campaign', e));
  }
};

/**
 * Action Name: validateCampaign
 * Description:
 *      validate file type when uploading campaigns
 * Param: payload
 * Return: fileValidation if true or false and status
 * Author: Gian
 * Last Update By: Shirwyn
 */
export const validateCampaign = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/campaigns/validate-file`,
      payload,
      await getHeaders()
    );
    if (response.status === 201) {
      dispatch({
        type: CAMPAIGN_CREATE_VALIDATE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(errorHandler('Campaign', e, 'VALIDATE_CAMPAIGN'));
  }
};


/**
 * Action Name: fileUploadDetails
 * Description:
 *      Get the details to be able to upload a campaign file directly to AWS S3 and and use the response to upload a file.
 * Param: payload, org id and file - file_type must be xlsx or xls
 * Return: If file is validated. 
 * Author: Nelly
 * Last Update By: Nelly
 */
export const fileUploadDetails = (
  payload,
  organization_id,
  fileUpload,
) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${ROOT_URL}/organizations/${organization_id}/file-upload-details`,
      payload,
      await getHeaders()
    );
    if (response.status === 201) {
      dispatch({
        type: CAMPAIGN_CREATE_VALIDATE_SUCCESS,
        payload: response.data,
      });

      const urlData = response.data.url;
      let data = response.data.fields;    

      let formData = new FormData(); 

      for ( var key in data ) {
        formData.append(key, data[key]);
      }
      formData.append('file', fileUpload);    
       axios.post(
          urlData,
          formData, 
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
    }
  } catch (e) {
    dispatch(errorHandler("Campaign", e, "VALIDATE_CAMPAIGN"));
  }
};

/**
 * Action Name: validationStatus
 * Description:
 *      Get the status of the validation of a recently uploaded campaign file to AWS S3.
 * Param: organization_id and campaign_id
 * Return: None
 * Author: Nelly
 * Last Update By: Nelly
 */
export const validationStatus = ( organization_id, campaign_id ) => async (
  dispatch
) => {
  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${organization_id}/campaign-validation/${campaign_id}`,
      await getHeaders()
    );    
   
    if(response.data.status === "Pending" || response.data.status === "Ongoing"){
      return response.data;
    } else if(response.data.status === 'Failed'){
      dispatch({
        type: CAMPAIGN_ERROR, 
        payload: response.data
      })
    } else {
      dispatch({
        type: CAMPAIGNS_CREATE_SUCCESS, 
        payload: response.data
      })
    }
    return response.data;
  } catch (e) {
    dispatch(errorHandler('Campaign', e));  
  }
};