/**
 * Class Name: OrganizationPage
 * Description: Organization container.
 * Param: N/A
 * Return: OrganizationPage Component
 * Author: Shirwyn
 * Last Update: RJ
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  readOrganizations,
  viewOrganization,
  createOrganization,
  updateOrganization,
  deleteOrganization,
} from "../../actions";
import {
  OrganizationSubheader,
  OrganizationSideDrawer,
  OrganizationForm,
  OrganizationView,
  Loader,
  CommonTable,
  Pagination,
  PageSnackBar,
} from "../../components";
import { SideDrawerLoaderStyles } from "../../assets/styles";
import {
  pp_blue_color_loader,
  white_color_loader,
} from "../../assets/styles/types";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/styles";
import { trimString } from "../../helpers";

const PAGE_SIZE = 10;

class OrganizationPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      organizationName: "",
      errorState: false,
      createState: false,
      viewState: false,
      editState: false,
      deleteState: false,
      orgExists: false,
      orgInfo: {},
      org: [],
      key: "",
      org_name: "",
      created_by: "",
      sgmRate: "",
      campaignRate: "",
      aws_secret: "",
      updatingOrg: "",
      //when to display loader for table component
      isLoadingTable: true,
      isLoadingModal: true,
      isViewLoading: false,
      isSubmitLoading: false,
      mounted: false,
      permissions: [],
      loaderColor: pp_blue_color_loader,
      orgFilterOptions: [],
      selectedOrgFilter: {},
      snackBarOpen: false,
      snackBarConfig: {
        status: "",
        message: "",
      },
    };
    this.clickedRow = this.clickedRow.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submitOrganization = this.submitOrganization.bind(this);
    this.updateOrganization = this.updateOrganization.bind(this);
    this.removeOrganization = this.removeOrganization.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.orgFilterHandler = this.orgFilterHandler.bind(this);
    this.orgNextPageHandler = this.orgNextPageHandler.bind(this);
    this.orgPreviousPageHandler = this.orgPreviousPageHandler.bind(this);
    this.orgActiveFilterHandler = this.orgActiveFilterHandler.bind(this);
  }

  /**
   * Function Name: componenDidMount
   * Description: Immediately gett all organizations from the api upon container load
   * Param: N/A
   * Return: void
   * Author: Shirwyn
   * Last Update: Everard
   */
  componentDidMount() {
    this._isMounted = true;

    this.setState(
      {
        mounted: true,
        permissions: this.props.permissions,
        orgFilterOptions: [
          { label: "Organization Name", value: "org_name" },
          { label: "Rate", value: "rate" },
          { label: "Date Created", value: "created_timestamp" },
          { label: "Created By", value: "created_by" },
          { label: "Active", value: "active" },
        ],
        selectedOrgFilter: {
          label: "Date Created",
          value: "created_timestamp",
        },
        selectedOrganizationActiveFilter: { value: "active", label: "Active" },
      },
      () => {
        this.props
          .readOrganizations(
            this.state.selectedOrganizationActiveFilter.value,
            PAGE_SIZE
          )
          .then(() => {
            if (!this.props.error) {
              this.setState(
                {
                  org: this.props.organizations,
                },
                () => {
                  //when the api returns the data for the table set isLoading to false
                  setTimeout(() => {
                    this.setState({ isLoadingTable: false });
                  }, 500);
                }
              );
            } else {
              if (this.props.system_error) {
                this.handlePageSystemAlert("error", this.props.error);
              }
            }
          });
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({ mounted: false });
  }

  /**
   * Function Name: orgPreviousPageHandler
   * Description: handler for previous button of Pagination
   * Param: event
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  orgPreviousPageHandler(e) {
    e.preventDefault();

    if (this.props.current_page > 1) {
      if (this._isMounted) {
        this.setState(
          {
            isLoadingTable: true,
          },
          () => {
            this.props
              .readOrganizations(
                this.state.selectedOrganizationActiveFilter.value,
                PAGE_SIZE,
                this.props.current_page - 1
              )
              .then(() => {
                if (!this.state.error) {
                  if (this._isMounted) {
                    this.setState({
                      org: this.props.organizations,
                      isLoadingTable: false,
                    });
                  }
                } else {
                  if (this.props.system_error) {
                    this.handlePageSystemAlert("error", this.props.error);
                  }
                }
              });
          }
        );
      }
    }
  }

  /**
   * Function Name: orgNextPageHandler
   * Description: handler for next button of Pagination
   * Param: event
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  orgNextPageHandler(e) {
    e.preventDefault();

    if (this.props.current_page < this.props.page_count) {
      if (this._isMounted) {
        this.setState(
          {
            isLoadingTable: true,
          },
          () => {
            this.props
              .readOrganizations(
                this.state.selectedOrganizationActiveFilter.value,
                PAGE_SIZE,
                this.props.current_page + 1
              )
              .then(() => {
                if (!this.state.error) {
                  if (this._isMounted) {
                    this.setState({
                      org: this.props.organizations,
                      isLoadingTable: false,
                    });
                  }
                } else {
                  if (this.props.system_error) {
                    this.handlePageSystemAlert("error", this.props.error);
                  }
                }
              });
          }
        );
      }
    }
  }

  /**
   * Function Name: userFilterHandler
   * Description: handler for Organization table filter
   * Param: selectedUserFilter
   * Return: N/A
   * Author: Shirwyn
   * Last Update By: Shirwyn
   */
  orgFilterHandler = (selectedOrgFilter) => {
    if (this._isMounted) {
      this.setState({ selectedOrgFilter });
    }
  };

  /**
   * Function Name: orgActiveFilterHandler
   * Description: handler for Organization active status filter
   * Param: orgActiveFilterHandler
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */
  orgActiveFilterHandler(selectedOrganizationActiveFilter) {
    if (this._isMounted) {
      this.setState(
        {
          selectedOrganizationActiveFilter,
          isLoadingTable: true,
        },
        () => {
          this.props
            .readOrganizations(
              this.state.selectedOrganizationActiveFilter.value,
              PAGE_SIZE
            )
            .then(() => {
              if (!this.props.error) {
                if (this._isMounted) {
                  this.setState({
                    org: this.props.organizations,
                    isLoadingTable: false,
                  });
                }
              } else {
                if (this.props.system_error) {
                  this.handlePageSystemAlert("error", this.props.error);
                }
              }
            });
        }
      );
    }
  }

  /**
   * Function Name: onChange
   * Description: handles onChange event of text fields
   * Param: N/A
   * Return: void
   * Author: Shirwyn
   * Last Update: RJ
   */
  onChange(e) {
    this.setState({
      [e.target.name]:
        e.target.name === "org_name"
          ? e.target.value.slice(0, 70)
          : e.target.name === "campaignRate"
          ? e.target.value.slice(0, 70)
          : e.target.name === "sgmRate"
          ? e.target.value
          : e.target.name === "aws_secret"
          ? e.target.value.slice(0, 25)
          : "",
    });
    e.preventDefault();
    this.setState({ orgExists: false });
  }

  /**
   * Function Name: validateInput
   * Description: validates required input fields before submitting
   * Param: e (event), form
   * Return: void
   * Author: Shirwyn
   * Last Update: RJ
   */
  validateInput(e, form) {
    e.preventDefault();
    if (
      form === "create" &&
      this.state.org_name !== "" &&
      this.state.aws_secret !== "" &&
      this.state.campaignRate !== "" &&
      this.state.sgmRate !== ""
    ) {
      this.submitOrganization();
    } else if (
      form === "update" &&
      this.state.org_name &&
      this.state.aws_secret &&
      this.state.campaignRate !== "" &&
      this.state.sgmRate !== "" &&
      this.state.updatingOrg === this.state.org_name
    ) {
      this.setState({ orgExists: false });
      this.updateOrganization();
    } else if (
      form === "update" &&
      this.state.org_name !== "" &&
      this.state.aws_secret !== "" &&
      this.state.campaignRate !== "" &&
      this.state.sgmRate !== ""
    ) {
      this.updateOrganization();
    } else if (
      (form === "create" || "update") &&
      this.state.org_name !== "" &&
      this.state.aws_secret !== "" &&
      this.state.campaignRate !== "" &&
      this.state.sgmRate !== ""
    ) {
      this.setState({ errorState: true });
    } else {
      this.setState({ errorState: true });
    }
  }

  /**
   * Function Name: submitOrganization
   * Description: handles creation of new organization
   * Param: N/A
   * Return: void
   * Author: Shirwyn
   * Last Update: RJ
   */
  submitOrganization() {
    const { org_name, aws_secret, campaignRate, sgmRate } = this.state;
    const formatRate = (rate) => {
      return rate.slice(0, 1) === "." ? "0" + rate : rate;
    };
    const submitPayload = {
      org_name: org_name,
      aws_secret: aws_secret,
      rate: formatRate(campaignRate),
      rate_sgm: formatRate(sgmRate),
    };
    if (submitPayload.rate > 0 && submitPayload.rate_sgm > 0) {
      this.setState({
        isSubmitLoading: true,
        loaderColor: pp_blue_color_loader,
      });
      this.props.createOrganization(submitPayload).then(() => {
        if (!this.props.error) {
          this.props
            .readOrganizations(
              this.state.selectedOrganizationActiveFilter.value,
              PAGE_SIZE
            )
            .then(() => {
              this.setState(
                {
                  org: this.props.organizations,
                  org_name: "",
                  aws_secret: "",
                  campaignRate: "",
                  sgmRate: "",
                  errorState: false,
                  orgExists: false,
                  createState: false,
                  isSubmitLoading: false,
                  isLoadingTable: true,
                },
                () => {
                  //when the api returns the data for the table set isLoading to false
                  setTimeout(() => {
                    this.setState({
                      isLoadingTable: false,
                    });

                    this.handlePageSystemAlert(
                      "success",
                      "Organization created."
                    );
                  }, 500);
                }
              );
            });
        } else {
          this.setState({
            createState: true,
            isLoadingTable: false,
            isSubmitLoading: false,
            orgExists: this.props.status === 422, // organization name already exists
          });

          if (this.props.system_error) {
            this.handlePageSystemAlert("error", this.props.error);
          }
        }
      });
    } else {
      this.setState({
        errorState: true,
      });
    }
  }

  /**
   * Function Name: updateOrganization
   * Description: handles updates of existing organizations
   * Param: N/A
   * Return: void
   * Author: Shirwyn
   * Last Update: RJ
   */
  updateOrganization(activate = false) {
    const { org_name, aws_secret, campaignRate, sgmRate, key } = this.state;
    const formatRate = (rate) => {
      return rate.slice(0, 1) === "." ? "0" + rate : rate;
    };
    const editPayload = {
      org_name,
      aws_secret,
      rate: formatRate(campaignRate),
      rate_sgm: formatRate(sgmRate),
    };
    const payload = activate
      ? {
          active: true,
        }
      : editPayload;
    if ((payload.rate > 0 && payload.rate_sgm > 0) || activate) {
      this.setState({
        isSubmitLoading: true,
        loaderColor: pp_blue_color_loader,
      });
      this.props.updateOrganization(key, payload).then(() => {
        if (!this.props.error) {
          this.props
            .readOrganizations(
              this.state.selectedOrganizationActiveFilter.value,
              PAGE_SIZE,
              this.props.current_page
            )
            .then(() => {
              if (!this.props.error) {
                this.setState(
                  {
                    org: this.props.organizations,
                    org_name: "",
                    aws_secret: "",
                    campaignRate: "",
                    sgmRate: "",
                    editState: false,
                    viewState: false,
                    errorState: false,
                    createState: false,
                    isSubmitLoading: false,
                    isLoadingTable: true,
                    deleteState: false,
                  },
                  () => {
                    //when the api returns the data for the table set isLoading to false
                    setTimeout(() => {
                      this.setState({
                        isLoadingTable: false,
                      });
                      this.handlePageSystemAlert(
                        "success",
                        "Organization updated."
                      );
                    }, 500);
                  }
                );
              } else {
                this.setState({
                  isLoadingTable: false,
                  isSubmitLoading: false,
                });
                if (this.props.system_error) {
                  this.handlePageSystemAlert("error", this.props.error);
                }
              }
            });
        } else {
          this.setState({
            editState: true,
            isLoadingTable: false,
            isSubmitLoading: false,
            orgExists: this.props.status === 422, // organization name already exists
          });

          if (this.props.system_error) {
            this.handlePageSystemAlert("error", this.props.error);
          }
        }
      });
    } else {
      this.setState({ errorState: true });
    }
  }

  /**
   * Function Name: removeOrganization
   * Description: handles deletion of existing organizations
   * Param: N/A
   * Return: void
   * Author: Shirwyn
   * Last Update: Everard
   */
  removeOrganization() {
    this.setState({ isLoadingModal: true, loaderColor: white_color_loader });
    setTimeout(() => {
      this.props.deleteOrganization(this.state.key).then(() => {
        this.setState({
          isLoadingTable: true,
          loaderColor: pp_blue_color_loader,
        });
        this.props
          .readOrganizations(
            this.state.selectedOrganizationActiveFilter.value,
            PAGE_SIZE
          )
          .then(() => {
            if (!this.props.error) {
              this.setState(
                {
                  org: this.props.organizations,
                  viewState: false,
                  deleteState: false,
                  organizationName: "",
                },
                () => {
                  //when the api returns the data for the table set isLoading to false
                  setTimeout(() => {
                    this.setState({
                      isLoadingTable: false,
                    });
                    this.handlePageSystemAlert(
                      "success",
                      "Organization disabled."
                    );
                  }, 500);
                }
              );
            } else {
              if (this.props.system_error) {
                this.handlePageSystemAlert("error", this.props.error);
              }
            }
          });
      });
    }, 500);
  }

  /**
   * Function Name: clickedRow
   * Description: triggers when a table row is clicked and fetch the id
   * Param: id
   * Return: void
   * Author: Shirwyn
   * Last Update: RJ
   */
  clickedRow(id) {
    this.setState(
      {
        isViewLoading: true,
        viewState: true,
        key: id,
      },
      () => {
        this.props.viewOrganization(this.state.key).then(() => {
          this.setState(
            {
              org_name: this.props.organizationInfo.org_name,
              created_by: this.props.organizationInfo.created_by.name,
              aws_secret: this.props.organizationInfo.aws_secret,
              campaignRate: this.props.organizationInfo.rate,
              sgmRate: this.props.organizationInfo.rate_sgm,
              orgInfo: this.props.organizationInfo,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  isViewLoading: false,
                });
              }, 500);
            }
          );
        });
      }
    );
  }

  /**
   * Function Name: handleAlertOncClose
   * Description: Triggers once the alert component requests to be closed according to the duration time
   * Param: handleAlertOnClose
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */

  handleAlertOnClose = () => {
    this.setState({
      snackBarOpen: false,
    });
  };

  /**
   * Function Name: handlePageSystemAlert
   * Description: Triggers once the alert is a system alert
   * Param: type, message
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */

  handlePageSystemAlert = (type, message) => {
    this.setState({
      snackBarOpen: true,
      snackBarConfig: {
        status: type,
        message: message,
      },
    });
  };

  /**
   * Function Name: orgModalInputChange
   * Description: Setting the state of organizationName
   * Param: n/a
   * Return: void
   * Author: Nelly
   * Last Update By: Nelly
   */
  orgModalInputChange = (e) => {
    this.setState({
      organizationName: e.target.value,
    });
  };

  // /**
  //  * Function Name: formatInput
  //  * Description: To remove whitespaces on input
  //  * Param: n/a
  //  * Return: trimmed texts
  //  * Author: Nelly
  //  * Last Update By: Nelly
  //  */
  formatInput = (e) => {
    const attribute = e.target.getAttribute("name");
    this.setState({ [attribute]: trimString(e.target.value) });
  };

  render() {
    const { classes } = this.props;
    return (
      <div id="organization-page">
        <OrganizationSubheader
          title={"Organizations"}
          btn={"Create Organization"}
          permissions={this.state.permissions}
          onCreate={() => {
            this.setState({
              createState: true,
              errorState: false,
            });
          }}
        />
        {
          //if isLoading is true it will display the loader
          //else it will display the table component
          this.state.isLoadingTable ? (
            <Loader
              id="table-Loading"
              adjustSmall={window.screen.width <= 720}
              color={this.state.loaderColor}
            />
          ) : (
            <div>
              <Pagination
                id="org-page-active-filter"
                customSelectID="organization-active-filter"
                filterList={this.state.orgFilterOptions}
                selectedFilter={this.state.selectedOrgFilter}
                filterChangeHandler={this.orgFilterHandler}
                startingNumber={this.props.view_start}
                endingNumber={this.props.view_end}
                totalCount={this.props.total_count}
                previousButtonHandler={(e) => this.orgPreviousPageHandler(e)}
                nextButtonHandler={(e) => this.orgNextPageHandler(e)}
                isPreviousButtonDisabled={this.props.view_start <= 1}
                isNextButtonDisabled={
                  this.props.total_count === this.props.view_end
                }
                // for filter
                filterOptions={[
                  { value: "all", label: "All" },
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                ]}
                filterSelectedOption={
                  this.state.selectedOrganizationActiveFilter
                }
                filterOnChangeHandler={this.orgActiveFilterHandler}
              />
              <CommonTable
                id="org-table-view"
                headerArray={[
                  "Organization Name",
                  "Rate (Campaign/SGM)",
                  "Date Created",
                  "Created By",
                  "Status",
                ]}
                arrangement={[
                  "org_name",
                  "rate",
                  "created_timestamp",
                  "created_by",
                  "active",
                ]}
                colWidth={["40%", "20%", "15%", "15%", "15%"]}
                clickedRowHandler={this.clickedRow}
                data={this.state.org}
                page={"organization"}
              />
            </div>
          )
        }
        {/* Conditional renders the OrganizationForm (create) component */}
        {this.state.createState === true ? (
          <OrganizationSideDrawer
            id="org-drawer"
            type={"create"}
            title={"Organization"}
            drawerState={this.state.createState}
            showModal={this.state.deleteState}
            onBack={() => {
              this.setState({
                createState: false,
                orgExists: false,
              });
            }}
          >
            <OrganizationForm
              id="org-form-create"
              formatInput={this.formatInput}
              btnText={"Cancel"}
              formType={"Submit"}
              handleInputChange={(e) => this.onChange(e)}
              submitOrganization={(e) => this.validateInput(e, "create")}
              hasError={this.state.errorState}
              orgName={this.state.org_name}
              awsSecret={this.state.aws_secret}
              campaignRate={this.state.campaignRate}
              sgmRate={this.state.sgmRate}
              hasDuplicateOrg={this.state.orgExists}
              submitLoading={this.state.isSubmitLoading}
              loaderColor={this.state.loaderColor}
              onCancel={() => {
                this.setState({
                  createState: false,
                  org_name: "",
                  aws_secret: "",
                  campaignRate: "",
                  sgmRate: "",
                  orgExists: false,
                });
              }}
            />
          </OrganizationSideDrawer>
        ) : null}
        {/* Conditional renders the organizationView component */}
        {this.state.viewState ? (
          <OrganizationSideDrawer
            id="org-side-drawer-view"
            type={"view"}
            title={"Organization"}
            loaderColor={this.state.loaderColor}
            loadingModal={this.state.isLoadingModal}
            drawerState={this.state.viewState}
            getSelectedItem={this.state.orgInfo.org_name}
            deleteSelected={this.removeOrganization}
            isActive={this.state.orgInfo.active}
            permissions={this.state.permissions}
            organizationName={this.state.organizationName}
            orgModalInputChange={this.orgModalInputChange}
            onBack={() => {
              this.setState({
                viewState: false,
                org_name: "",
                campaignRate: "",
                sgmRate: "",
                aws_secret: "",
              });
            }}
            onEdit={() => {
              this.setState({
                viewState: false,
                editState: true,
                org_name: this.props.organizationInfo.org_name,
                created_by: this.props.organizationInfo.created_by.name,
                aws_secret: this.props.organizationInfo.aws_secret,
                campaignRate: this.props.organizationInfo.rate,
                sgmRate: this.props.organizationInfo.rate_sgm,
                updatingOrg: this.props.organizationInfo.org_name,
              });
            }}
            onDelete={() => {
              this.setState({
                deleteState: true,
                isLoadingModal: false,
                org_name: "",
                campaignRate: "",
                sgmRate: "",
                aws_secret: "",
              });
            }}
            onActivate={() => {
              this.setState({ organizationName: "" });
              this.updateOrganization(true);
            }}
            showModal={this.state.deleteState}
            handleCloseModal={() => {
              this.setState({
                deleteState: false,
                isLoadingModal: false,
                organizationName: "",
              });
            }}
          >
            {this.state.isViewLoading ? (
              <div className={classes.loader}>
                <Loader
                  id="org-view-loading"
                  size={300}
                  adjustSmall={window.screen.width <= 720}
                  color={this.state.loaderColor}
                />
              </div>
            ) : (
              <OrganizationView
                id="org-view"
                org_name={this.props.organizationInfo.org_name}
                aws_secret={this.props.organizationInfo.aws_secret}
                created_by={this.props.organizationInfo.created_by.name}
                date_created={this.props.organizationInfo.created_timestamp}
                active={this.props.organizationInfo.active}
                campaignRate={this.props.organizationInfo.rate}
                sgmRate={this.props.organizationInfo.rate_sgm}
              />
            )}
          </OrganizationSideDrawer>
        ) : null}
        {/* Conditional renders the OrganizationForm (edit) component */}
        {this.state.editState === true ? (
          <OrganizationSideDrawer
            id="org-side-drawer-edit"
            type={"edit"}
            title={"Organization"}
            drawerState={this.state.editState}
            showModal={this.state.deleteState}
            onBack={() => {
              this.setState({
                editState: false,
                viewState: false,
                org_name: "",
                campaignRate: "",
                sgmRate: "",
                aws_secret: "",
                updatingOrg: "",
                orgExists: false,
              });
            }}
          >
            <OrganizationForm
              id="org-form-edit"
              formatInput={this.formatInput}
              btnText={"Cancel"}
              handleInputChange={(e) => this.onChange(e)}
              orgName={this.state.org_name}
              awsSecret={this.state.aws_secret}
              createdBy={this.state.orgInfo.created_by.name}
              campaignRate={this.state.campaignRate}
              sgmRate={this.state.sgmRate}
              dateCreated={this.state.orgInfo.created_timestamp}
              submitOrganization={(e) => this.validateInput(e, "update")}
              formType={"Save Changes"}
              hasError={this.state.errorState}
              hasDuplicateOrg={this.state.orgExists}
              submitLoading={this.state.isSubmitLoading}
              loaderColor={this.state.loaderColor}
              onCancel={() => {
                this.setState({
                  editState: false,
                  org_name: "",
                  created_by: "",
                  campaignRate: "",
                  sgmRate: "",
                  aws_secret: "",
                  updatingOrg: "",
                });
              }}
            />
          </OrganizationSideDrawer>
        ) : null}

        <PageSnackBar
          open={this.state.snackBarOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={this.handleAlertOnClose}
          snackBarConfig={this.state.snackBarConfig}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ organization, auth }) => {
  const {
    organizations,
    total_count,
    current_page,
    page_count,
    view_start,
    view_end,
    error,
    system_error,
    organizationInfo,
    status,
    type,
  } = organization;
  const { permissions } = auth;

  return {
    organizations,
    total_count,
    current_page,
    page_count,
    view_start,
    view_end,
    error,
    system_error,
    permissions,
    organizationInfo,
    status,
    type,
  };
};

export default compose(
  withStyles(SideDrawerLoaderStyles),
  connect(mapStateToProps, {
    readOrganizations,
    viewOrganization,
    createOrganization,
    updateOrganization,
    deleteOrganization,
  })
)(OrganizationPage);
