/**
 * Style Name: OrganizationFormStyles
 * Description: Styles for Organization form Component
 * Param: void
 * Return: class styles
 * Author: Shirwyn
 * Last Update By: Shirwyn
 */
import { makeStyles } from '@material-ui/core/styles';
import { pp_blue_color, black_color } from '../../assets/styles/types';

const OrganizationFormStyles = makeStyles(() => ({
  button: {
    margin: '1rem 0',
    color: '#fff',
    textTransform: 'capitalize',
    backgroundColor: pp_blue_color,
    width: '9rem',
    height: '2.2rem',
    '&:hover': {
      backgroundColor: pp_blue_color,
      borderColor: pp_blue_color,
    },
  },
  form: {
    padding: '0 3.5rem',
    display: 'block',
  },
  gridMargin: {
    margin: '1rem 0',
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  label: {
    fontWeight: 'bold',
    color: black_color,
    opacity: '0.5',
  },
  data: {
    fontWeight: 'bold',
    padding: '1rem 0',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  CancelBtn: {
    backgroundColor: '#c9c9c9',
    marginLeft: '1.5rem',
    margin: '1rem 0',
    color: 'white',
    textTransform: 'capitalize',
    width: '9rem',
    height: '2.2rem',
    '&:hover': {
      backgroundColor: '#c9c9c9',
      borderColor: '#c9c9c9',
    },
  }
}));

export { OrganizationFormStyles };
