import axios from 'axios';
import {
  SGM_ERROR,
  SGM_READ_SUCCESS,
  SGM_FILTER_SUCCESS,
  SGM_SERVER_ADDRESS,
  UNAUTHORIZED_ERROR,
} from './types';
import { getHeaders } from './AuthActions';
import { convertTimestamp, errorHandler } from '../helpers';
const ROOT_URL = SGM_SERVER_ADDRESS;

/**
 * Action Name: readFilteredMessages
 * Description:
 *      retrieve sgm for specific organization with masked phone number
 * Param: org_id, page_size, page_number
 * Return: array of filtered messages
 * Author: Gian
 * Last Update By: RJ
 */

export const readFilteredMessages = (PAYLOAD) => async (dispatch) => {
  let {
    orgID,
    size,
    last_timestamp,
    first_timestamp,
    message_id,
    direction,
    order,
    status,
    start,
    end,
  } = PAYLOAD;
  try {
    const config = {
      headers: (await getHeaders()).headers,
      params: {
        size,
        last_created_timestamp: last_timestamp || first_timestamp,
        last_message_id: message_id,
        direction,
        order,
        status,
        start,
        end,
      },
    };
    let response;
    response = await axios.get(
      `${ROOT_URL}/organizations/${orgID}/sgm`,
      config
    );

    if (response.status === 200) {
      const data = response.data.results.map((item) => {
        const COMPLETED_UTC_TIME =
          item.completed_timestamp && item.completed_timestamp.split(' ');
        return {
          ...item,
          id: item.message_id,
          completed_timestamp:
            item.completed_timestamp &&
            convertTimestamp(
              `${COMPLETED_UTC_TIME[0]}T${COMPLETED_UTC_TIME[1]}`
            ),
          created_timestamp:
            item.created_timestamp && convertTimestamp(item.created_timestamp),
        };
      });
      const CREATED_TIMESTAMP = {
        first: response.data.results[0]?.created_timestamp,
        last: response.data.results?.slice(-1)[0]?.created_timestamp,
      };
      const MESSAGE_ID = {
        first: response.data.results[0]?.message_id,
        last: response.data.results?.slice(-1)[0]?.message_id,
      };
      dispatch({
        type: SGM_FILTER_SUCCESS,
        payload: {
          sgm_list: data,
          total_count: response.data.results.length,
          is_last_page:
            direction === 'backward' ? false : response.data.is_last_page,
          MESSAGE_ID,
          CREATED_TIMESTAMP,
        },
      });
    }
  } catch (e) {
    if (e.response.status === 401) {
      return {
        type: UNAUTHORIZED_ERROR,
        payload: {
          error: 'Unauthorized Access error',
          status: e.response.status,
        },
      };
    } else {
      dispatch({
        type: SGM_ERROR,
        payload: {
          error: "Can't retrieve SGM",
          description: e.response.error_message,
        },
      });
    }
  }
};

/**
 * Action Name: viewSpecificMessage
 * Description:
 *      retrieve single sgm
 * Param: organization_id, message_id
 * Return: object with completed_timestamp,
 *          created_timestamp,
 *          failure_reason,
 *          is_priority,
 *          message_id,
 *          organization_id,
 *          rate,
 *          retry_count,
 *          status,
 *          masked_mobile_number
 * Author: Gian
 * Last Update By: RJ
 */
export const viewSpecificMessage =
  (organization_id, message_id) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${ROOT_URL}/organizations/${organization_id}/sgm/${message_id}`,
        await getHeaders()
      );
      if (response.status === 200) {
        dispatch({
          type: SGM_READ_SUCCESS,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e.response === undefined || e.response.status === 401) {
        return dispatch(errorHandler('SGM', e));
      } else {
        dispatch({
          type: SGM_ERROR,
          payload: {
            error: "Can't view SGM",
            description: e.response.error_message,
          },
        });
      }
    }
  };
