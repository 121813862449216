import React from 'react';
import { Drawer, Typography, Grid, Button, Divider } from '@material-ui/core';
import CampaignSideDrawerStyles from './CampaignSideDrawerStyles';
import { KeyboardBackspace } from '@material-ui/icons';

const thisCampaignTitle = (type, title, closeDrawer) => {
  const classes = CampaignSideDrawerStyles();
  switch (type) {
    case 'view':
      return (
        <Grid>
          <Grid>
            <Button id='side-drawer-back-button-view' className={classes.backButton} onClick={closeDrawer}>
              <KeyboardBackspace className={classes.backArrow} />
              Back
            </Button>
          </Grid>
          <Grid className={classes.campaignFormHeader}>
            <Typography className={classes.campaignTitle}>
              View {title}
            </Typography>
          </Grid>
        </Grid>
      );
    case 'create':
      return (
        <Grid>
          <Grid>
            <Button  id='side-drawer-back-button-create' className={classes.backButton} onClick={closeDrawer}>
              <KeyboardBackspace className={classes.backArrow} />
              Back
            </Button>
          </Grid>
          <Grid className={classes.campaignFormHeader}>
            <Typography className={classes.campaignTitle}>
              Create {title}
            </Typography>
          </Grid>
        </Grid>
      );
    default:
      return null;
  }
};

const CampaignSideDrawer = ({ type, title, children, open, closeDrawer }) => {
  const classes = CampaignSideDrawerStyles();
  return (
    <Drawer
      id='campaignsidedrawer'
      className={classes.sideDrawer}
      open={open}
      anchor='right'
      onClose={closeDrawer}
    >
      <div className={classes.sideDrawer}>
        <Grid>
          <Grid item className={classes.gridItem}>
            {thisCampaignTitle(type, title, closeDrawer)}
          </Grid>
          <Divider className={classes.divider} />
          <Grid item className={classes.gridItem}>
            {children}
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

export { CampaignSideDrawer, thisCampaignTitle };
