import { makeStyles } from '@material-ui/core/styles';

const OrganizationTableViewStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
        textAlign: 'left'
    },
    tableRow: {
        borderLeft: '5px solid #fff',
        '&:hover': {
            backgroundColor: 'rgba(102,204,255,.25) !important',
            borderColor: '#6cf'
        }
    },
    tableCellHead: {
        fontSize: '15px',
        fontWeight: 'bold',
        color: '#2c2c2c',
        opacity: '0.5'
    }
  }));

  export default OrganizationTableViewStyles;