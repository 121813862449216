/**
 * Style Name: CommonStyles
 * Description: Common Styles
 * Param: void
 * Return: class styles
 * Author: Frances
 * Last Update By: Frances
 */
import { makeStyles } from '@material-ui/core/styles';

const CommonStyles = makeStyles( theme => ({
    fluid: {
        width: '100%',
        height: 'auto'
    },
    inputTitle: {
        padding: '0.5rem'
    },
    selectTitle: {
        padding: '1rem 0 1rem 0'
    }
  }));

export { CommonStyles };
