/**
 * Style Name: InvoiceTableViewStyles
 * Description: Styles for Invoice Table View Component
 * Param: void
 * Return: class styles
 * Author: Jeremiah
 * Last Update By: Jeremiah
 */
import { makeStyles } from '@material-ui/core/styles';
import {pp_blue_color} from "./types";

const ForceChangePasswordStyles = makeStyles( theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        minWidth: "10vh",
        backgroundImage: "linear-gradient(45deg, pp_blue_color, #3980CB)"
    },
    margin: {
        width: "15rem",
        marginTop: ".3rem"
    },
    image: {
        height: "8.75rem",
        width: "15.500rem",
        margin: "-.5rem auto -1rem auto",
        display: 'block',
        padding: "3px"
      },
    formContainer: {
        justifyContent: "center",
        alignItems: "center",
        padding: "1.5rem",
        borderRadius: ".6rem",
        maxWidth: "18rem",
        textAlign: "center",
        fontSize: "1rem"
    },
    errorMessage: {
        textAlign: "center"
    },
    inputLabel: {
        marginTop: "1rem",
        textAlign: "center",
        fontWeight: "bold"
    },
    inputLabel2: {
        marginTop: "1rem",
        textAlign: "left",
        fontWeight: "bold",
        marginLeft: "2rem",
        fontSize:"1rem",
        lineHeight: "1.2",
        maxHeight: "1.5rem",
        width: "100%"
    },
    form: {
        maxWidth: '262px',
        margin: 'auto'
    },
    gridItem: {
        flex: window.screen.width <= 720 ? 'auto' : ''
    },
    guideLabel: {
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "17rem",
        textAlign: "left",
        fontSize: ".75rem",
        fontFamily: '"Avenir", sans-serif',
        marginLeft: "1rem",
        color: "rgba(0, 0, 0, 0.54)"
    },
    listItem:{
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    loader:{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
    },
    loginButton: {
        width: "15rem",
        height: "3rem",
        marginTop: "0.8rem",
        backgroundColor: pp_blue_color,
        color: '#fff',
        fontWeight: "bold",
        fontSize: "1rem",
        textTransform: "capitalize",
        '&:hover':{
            backgroundColor: pp_blue_color
        }
    }
}));

export { ForceChangePasswordStyles };