/**
 * Style Name: OrganizationSubheaderStyles
 * Description: Styles for Organization Subheader Component
 * Param: void
 * Return: class styles
 * Author: Shirwyn
 * Last Update By: Shirwyn
 */
import { makeStyles } from '@material-ui/core/styles';
import { pp_blue_color } from '../../assets/styles/types';

const OrganizationSubheaderStyles = makeStyles(() => ({
    button: {
        backgroundColor: pp_blue_color,
        borderColor: pp_blue_color,
        color: '#fff',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: pp_blue_color,
            borderColor: pp_blue_color
        }
    },
    subHeader: {
        width: '100%'
    }
}));

export { OrganizationSubheaderStyles };