import { createMuiTheme } from '@material-ui/core/styles';
import { black_color, pp_blue_color, error_color } from '../styles/types';

const CommonOverridesStyles = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiOutlinedInput: {
            // Name of the rule
            input: {
            // Some CSS
            padding: '0.75rem'
            },
        },
    },
    typography: {
       fontFamily: '"Avenir", sans-serif',
       color: black_color
    },
    palette: {
        primary: {
            main: pp_blue_color
        },
        error: {
            main: error_color,
        }
    }
});

export { CommonOverridesStyles };