import { makeStyles } from '@material-ui/core/styles';
import { black_light_color } from '../../assets/styles/types';

const PaginationStyles = makeStyles(() => ({
    nextButton: {
        border: '1px solid ' + black_light_color,
        borderRadius: '0',
        padding: '0.3rem'
    },
    previousButton: {
        border: '1px solid ' + black_light_color,
        borderRadius: '0',
        padding: '0.3rem'
    },
    pageNumber: {
        paddingTop: '0.5rem',
        marginRight: '1rem'
    },
    searchButton: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    searchField: {
        width: '13rem',
        paddingRight: '0'
    },
    filterWidth: {
        width: '10rem'
    },
    container: {
        margin: '2rem 0 1rem'
    },
    selectActiveDropdown: {
        width: '10rem'
    },
    filterLabel: {
        lineHeight: '3'
    }
}));

export default PaginationStyles;