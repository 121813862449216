/**
 * Function Name: stringToDate
 * Description: convert string date format depending on interval
 * Param: date, interval
 * Return: new date format
 * Author: Shirwyn
 * Last Update By: Shirwyn
 */
import moment from 'moment';

const stringToDate = (date, interval) => {
    switch (interval) {
        case 'daily':
            return moment(date).format('MMM-DD');
        case 'weekly': {
            const dateArr = (date).split(' - ');
            return [moment(dateArr[0]).format('MMM-DD'), moment(dateArr[1]).format('MMM-DD')].join(' - ');
        }
        case 'monthly':
            return date;
        default:
            return date;
    }
}

export { stringToDate };