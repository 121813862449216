import React from 'react';
import { Drawer, Typography, Grid, Button, Divider } from '@material-ui/core';
import SgmSideDrawerStyles from './SgmSideDrawerStyles';
import { KeyboardBackspace } from '@material-ui/icons';

const thisSgmTitle = (type, title, closeDrawer) => {
  const classes = SgmSideDrawerStyles();
  if (type === 'view') {
    return (
      <Grid id='sgm-view-sidedrawer'>
        <Grid>
          <Button className={classes.backButton} onClick={closeDrawer}>
            <KeyboardBackspace className={classes.backArrow} />
            Back
          </Button>
        </Grid>
        <Grid className={classes.sgmFormHeader}>
          <Typography className={classes.sgmTitle}>View {title}</Typography>
        </Grid>
      </Grid>
    );
  }
};

const SgmSideDrawer = ({ type, title, children, open, closeDrawer }) => {
  const classes = SgmSideDrawerStyles();
  return (
    <Drawer
      id='sgm-sidedrawer'
      className={classes.sideDrawer}
      open={open}
      anchor='right'
      onClose={closeDrawer}
    >
      <div className={classes.sideDrawer}>
        <Grid>
          <Grid item className={classes.gridItem}>
            {thisSgmTitle(type, title, closeDrawer)}
          </Grid>
          <Divider className={classes.divider} />
          <Grid item className={classes.gridItem}>
            {children}
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

export { SgmSideDrawer, thisSgmTitle };
