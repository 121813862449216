/**
 * Component Name: OrganizationSuggest
 * Description: Renders Dashboard Layout
 * Param:
 *      organizationName,
 *      changeHandler,
 *      keyPressHandler,
 *      showSuggestionsState,
 *      userInputState,
 *      filteredSuggestionsState,
 *      onSuggestClickfunc
 * Return: OrganizationSuggest component
 * Author: Frances
 * Last Update By: Shirwyn
 */
import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel } from '@material-ui/core';
import { CustomSelect } from '../../components';
import { CommonStyles } from '../../assets/styles';
import { OrganizationSuggestStyles } from './OrganizationSuggestStyles';
import { VIEW_ORGANIZATIONS } from '../../helpers';

const OrganizationSuggest = ({
  organizationName,
  changeHandler,
  organizations,
  permissions,
}) => {
  const commonClasses = CommonStyles();
  const classes = OrganizationSuggestStyles();
  return (
    <div>
      {permissions.includes(VIEW_ORGANIZATIONS) ? (
        <InputLabel className={commonClasses.selectTitle}>
          Organization
        </InputLabel>
      ) : null}
      {permissions.includes(VIEW_ORGANIZATIONS) ? (
        <CustomSelect
          id='org-suggest'
          customSelectID='organization-suggest'
          customName='organizationName'
          selectedValue={organizationName}
          onChangeHandler={changeHandler}
          customOptions={organizations}
          customClassName={classes.selectDropdown}
          searchable={true}
        />
      ) : null}
    </div>
  );
};

OrganizationSuggest.propTypes = {
  organizationName: PropTypes.object,
  changeHandler: PropTypes.func,
  organizations: PropTypes.array,
};

export { OrganizationSuggest };
