import { makeStyles } from '@material-ui/core/styles';
import { pp_blue_color, white_color } from '../../assets/styles/types';

const CampaignSubHeaderStyles = makeStyles(() => ({
  button: {
    margin: '1rem .25rem 0',
    color: white_color,
    textTransform: 'capitalize',
    backgroundColor: pp_blue_color,
    '&:hover': {
      backgroundColor: pp_blue_color,
      borderColor: pp_blue_color,
    },
  },
  inActiveOrg: {
    color: '#fff !important',
    textTransform: 'capitalize',
    backgroundColor: '#c9c9c9',
    cursor: 'default',
    margin: '1rem 0 0',
    '&:hover': {
      backgroundColor: '#c9c9c9',
      borderColor: '#c9c9c9',
    },
  },
}));

export default CampaignSubHeaderStyles;
