/**
 * Style Name: LoaderStyles
 * Description: Styles for Loader
 * Param: void
 * Return: class styles
 * Author: Frances
 * Last Update By: Frances
 */
import { makeStyles } from '@material-ui/core/styles';

const LoaderStyles = makeStyles(theme => ({
    conatiner: {
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'flex-start'
        }
    },
    item: {
        [theme.breakpoints.up("sm")]: {
            width: '100%'
        },
        [theme.breakpoints.down("sm")]: {
            margin: '0 auto'
        },
        [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
            // margin: '-12rem auto',
        }
    }
}));

export { LoaderStyles };
