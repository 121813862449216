import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const RefresherComponent = ({ refresher, component: Component, status }) => {
  return (
    <div>
      {status ? (
        <Grid
          id='refresher_layout'
          container
          justify='center'
          alignContent='center'
          alignItems='center'
          style={{ height: '15rem' }}
        >
          <Grid container item justify='center' style={{ padding: '1rem' }}>
            <Typography variant='h5' style={{ textAlign: 'center' }}>
              Something went wrong.
              <br /> Please try again
            </Typography>
          </Grid>
          <Grid container item justify='center'>
            <Button
              variant='contained'
              color='primary'
              style={{ color: 'white', textShadow: '1px black' }}
              onClick={refresher}
            >
              Try Again
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Component />
      )}
    </div>
  );
};

RefresherComponent.propTypes = {
  refresher: PropTypes.func,
  component: PropTypes.elementType,
  status: PropTypes.bool,
};
export { RefresherComponent };
