import { makeStyles } from '@material-ui/core/styles';
import { black_color } from './types';

const drawerWidth = 240;


const ContentViewStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [`${theme.breakpoints.up('md')} and (orientation: landscape)`]: {
      display: 'flex'
    }
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: '#fff !important',
    color: black_color  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    iconHover:{
      '&:hover': {
          background: '#32FBFB',
       }
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: black_color
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

export { ContentViewStyles };
