/**
 * Style Name: CustomSelectStyle
 * Description: Styles for org dropdown in users & campaign
 * Param: void
 * Return: class styles
 * Author: Nelly
 * Last Update By:
 */

const CustomSelectStyle = {
  singleValue: (provided) => ({
    ...provided,
    width: '90%',
  }),
};

export { CustomSelectStyle };
