/**
 * Class Name: OrganizationForm
 * Description: OrganizationForm component.
 * Param: N/A
 * Return: OrganizationForm Component
 * Author: Shirwyn
 * Last Update: RJ
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Typography,
  TextField,
  Button,
  Grid,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core';
import { convertTimestamp } from '../../helpers';
import { OrganizationFormStyles } from './OrganizationFormStyles';
import { Loader } from '../../components';

const OrganizationForm = ({
  submitOrganization,
  formType,
  orgName,
  awsSecret,
  createdBy,
  dateCreated,
  handleInputChange,
  campaignRate,
  sgmRate,
  hasError,
  hasDuplicateOrg,
  submitLoading,
  loaderColor,
  onCancel,
  formatInput,
  btnText,
}) => {
  const classes = OrganizationFormStyles();
  const type = formType === 'Submit' ? 'create' : 'edit';
  const inputProps = {
    step: 0.01,
    min: 0,
  };
  return (
    <FormControl className={classes.form} id='organization-form'>
      <Grid container item direction='column' xs={12}>
        <Grid item xs className={classes.gridMargin}>
          <Typography variant='body1' className={classes.label}>
            Organization Name
          </Typography>
          <TextField
            autoFocus
            className={classes.textField}
            name='org_name'
            value={orgName}
            onChange={handleInputChange}
            margin='normal'
            variant='outlined'
            placeholder='Organization'
            error={
              (orgName === '' && type !== 'create') ||
              (orgName === '' && type === 'create' && hasError) ||
              (orgName !== '' && hasDuplicateOrg === true)
            }
            id='org-name-input'
            onBlur={formatInput}
          />
          {(orgName === '' && type !== 'create') ||
          (orgName === '' && type === 'create' && hasError) ? (
            <FormHelperText id='orgNameEmpty' error>
              Organization name is required
            </FormHelperText>
          ) : null}
          {orgName !== '' && hasDuplicateOrg ? (
            <FormHelperText id='orgNameExists' error>
              Organization name already exists
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs className={classes.gridMargin}>
          <Typography variant='body1' className={classes.label}>
            Campaign Rate
          </Typography>

          <TextField
            className={classes.textField}
            name='campaignRate'
            value={campaignRate}
            onChange={handleInputChange}
            margin='normal'
            variant='outlined'
            placeholder='0.00'
            type='number'
            InputProps={{
              inputProps,
              startAdornment: (
                <InputAdornment position='start'>₱</InputAdornment>
              ),
            }}
            error={
              (campaignRate === '' && type !== 'create') ||
              (campaignRate === '' && type === 'create' && hasError) ||
              (campaignRate <= 0 && type !== 'create') ||
              (campaignRate <= 0 && type === 'create' && hasError)
                ? true
                : false
            }
            id='campaignRate-input'
          />
          {(campaignRate === '' && type !== 'create') ||
          (campaignRate === '' && type === 'create' && hasError) ? (
            <FormHelperText error>Rate is required</FormHelperText>
          ) : null}
          {(campaignRate <= 0 && type !== 'create') ||
          (campaignRate <= 0 && type === 'create' && hasError) ? (
            <FormHelperText error>Rate must be greater than 0</FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs className={classes.gridMargin}>
          <Typography variant='body1' className={classes.label}>
            SGM Rate
          </Typography>

          <TextField
            className={classes.textField}
            name='sgmRate'
            value={sgmRate}
            onChange={handleInputChange}
            margin='normal'
            variant='outlined'
            placeholder='0.00'
            type='number'
            InputProps={{
              inputProps,
              startAdornment: (
                <InputAdornment position='start'>₱</InputAdornment>
              ),
            }}
            error={
              (sgmRate === '' && type !== 'create') ||
              (sgmRate === '' && type === 'create' && hasError) ||
              (sgmRate <= 0 && type !== 'create') ||
              (sgmRate <= 0 && type === 'create' && hasError)
                ? true
                : false
            }
            id='sgmRate-input'
          />
          {(sgmRate === '' && type !== 'create') ||
          (sgmRate === '' && type === 'create' && hasError) ? (
            <FormHelperText error>Rate is required</FormHelperText>
          ) : null}
          {(sgmRate <= 0 && type !== 'create') ||
          (sgmRate <= 0 && type === 'create' && hasError) ? (
            <FormHelperText error>Rate must be greater than 0</FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs className={classes.gridMargin}>
          <Typography variant='body1' className={classes.label}>
            AWS Secret
          </Typography>
          <TextField
            className={classes.textField}
            name='aws_secret'
            value={awsSecret}
            onChange={handleInputChange}
            margin='normal'
            variant='outlined'
            placeholder='AWS Secret'
            error={
              (awsSecret === '' && type !== 'create') ||
              (awsSecret === '' && type === 'create' && hasError)
                ? true
                : false
            }
            id='aws-secret-input'
            onBlur={formatInput}
          />
          {(awsSecret === '' && type !== 'create') ||
          (awsSecret === '' && type === 'create' && hasError) ? (
            <FormHelperText error>AWS Secret is required</FormHelperText>
          ) : null}
        </Grid>
        {type === 'edit' ? (
          <div>
            <Typography variant='body1' className={classes.label}>
              Created by
            </Typography>
            <Typography
              variant='body1'
              className={classes.data}
              id='createdby-data'
            >
              {createdBy}
            </Typography>
            <Typography variant='body1' className={classes.label}>
              Date Created
            </Typography>
            <Typography
              variant='body1'
              className={classes.data}
              id='date-created-data'
            >
              {convertTimestamp(dateCreated)}
            </Typography>
          </div>
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            id='organization-form-button'
            variant='contained'
            className={classes.button}
            onClick={submitOrganization}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <div className={classes.loader}>
                <Loader
                  size={70}
                  adjustSmall={window.screen.width <= 720}
                  color={loaderColor}
                />
              </div>
            ) : (
              formType
            )}
          </Button>
          <Button
            onClick={onCancel}
            variant='contained'
            className={classes.CancelBtn}
          >
            {btnText}
          </Button>
        </div>
      </Grid>
    </FormControl>
  );
};

//Define proptypes used by OrganizationForm
OrganizationForm.propTypes = {
  submitOrganization: PropTypes.func,
  formType: PropTypes.string,
  orgName: PropTypes.string,
  awsSecret: PropTypes.string,
  createdBy: PropTypes.string,
  dateCreated: PropTypes.string,
  handleInputChange: PropTypes.func,
  priceRate: PropTypes.string,
  hasError: PropTypes.bool,
  hasDuplicateOrg: PropTypes.bool,
};

export { OrganizationForm };
