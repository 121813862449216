/**
 * Class Name: CampaignPage
 * Description: Renders CampaignPage container
 * Param: void
 * Return: CampaignPage
 * Author: Gian
 * Last Update By: RJ
 */
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  CampaignSubHeader,
  CampaignView,
  CampaignForm,
  CampaignSideDrawer,
  Loader,
  ConfirmationModal,
  OrganizationSuggest,
  Pagination,
  CommonTable,
  PageSnackBar,
} from '../../components';
import {
  readCampaigns,
  createCampaigns,
  viewCampaign,
  validateCampaign,
  readOrganizations,
  invoicePrice,
  fileUploadDetails,
  validationStatus,
} from '../../actions';
import {
  VIEW_CAMPAIGNS,
  VIEW_ORGANIZATIONS,
  trimString,
  limitCharacter,
} from '../../helpers';
import { SideDrawerLoaderStyles } from '../../assets/styles';
import { pp_blue_color_loader } from '../../assets/styles/types';
import { OrgDropDownStyles } from '../../assets/styles';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/styles';

const PAGE_SIZE = 10;

class CampaignPage extends Component {
  _isMounted = false;
  _isTableInitialLoad = true;

  constructor(props) {
    super(props);
    this.state = {
      error_message: '',
      error_url: '',
      campaigns: [],
      campInfo: {},
      org: [],
      key: '',
      onView: false,
      campaignName: '',
      orgSelect: '',
      orgID: '',
      csvMessage: '',
      campaignMessage: '',
      campaignId: '',
      orgMessage: '',
      onStatusCampaignName: false, // Campaign Error Status
      onStatusCSV: false, // CSV Error Status
      onStatusOrg: false, // Org Error
      onCreate: false,
      files: null,
      billingAmount: [],
      campKey: '',
      orgKey: '',
      fileName: '',
      convertedFile: null,
      //when to display loader for table component
      isLoading: true,
      loaderColor: pp_blue_color_loader,
      isBillingConfirmed: false,
      isOpen: false,
      disableView: false,
      campaignSubmitDisable: false,
      confirmModalDisable: false,
      isViewLoading: false,
      permissions: [],
      selectedOrganization: null,
      createSelectedOrganization: null,
      organization_names: [],
      selectedOrgStatus: null,
      selectedOrgId: '',
      filterOptions: [],
      selectedFilter: {},
      isScheduledCampaign: false,
      doNotCharge: false,
      scheduledDateTime: moment().add(315, 'seconds'),
      scheduledDateTimeMessage: '',
      snackBarOpen: false,
      snackBarConfig: {
        status: '',
        message: '',
      },
    };
    this.handleClicked = this.handleClicked.bind(this);
    this.onHandleCSV = this.onHandleCSV.bind(this);
    this.validateCampaignInfo = this.validateCampaignInfo.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.openCreate = this.openCreate.bind(this);
    this.closeViewDrawer = this.closeViewDrawer.bind(this);
    this.onRefreshTable = this.onRefreshTable.bind(this);
    this.organizationSearchHandler = this.organizationSearchHandler.bind(this);
    this.createNewCampaign = this.createNewCampaign.bind(this);
    this.campaignTableFilterHandler =
      this.campaignTableFilterHandler.bind(this);
    this.campaignNextPageHandler = this.campaignNextPageHandler.bind(this);
    this.campaignPreviousPageHandler =
      this.campaignPreviousPageHandler.bind(this);
    this.onClickSendAsScheduledCampaign =
      this.onClickSendAsScheduledCampaign.bind(this);
    this.onClickDoNotCharge = this.onClickDoNotCharge.bind(this);
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.setDateTimePickerFieldError =
      this.setDateTimePickerFieldError.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._isTableInitialLoad = true;
    const { activeOrg, inActiveOrg } = OrgDropDownStyles;

    this.setState(
      {
        permissions: this.props.permissions,
        isScheduledCampaign: false,
        doNotCharge: false,
        filterOptions: [
          { label: 'Name', value: 'name' },
          { label: 'Status', value: 'status' },
          { label: 'Created By', value: 'created_by' },
          { label: 'Date Completed', value: 'date_completed' },
          { label: 'Date Created', value: 'created_timestamp' },
        ],
      },
      () => {
        if (!this.state.permissions.includes(VIEW_CAMPAIGNS)) {
          if (this._isMounted) {
            this.setState({
              disableView: true,
            });
          }
        }
        if (!this.state.permissions.includes(VIEW_ORGANIZATIONS)) {
          if (this._isMounted) {
            this.setState(
              {
                selectedOrgId: this.props.organizationId,
                selectedFilter: this.state.filterOptions[0],
              },
              () => {
                this.props
                  .readCampaigns(this.state.selectedOrgId, PAGE_SIZE)
                  .then(() => {
                    if (!this.props.error) {
                      if (this._isMounted) {
                        this.setState(
                          {
                            campaigns: this.props.campaigns,
                            orgID: this.state.selectedOrgId,
                            selectedOrgStatus: 'true',
                          },
                          () => {
                            setTimeout(() => {
                              if (this._isMounted) {
                                this._isTableInitialLoad = false;
                                this.setState({ isLoading: false });
                              }
                            }, 500);
                          }
                        );
                      }
                    } else {
                      this.setState({ isLoading: false });
                      if (this.props.system_error) {
                        this.handlePageSystemAlert('error', this.props.error);
                      }
                    }
                  });
              }
            );
          }
        } else {
          this.props.readOrganizations().then(() => {
            if (this._isMounted) {
              this.setState(
                {
                  userInput: this.props.organizations[0].org_name,
                  org: this.props.organizations,
                  selectedFilter: this.state.filterOptions[0],
                  organization_names: this.props.organizations.map((org) => {
                    let num = 17;
                    if (org.org_name.length >= num) {
                      return {
                        canHaveSuperAdmin: org.can_has_super_admin,
                        value: org.org_id,
                        status: org.active,
                        label:
                          org.active === 'true' ? (
                            <>
                              {org.org_name.slice(0, num) + '...'}{' '}
                              {<span style={activeOrg}>A</span>}
                            </>
                          ) : (
                            <>
                              {org.org_name.slice(0, num) + '...'}{' '}
                              {<span style={inActiveOrg}>IN</span>}
                            </>
                          ),
                      };
                    } else {
                      return {
                        value: org.org_id,
                        canHaveSuperAdmin: org.can_has_super_admin,
                        status: org.active,
                        label:
                          org.active === 'true' ? (
                            <>
                              {org.org_name}
                              {<span style={activeOrg}>A</span>}
                            </>
                          ) : (
                            <>
                              {org.org_name}
                              {<span style={inActiveOrg}>IN</span>}
                            </>
                          ),
                      };
                    }
                  }),
                  selectedOrgStatus: this.props.organizations[0].active,
                },

                () => {
                  this.props
                    .readCampaigns(this.state.org[0].org_id, PAGE_SIZE)
                    .then(() => {
                      if (!this.props.error) {
                        if (this._isMounted) {
                          this.setState(
                            {
                              campaigns: this.props.campaigns,
                              orgID: this.state.org[0].org_id,
                              selectedOrgId: this.state.org[0].org_id,
                              selectedOrganization:
                                this.state.organization_names[0],
                              selectedOrgStatus:
                                this.props.organizations[0].active,
                            },
                            () => {
                              setTimeout(() => {
                                if (this._isMounted) {
                                  this._isTableInitialLoad = false;
                                  this.setState({ isLoading: false });
                                }
                              }, 500);
                            }
                          );
                        }
                      } else {
                        this.setState({ isLoading: false });
                        if (this.props.system_error) {
                          this.handlePageSystemAlert('error', this.props.error);
                        }
                      }
                    });
                }
              );
            }
          });
        }
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Function Name: campaignPreviousPageHandler
   * Description: handler for previous button of Pagination
   * Param: e
   * Return: void
   * Author: Shirwyn
   * Last Update By: Raymart
   */
  campaignPreviousPageHandler(e) {
    e.preventDefault();

    if (this.props.current_page > 1) {
      if (this._isMounted) {
        this.setState(
          {
            isLoading: true,
          },
          () => {
            this.props
              .readCampaigns(
                this.state.orgID,
                PAGE_SIZE,
                this.props.current_page - 1
              )
              .then(() => {
                if (!this.props.error) {
                  if (this._isMounted) {
                    this.setState({
                      campaigns: this.props.campaigns,
                      isLoading: false,
                    });
                  }
                } else {
                  this.setState({ isLoading: false });
                  if (this.props.system_error) {
                    this.handlePageSystemAlert('error', this.props.error);
                  }
                }
              });
          }
        );
      }
    }
  }

  /**
   * Function Name: campaignNextPageHandler
   * Description: handler for next button of Pagination
   * Param: e
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  campaignNextPageHandler(e) {
    e.preventDefault();

    if (this.props.current_page < this.props.page_count) {
      if (this._isMounted) {
        this.setState(
          {
            isLoading: true,
          },
          () => {
            this.props
              .readCampaigns(
                this.state.orgID,
                PAGE_SIZE,
                this.props.current_page + 1
              )
              .then(() => {
                if (!this.props.error) {
                  if (this._isMounted) {
                    this.setState({
                      campaigns: this.props.campaigns,
                      isLoading: false,
                    });
                  }
                } else {
                  this.setState({ isLoading: false });
                  if (this.props.system_error) {
                    this.handlePageSystemAlert('error', this.props.error);
                  }
                }
              });
          }
        );
      }
    }
  }

  /**
   * Function Name: campaignTableFilterHandler
   * Description: handler for Campaign table filter
   * Param: selectedFilter
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  campaignTableFilterHandler(selectedFilter) {
    if (this._isMounted) {
      this.setState({ selectedFilter });
    }
  }

  /**
   * Function Name: organizationSearchHandler
   * Description: handler for OrganizationSuggest select and search component
   * Param: selectedOrganization
   * Return: void
   * Author: Shirwyn
   * Last Update By: Everard
   */
  organizationSearchHandler(selectedOrganization) {
    if (this._isMounted) {
      this.setState(
        {
          selectedOrganization: selectedOrganization,
          orgID: selectedOrganization.value,
          selectedOrgId: selectedOrganization.value,
          selectedOrgStatus: selectedOrganization.status,
          isLoading: true,
        },
        () => {
          this.props.readCampaigns(this.state.orgID, PAGE_SIZE).then(() => {
            if (!this.props.error) {
              if (this._isMounted) {
                this.setState({ campaigns: this.props.campaigns }, () => {
                  setTimeout(() => {
                    if (this._isMounted) {
                      this.setState({ isLoading: false });
                    }
                  }, 500);
                });
              }
            } else {
              this.setState({ isLoading: false });
              if (this.props.system_error) {
                this.handlePageSystemAlert('error', this.props.error);
              }
            }
          });
        }
      );
    }
  }

  /**
   * Component Name: handleClicked
   * Description:
   *      Displays the cell/data in a row of the table
   * Param: campaign id, organization id
   * Return: View
   * Author: Gian
   * Last Update By: Everard
   */
  handleClicked(campId, orgId) {
    if (this._isMounted) {
      this.setState(
        {
          onView: true,
          isViewLoading: true,
          campKey: campId,
          orgKey: orgId,
        },
        () => {
          this.props
            .viewCampaign(this.state.campKey, this.state.orgKey)
            .then(() => {
              if (this._isMounted) {
                this.setState(
                  {
                    selectedOrgId: this.props.campaignInfo.organization_id,
                    campaignId: this.props.campaignInfo.campaign_id,
                    campInfo: this.props.campaignInfo,
                  },
                  () => {
                    setTimeout(() => {
                      if (this._isMounted) {
                        this.setState({
                          isViewLoading: false,
                        });
                      }
                    }, 500);
                  }
                );
              }
            });
        }
      );
    }
  }

  /**
   * Component Name: onHandleCSV
   * Description:
   *      Do not need to convert file to base64 to directly upload to s3
   * Param: event
   * Return: ''
   * Author: Gian
   * Last Update By: Nelly
   */
  onHandleCSV(event) {
    event.persist();

    if (this._isMounted) {
      this.setState({
        onStatusCSV: false,
        csvMessage: '',
      });
    }

    if (event.target.files[0]) {
      if (this._isMounted) {
        this.setState(
          {
            files: event.target.files[0],
            fileName: event.target.files[0].name,
          },
          () => {
            let reader = new FileReader();
            reader.readAsBinaryString(event.target.files[0]);
            reader.onloadend = () => {
              let parsedFile = reader.result;
              if (this._isMounted) {
                this.setState({ convertedFile: parsedFile });
              }
            };
          }
        );
      }
    } else {
      if (this._isMounted) {
        this.setState({
          convertedFile: '',
          onStatusCSV: true,
          csvMessage: this.props.error,
        });
      }
    }
  }

  /**
   * Component Name: handleInput
   * Description:
   *      Handles the Campaign Name
   * Param: event
   * Return: void
   * Author: Gian
   * Last Update By: Everard
   */
  handleInput(event) {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: limitCharacter(event.target.value, 70),
      });
    }

    if (!event.target.value) {
      if (this._isMounted) {
        this.setState({
          onStatusCampaignName: true,
          campaignMessage: 'Campaign Name is required',
        });
      }
    }
    event.preventDefault();
  }

  /**
   * Component Name: handleOrgName
   * Description:
   *      Handles the Organization Name and value
   * Param: event
   * Return: void
   * Author: Gian
   * Last Update By: Everard
   */
  handleOrgName(event) {
    if (this._isMounted) {
      this.setState({
        selectedOrgId: event.target.value,
        createSelectedOrganization: {
          value: event.target.value,
          label: event.target.selectedOptions[0].text,
        },
      });
    }

    if (!event.target.value) {
      if (this._isMounted) {
        this.setState({
          onStatusOrg: true,
          orgMessage: 'Organization is required',
        });
      }
    }
    event.preventDefault();
  }

  /**
   * Function Name: validateCampaignInfo
   * Description:
   *      Validates Campaign information using the payload
   * Param: void
   * Return: void
   * Author: Gian
   * Last Update By: Nelly
   */
  validateCampaignInfo() {
    const fileExtension = this.state.fileName;
    const resXLSX = fileExtension.slice(-4);
    const resXLS = fileExtension.slice(-3);
    const result =
      resXLSX === 'xlsx' ? resXLSX : resXLSX === '.xls' ? resXLS : null;

    const payload = {
      file_type: result,
    };

    let campaignId = '';
    if (!this.state.permissions.includes(VIEW_ORGANIZATIONS)) {
      campaignId = this.props.organizationId;
    } else {
      campaignId = this.state.selectedOrgId;
    }

    if (this._isMounted) {
      this.setState(
        {
          campaignSubmitDisable: true,
          selectedOrgId: campaignId,
        },
        () => {
          if (this.state.permissions.includes(VIEW_ORGANIZATIONS)) {
            if (this.state.selectedOrgId === '') {
              if (this._isMounted) {
                this.setState({
                  onStatusOrg: true,
                  campaignSubmitDisable: false,
                  orgMessage: 'Organization is required',
                  isOpen: false,
                });
              }
            }
          }

          if (this.state.campaignName === '') {
            if (this._isMounted) {
              this.setState({
                isOpen: false,
                onStatusCampaignName: true,
                campaignSubmitDisable: false,
                campaignMessage: 'Campaign Name is required',
              });
            }
          }

          if (this.state.convertedFile === '') {
            if (this._isMounted) {
              this.setState({
                isOpen: false,
                onStatusCSV: true,
                campaignSubmitDisable: false,
                csvMessage: 'File is required (*.xls, *.xlsx)',
              });
            }
          }

          if (
            this.state.isScheduledCampaign &&
            (this.state.scheduledDateTimeMessage ||
              this.state.scheduledDateTime === null)
          ) {
            if (this._isMounted) {
              this.setState({
                isOpen: false,
                campaignSubmitDisable: false,
                scheduledDateTimeMessage:
                  this.state.scheduledDateTime !== null
                    ? this.state.scheduledDateTimeMessage
                    : 'Date/Time is required',
              });
            }
          }
          if (this.state.convertedFile !== '') {
            this.props
              .fileUploadDetails(
                payload,
                this.state.selectedOrgId,
                this.state.files
              )
              .then(() => {
                if (
                  this.props.status === 400 ||
                  this.state.fileName.includes('.csv')
                ) {
                  if (this._isMounted) {
                    this.setState({
                      isOpen: false,
                      campaignSubmitDisable: false,
                      onStatusCSV: true,
                      csvMessage: 'Only .xls and .xlxs are supported',
                    });
                  }
                } else {
                  if (
                    this.props.status === '' &&
                    this.state.campaignName !== '' &&
                    this.state.selectedOrgId !== '' &&
                    ((this.state.isScheduledCampaign &&
                      !this.state.scheduledDateTimeMessage &&
                      this.state.scheduledDateTime !== null) ||
                      !this.state.isScheduledCampaign)
                  ) {
                    let intervalId = setInterval(() => {
                      this.props
                        .validationStatus(
                          this.state.selectedOrgId,
                          this.props.campaignInfo.campaign_id
                        )
                        .then((response) => {
                          if (
                            response.status === 'Pending' ||
                            response.status === 'Ongoing'
                          ) {
                            if (this._isMounted) {
                              this.setState({
                                error_message: '',
                                error_url: '',
                                csvMessage: '',
                              });
                            }
                          } else if (response.status === 'Failed') {
                            if (this._isMounted) {
                              this.setState({
                                csvMessage:
                                  this.props.error_message ===
                                  'File contains invalid entries'
                                    ? this.props.error_message
                                    : this.props.error,
                                error_url: this.props.error_url,
                                isOpen: false,
                                campaignSubmitDisable: false,
                                onStatusCSV: true,
                              });
                              clearInterval(intervalId);
                            }
                          } else {
                            if (this._isMounted) {
                              this.setState({
                                billingAmount: response.expected_cost,
                                isOpen: !this.state.isOpen,
                                campaignSubmitDisable: false,
                                csvMessage: '',
                                error_message: '',
                                error_url: '',
                              });
                              clearInterval(intervalId);
                            }
                          }
                        });
                    }, 5000);
                  }
                }
              });
          }
        }
      );
    }
  }

  /**
   * Function Name: createNewCampaign
   * Description:
   *      Validates Campaign information using the payload
   * Param: void
   * Return: void
   * Author: Gian
   * Last Update By: Raymart
   */

  createNewCampaign() {
    let doNotCharge =
      this.props.currentUserRole === 'super_admin'
        ? { do_not_charge: this.state.doNotCharge }
        : null;
    let isScheduled =
      this.state.isScheduledCampaign === true
        ? {
            campaign_scheduled_time: moment
              .utc(this.state.scheduledDateTime)
              .format('YYYY-MM-DD HH:mm')
              .valueOf()
              .toString(),
          }
        : null;

    let payload = {
      campaign_id: this.props.campaignInfo.campaign_id,
      campaign_name: this.state.campaignName,
      ...isScheduled,
      ...doNotCharge,
    };

    if (this._isMounted) {
      this.setState({ confirmModalDisable: true });
    }
    this.props.createCampaigns(payload, this.state.selectedOrgId).then(() => {
      if (!this.props.error) {
        if (this._isMounted) {
          this.setState(
            {
              isOpen: false,
              confirmModalDisable: false,
              campaignSubmitDisable: false,
              onCreate: !this.state.onCreate,
              selectedOrganization: this.state.createSelectedOrganization, // set the selected organization from the create campaign to the campaign list.
              isLoading: true,
            },
            () => {
              this.props
                .readCampaigns(this.state.selectedOrgId, PAGE_SIZE)
                .then(() => {
                  if (!this.props.error) {
                    if (this._isMounted) {
                      this.setState(
                        {
                          campaigns: this.props.campaigns,
                          campaignName: '',
                          convertedFile: '',
                          confirmModalDisable: false,
                          onStatusCampaignName: false,
                          onStatusCSV: false,
                          onStatusOrg: false,
                          csvMessage: '',
                          campaignMessage: '',
                          orgMessage: '',
                          scheduledDateTimeMessage: '',
                        },
                        () => {
                          setTimeout(() => {
                            if (this._isMounted) {
                              this.setState({
                                isLoading: false,
                                confirmModalDisable: false,
                                createSelectedOrganization: null,
                                snackBarOpen: true,
                                snackBarConfig: {
                                  status: 'success',
                                  message: 'Campaign created.',
                                },
                              });
                            }
                          }, 500);
                        }
                      );
                    }
                  } else {
                    this.setState({ isLoading: false });
                    if (this.props.system_error) {
                      this.handlePageSystemAlert('error', this.props.error);
                    }
                  }
                });
            }
          );
        }
      } else {
        this.setState({
          confirmModalDisable: false,
          campaignSubmitDisable: false,
          selectedOrganization: this.state.createSelectedOrganization,
          isOpen: false,
          scheduledDateTimeMessage: this.props.error,
        });

        if (this.props.system_error) {
          this.handlePageSystemAlert('error', this.props.error);
        }
      }
    });
  }

  /**
   * Component Name: onRefreshTable
   * Description:
   *      Refreshes the Campaign Table View
   * Param: void
   * Return: void
   * Author: Gian
   * Last Update By: Everard
   */
  onRefreshTable() {
    let campaignId = '';
    if (!this.state.permissions.includes(VIEW_ORGANIZATIONS)) {
      campaignId = this.props.organizationId;
    } else {
      campaignId = this.state.selectedOrganization.value;
    }

    if (this._isMounted) {
      this.setState(
        {
          selectedOrgId: campaignId,
          isLoading: true,
        },
        () => {
          this.props
            .readCampaigns(
              this.state.selectedOrgId,
              PAGE_SIZE,
              this.props.current_page
            )
            .then(() => {
              if (!this.props.error) {
                if (this._isMounted) {
                  this.setState(
                    {
                      campaigns: this.props.campaigns,
                    },
                    () => {
                      setTimeout(() => {
                        if (this._isMounted) {
                          this.setState({ isLoading: false });
                        }
                      }, 500);
                    }
                  );
                }
              } else {
                this.setState({ isLoading: false });
                if (this.props.system_error) {
                  this.handlePageSystemAlert('error', this.props.error);
                }
              }
            });
        }
      );
    }
  }

  /**
   * Component Name: openCreate
   * Description:
   *      Displays the Campaign Create SideDrawer
   * Param: void
   * Return: void
   * Author: Gian
   * Last Update By: Everard
   */
  openCreate() {
    if (this._isMounted) {
      this.setState({
        org: this.props.organizations,
        onCreate: true,
        convertedFile: '',
        campaignSubmitDisable: false,
        confirmModalDisable: false,
        createSelectedOrganization: this.state.selectedOrganization,
        isScheduledCampaign: false,
        doNotCharge: false,
        scheduledDateTime: moment().add(315, 'seconds'),
      });
    }
  }

  /**
   * Component Name: closeDrawer
   * Description:
   *      Closes the Campaign Create and view SideDrawer by clicking the back button
   * Param: void
   * Return: void
   * Author: Gian
   * Last Update By: Everard
   */
  closeDrawer() {
    if (this._isMounted) {
      this.setState({
        onCreate: false,
        onView: false,
        csvMessage: '',
        orgMessage: '',
        campaignMessage: '',
        campaignSubmitDisable: false,
        onStatusCampaignName: false,
        onStatusCSV: false,
        onStatusOrg: false,
        isBillingConfirmed: false,
        confirmModalDisable: false,
        scheduledDateTimeMessage: '',
        doNotCharge: false,
      });
    }
  }

  /**
   * Component Name: closeViewDrawer
   * Description:
   *      Closes the Campaign View SideDrawer
   * Param: void
   * Return: void
   * Author: Gian
   * Last Update By: Everard
   */
  closeViewDrawer() {
    if (this._isMounted) {
      this.setState({
        onView: false,
      });
    }
  }

  /**
   * Component Name: onClickSendAsScheduledCampaign
   * Description:
   *      Handler for campaign schedule checkbox. True: Scheduled, False: Now
   * Param: void
   * Return: void
   * Author: Everard
   * Last Update By: Everard
   */
  onClickSendAsScheduledCampaign(event) {
    if (this._isMounted) {
      this.setState({
        isScheduledCampaign: event.target.checked,
      });
    }
  }

  onClickDoNotCharge(e) {
    if (this._isMounted) {
      this.setState({
        doNotCharge: e.target.checked,
      });
    }
  }

  /**
   * Component Name: handleDateTimeChange
   * Description: Handler for date/time picker
   * Param: dateTime
   * Return: void
   * Author: Everard
   * Last Update By: Nelly
   */

  handleDateTimeChange(dateTime) {
    if (this._isMounted && dateTime <= moment()) {
      this.setState({
        scheduledDateTime: dateTime,
        scheduledDateTimeMessage: this.props.error,
      });
    } else {
      this.setState({
        scheduledDateTime: dateTime,
        scheduledDateTimeMessage: '',
      });
    }
  }

  setDateTimePickerFieldError(error) {
    if (this._isMounted) {
      this.setState({ scheduledDateTimeMessage: error });
    }
  }

  /**
   * Function Name: handleAlertOncClose
   * Description: Triggers once the alert component requests to be closed according to the duration time
   * Param: handleAlertOnClose
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */

  handleAlertOnClose = () => {
    this.setState({
      snackBarOpen: false,
    });
  };

  /**
   * Function Name: handlePageSystemAlert
   * Description: Triggers once the alert is a system alert
   * Param: type, message
   * Return: void
   * Author: Raymart
   * Last Update By: Raymart
   */

  handlePageSystemAlert = (type, message) => {
    this.setState({
      snackBarOpen: true,
      snackBarConfig: {
        status: type,
        message: message,
      },
    });
  };

  // /**
  //  * Function Name: formatInput
  //  * Description: To remove whitespaces on input
  //  * Param: n/a
  //  * Return: trimmed texts
  //  * Author: Nelly
  //  * Last Update By: Raymart
  //  */
  formatInput = (e) => {
    const attribute = e.target.getAttribute('name');
    this.setState({ [attribute]: trimString(e.target.value) });
  };

  render() {
    const { classes, campaignInfo, organizations, currentUserRole } =
      this.props;
    return (
      <div id='campaign-page'>
        <CampaignSubHeader
          id='campaign-header'
          disabled={true}
          adminAccess={this.state.canHaveSuperAdmin}
          title={'Campaign'}
          btn={'Create Campaign'}
          onCreate={this.openCreate}
          permissions={this.state.permissions}
          onRefresh={this.onRefreshTable}
          orgStatus={this.state.selectedOrgStatus}
          suggestField={
            <OrganizationSuggest
              id='org-suggest'
              organizationName={this.state.selectedOrganization}
              changeHandler={this.organizationSearchHandler}
              organizations={this.state.organization_names}
              permissions={this.state.permissions}
            />
          }
        />
        <div>
          {this._isTableInitialLoad ? null : (
            <Pagination
              id='campaign-pagination'
              customSelectID='campaign-active-filter'
              filterList={this.state.filterOptions}
              selectedFilter={this.state.selectedFilter}
              filterChangeHandler={this.campaignTableFilterHandler}
              startingNumber={this.props.view_start}
              endingNumber={this.props.view_end}
              totalCount={this.props.total_count}
              previousButtonHandler={(e) => this.campaignPreviousPageHandler(e)}
              nextButtonHandler={(e) => this.campaignNextPageHandler(e)}
              isPreviousButtonDisabled={this.props.view_start <= 1}
              isNextButtonDisabled={
                this.props.total_count === this.props.view_end
              }
            />
          )}
          {this.state.isLoading ? (
            <Loader
              id='header-loader'
              adjustSmall={window.screen.width <= 720}
              color={this.state.loaderColor}
            />
          ) : (
            <CommonTable
              id='table-header'
              headerArray={[
                'Name',
                'Status',
                'Progress',
                'Created By',
                'Date Completed',
                'Date Created',
              ]}
              arrangement={[
                'campaign_name',
                'status',
                'progress',
                'created_by',
                'created_timestamp',
                'completed_timestamp',
              ]}
              colWidth={['30%', '10%', '15%', '15%', '15%', '15%']}
              data={this.state.campaigns}
              clickedRowHandler={this.handleClicked}
              page={'campaign'}
            />
          )}
        </div>
        {this.state.onView === true && this.state.disableView === false ? (
          <CampaignSideDrawer
            id='campaign-details-drawer'
            open={this.state.onView}
            title={'Campaign'}
            type={'view'}
            closeDrawer={this.closeDrawer}
          >
            {this.state.isViewLoading === true ? (
              <div className={classes.loader}>
                <Loader
                  id='side-drawer-loader'
                  size={300}
                  adjustSmall={window.screen.width <= 720}
                  color={this.state.loaderColor}
                />
              </div>
            ) : (
              <CampaignView
                id='campaign-view-details'
                campaign_Name={campaignInfo.campaign_name}
                completedTimestamp={campaignInfo.completed_timestamp}
                createdBy={campaignInfo.created_by.name}
                createdTimestamp={campaignInfo.created_timestamp}
                campaignScheduledTime={campaignInfo.campaign_scheduled_time}
                progress={campaignInfo.progress}
                status={campaignInfo.status}
                isViewLoading={this.state.isViewLoading}
              />
            )}
          </CampaignSideDrawer>
        ) : null}
        {this.state.onCreate ? (
          <CampaignSideDrawer
            id='side-drawer-create-campaign'
            open={this.state.onCreate}
            title={'Campaign'}
            type={'create'}
            closeDrawer={this.closeDrawer}
          >
            <CampaignForm
              error_message={this.state.error_message}
              error_url={this.state.error_url}
              id='create-campaign-form'
              formatInput={this.formatInput}
              onCancel={() => {
                this.setState({
                  onCreate: false,
                  campaignName: '',
                  convertedFile: '',
                  isScheduledCampaign: false,
                  do_not_charge: false,
                  scheduledDateTime: moment().add(315, 'seconds'),
                  scheduledDateTimeMessage: '',
                  csvMessage: '',
                  campaignMessage: '',
                });
              }}
              btn={'Cancel'}
              onCampaignStatus={this.state.onStatusCampaignName} //CampaignName Error Handler Status
              onCSVStatus={this.state.onStatusCSV} //CSV Error Handler Status
              onOrgStatus={this.state.onStatusOrg} //Org Error Handler Status
              errorCSVMessage={this.state.csvMessage} //CSV Error Message
              errorOrgMessage={this.state.orgMessage} //Org Error Message
              errorCampaignMessage={this.state.campaignMessage} //Campaign Error Message
              campaignName={this.state.campaignName}
              orgSelect={this.state.selectedOrgId}
              handleName={(event) => this.handleInput(event)}
              handleCSV={this.onHandleCSV}
              onSubmitCSV={this.validateCampaignInfo}
              campaignSubmitDisable={this.state.campaignSubmitDisable}
              handleOrg={(event) => this.handleOrgName(event)}
              loaderColor={this.state.loaderColor}
              selectOptions={organizations}
              permissions={this.state.permissions}
              isScheduledCampaign={this.state.isScheduledCampaign}
              doNotCharge={this.state.doNotCharge}
              onClickSendAsScheduledCampaign={(event) =>
                this.onClickSendAsScheduledCampaign(event)
              }
              onClickDoNotCharge={(e) => {
                this.onClickDoNotCharge(e);
              }}
              scheduledDateTime={this.state.scheduledDateTime}
              handleDateTimeChange={this.handleDateTimeChange}
              errorScheduledDateTimeMessage={
                this.state.scheduledDateTimeMessage
              } // Date/Time Picker Error Message
              dateTimePickerFieldError={this.setDateTimePickerFieldError}
              organization={this.state.organization_names}
              organizationName={this.state.selectedOrganization}
              currentUserRole={currentUserRole}
            />
          </CampaignSideDrawer>
        ) : null}
        <ConfirmationModal
          id='billing-info-modal'
          activePage={'Billing'}
          priceCampaign={`₱${this.state.billingAmount}`}
          confirmCreate={this.createNewCampaign}
          cancelCreate={() => this.setState({ isOpen: false })}
          closeModal={() => this.setState({ isOpen: false })}
          isOpen={this.state.isOpen}
          confirmModalDisable={this.state.confirmModalDisable}
          loaderColor={this.state.loaderColor}
        />

        <PageSnackBar
          id='alert-modal'
          open={this.state.snackBarOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={this.handleAlertOnClose}
          snackBarConfig={this.state.snackBarConfig}
        />
      </div>
    );
  }
}

const mapStatetoProps = ({ campaign, auth, organization, invoice }) => {
  const {
    campaigns,
    total_count,
    current_page,
    page_count,
    view_start,
    view_end,
    error,
    system_error,
    campaignInfo,
    status,
    error_url,
    error_message,
  } = campaign;
  const { IdToken, permissions, organizationId, currentUserRole } = auth;
  const { organizations } = organization;
  const { expected_cost } = invoice;

  return {
    campaigns,
    total_count,
    current_page,
    page_count,
    view_start,
    view_end,
    error,
    system_error,
    status,
    campaignInfo,
    IdToken,
    permissions,
    organizationId,
    organizations,
    expected_cost,
    currentUserRole,
    error_url,
    error_message,
  };
};

export default compose(
  withStyles(SideDrawerLoaderStyles),
  connect(mapStatetoProps, {
    readCampaigns,
    viewCampaign,
    createCampaigns,
    validateCampaign,
    readOrganizations,
    invoicePrice,
    fileUploadDetails,
    validationStatus,
  })
)(CampaignPage);
