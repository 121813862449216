/**
 * Use: List of Styles
 * Description: A list of variables containing constant style values
 */

//colors
export const pp_blue_color = '#6cf';
export const white_color ='#fff';
export const pp_blue_light_color = 'rgba(102,204,255,.25)';
export const error_color = '#ff7675';
export const black_color = '#2c2c2c';
export const gray_color = '#8c8c8c';
export const green_color = '#6ce6a8';
export const dark_blue_color = '#489AC4';
export const white_color_loader = '#ffffff';
export const pp_blue_color_loader = '#66ccff';
export const black_light_color = 'rgba(44, 44, 44, 0.50)';