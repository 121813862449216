import React, { Component } from 'react';
import { Notifier } from '@airbrake/browser';
import { config } from '../../config';
import { Button } from '@material-ui/core';

const {
    SMS_AIRBRAKE_PROJECT_ID,
    SMS_AIRBRAKE_PROJECT_KEY
} = config;

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this.airbrake = new Notifier({
            projectId: SMS_AIRBRAKE_PROJECT_ID,
            projectKey: SMS_AIRBRAKE_PROJECT_KEY
        });

        this.pageRefresh = this.pageRefresh.bind(this);
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // Send error to Airbrake
        this.airbrake.notify({
            error: error,
            params: { info: info }
        });
    }

    pageRefresh() {
        window.location.reload();
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <h1>Something went wrong.</h1>
                    <Button variant="contained" color="primary" onClick={this.pageRefresh} href="#contained-buttons">Refresh</Button>
                </div>
            );
        }
        return this.props.children;
    }
}

export { ErrorBoundary };