/**
 * Component Name: ForgotPasswordComponent
 * Description: Displays the forgot password form
 * Param: onSubmitEmail, isEmailSubmitted, onChangeEmailInput, email, isButtonPressed, loaderForButton, errorEmailMessage, buttonLabel`
 * Return: component
 * Author: Gian
 * Last Update By: Gian
 */
import React from 'react';
import {
    Paper,
    InputLabel,
    FormControl,
    Button,
    FormHelperText

} from '@material-ui/core';
import {FormInput, ForceChangePasswordStyles} from "../../assets/styles";

const ForgotPassword = ({onSubmitEmail, isEmailSubmitted, onChangeEmailInput, email, loaderForButton, errorEmailMessage, buttonLabel, isEmailValid}) => {
    const classes = ForceChangePasswordStyles();
    return (
        <Paper className={classes.formContainer} id="forgot-page-container">
            <img
                src={require("../../assets/sms-full.png")}
                alt="pearlpay"
                className={classes.image}
            />
            <form>
                <div>
                    <FormControl className={classes.margin} id="email-input-container">
                        <InputLabel
                            shrink
                            htmlFor="bootstrap-input"
                            className={classes.inputLabel}
                        >
                            Email
                        </InputLabel>
                        <FormInput
                            name="email"
                            value={email}
                            onChange={onChangeEmailInput}
                        />
                        {
                            errorEmailMessage !== "" ? <FormHelperText error>{errorEmailMessage}</FormHelperText> : null
                        }
                    </FormControl>
                </div>
                <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    color="primary"
                    className={classes.loginButton}
                    onClick={onSubmitEmail}
                    disabled={isEmailSubmitted}
                >
                    {
                        isEmailSubmitted ? <div className={classes.loader}>{loaderForButton}</div> : buttonLabel
                    }
                </Button>
            </form>
        </Paper>
    );
}

export {ForgotPassword};