/**
 * Style Name: LoginPageStyle
 * Description: Styles for Login Page Layout
 * Param: void
 * Return: class styles
 * Author: Je
 * Last Update By: Je
 */

import { pp_blue_color } from './types';


const LoginPageStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100vh",
    minWidth: "10vh",
    backgroundImage: "linear-gradient(45deg, #6cf, #3980CB)"
  },
  margin: {
    width: "240px",
    marginTop: "2px"
  },
  image: {
    height: "8.75rem",
    width: "15.500rem",
    margin: "auto",
    display: "block",
    textAlign: "center",
    padding: "3px"
  },
  formContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 40px",
    borderRadius: "10px",
    maxWidth: "350px",
    textAlign: "center",
    fontSize: "14px"
  },
  errorMessage: {
    textAlign: "center"
  },
  inputLabel: {
    marginTop: "20px",
    textAlign: "center",
    fontWeight: "bold"
  },
  loginButton: {
    width: "240px",
    height: "50px",
    marginTop: "20px",
    backgroundColor: "#6cf",
    color: '#fff',
    fontWeight: "bold",
    fontSize: "18px",
    textTransform: "capitalize",
    '&:hover':{
      backgroundColor: pp_blue_color
    }
  },
  forgotPasslink: {
    marginLeft: "10px"
  },
  loader:{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  },
  form: {
   marginTop: '-20px'
  }
};

export { LoginPageStyles };
