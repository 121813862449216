import React from "react";
import { Fade, Backdrop, Modal } from "@material-ui/core";
import { ModalStyles } from "../../assets/styles";

/**
 * Function Name: getModalStyle
 * Description: Centers modal
 * Return: top, left and transform properties
 * Author: Toni
 * Last Update: Toni
 */

const getChangePassModalStyle = () => {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
};

const ChangePassModal = ({ isModalVisible, handleClose }) => {
    const classes = ModalStyles();
    const [modalStyle] = React.useState(getChangePassModalStyle);
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={isModalVisible}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={isModalVisible}>
                <div style={modalStyle} className={classes.modal}>
                    <h2 className={classes.modalTitle}> Change Password Success </h2>
                    <p className={classes.modaldescription}>
                        Your password has successfully been changed, please login again
                    </p>
                </div>
            </Fade>
        </Modal>
    );
};

export { ChangePassModal, getChangePassModalStyle };