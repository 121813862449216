import { combineReducers } from 'redux';
import AuthReducers from './AuthReducers';
import CampaignReducers from './CampaignReducers';
import OrganizationReducer from './OrganizationReducer';
import DashboardReducer from './DashboardReducer';
import SgmReducers from "./SgmReducers";
import InvoiceReducer from './InvoiceReducer'
import UserReducer from './UserReducer';

export default combineReducers({
  auth: AuthReducers,
  campaign: CampaignReducers,
  organization: OrganizationReducer,
  report: DashboardReducer,
  invoice: InvoiceReducer,
  user: UserReducer,
  sgm: SgmReducers
});
