/**
 * Function Name: convertTimestampToDate
 * Description: Accepts a timestamp value and converts it to a date with the format DD-MM-YY
 * Param: timestamp
 * Return: Date DD-MM-YY
 * Author: Shirwyn
 * Last Update By: Frances
 */
const convertTimestamp = (timestamp) => {
    const pad = (s) => { return (s < 10) ? '0' + s : s; }
    var d = new Date(timestamp);
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-');
}

export { convertTimestamp };