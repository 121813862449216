/**
 * Style Name: InvoiceTableViewStyles
 * Description: Styles for Invoice Table View Component
 * Param: void
 * Return: class styles
 * Author: Jeremiah
 * Last Update By: Jeremiah
 */
import { makeStyles } from '@material-ui/core/styles';
import { pp_blue_color, black_light_color } from './types';
const InvoiceTableViewStyles = makeStyles( theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
      },
      downloadButtonCell:{
          textAlign: 'right !important',
          padding: '14px 40px',
      },
      downloadButton:{
        color: '#fff',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: pp_blue_color
        }
      },
      tableCellAllCaps:{
          textTransform: 'uppercase'
      },
      recentInvoices: {
            color: black_light_color,
            fontSize: '15px',
            fontWeight: 'bold',
            margin: '0'
      }
  }));

export { InvoiceTableViewStyles };