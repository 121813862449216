import { config } from '../config';

const {
  SMS_AUTH_API_URL,
  SMS_ORGANIZATION_API_URL,
  SMS_CAMPAIGN_API_URL,
  SMS_API_VERSION,
  SMS_REPORT_API_URL,
  SMS_INVOICE_API_URL,
  SMS_SGM_API_URL,
  SMS_SGM_REPORT_API_URL,
} = config;
//API Urls
export const SGM_SERVER_ADDRESS = SMS_SGM_API_URL + '/' + SMS_API_VERSION;
export const SGM_REPORT_SERVER_ADDRESS =
  SMS_SGM_REPORT_API_URL + '/' + SMS_API_VERSION;
export const ORGANIZATION_SERVER_ADDRESS =
  SMS_ORGANIZATION_API_URL + '/' + SMS_API_VERSION;
export const AUTH_SERVER_ADDRESS = SMS_AUTH_API_URL + '/' + SMS_API_VERSION;
export const CAMPAIGN_SERVER_ADDRESS =
  SMS_CAMPAIGN_API_URL + '/' + SMS_API_VERSION;
export const SMS_REPORT_SERVER_ADDRESS =
  SMS_REPORT_API_URL + '/' + SMS_API_VERSION;
export const INVOICE_SERVER_ADDRESS =
  SMS_INVOICE_API_URL + '/' + SMS_API_VERSION;
export const USER_SERVER_ADDRESS = SMS_AUTH_API_URL + '/' + SMS_API_VERSION;
//Auth Actions
export const AUTH_LOGIN_SUCCESS = 'auth_login_success';
export const AUTH_PASSWORD_REQUEST = 'auth_force_change_password_request';
export const AUTH_LOGIN_INVALID_CREDENTIALS = 'auth_login_invalid_credentials';
export const AUTH_LOGIN_INVALID_EMAIL_ADDRESS =
  'auth_login_invalid_email_address';
export const AUTH_LOGIN_FAILED = 'auth_login_failed';
export const AUTH_FORCE_CHANGE_PASS_SUCCESS = 'auth_force_change_pass_success';
export const AUTH_FORCE_CHANGE_PASS_FAILED = 'auth_force_change_pass_failed';
export const AUTH_CHANGE_PASS_SUCCESS = 'auth_change_pass_success';
export const AUTH_CHANGE_PASS_INVALID_CREDENTIALS =
  'auth_change_pass_invalid_credentials';
export const AUTH_CHANGE_PASS_INVALID_PASSWORD =
  'auth_change_pass_invalid_password';
export const AUTH_INVALID_PASSWORD_FORMAT = 'auth_invalid_password_format';
export const AUTH_CHANGE_PASS_FAILED = 'auth_change_pass_failed';
export const AUTH_LOGOUT_SUCCESS = 'auth_logout_success';
export const AUTH_LOGOUT_FAILED = 'auth_logout_failed';
export const AUTH_FORGOT_PASSWORD_REQUEST = 'auth_forgot_password_request';
export const AUTH_FORGOT_PASSWORD_REQUEST_FAIL =
  'auth_forgot_password_request_fail';
export const AUTH_FORGOT_PASSWORD_REQUEST_INVALID_EMAIL =
  'auth_forgot_password_request_invalid_email';
export const MAXIMUM_FORGOT_PASSWORD_ATTEMPT =
  'maximum_forgot_password_attempt';
export const UNEXPECTED_ERROR_FORGOT_PASSWORD =
  'unexpected_error_forgot_password';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'auth_forgot_password_success';
export const AUTH_FORGOT_PASSWORD_INVALID_CODE =
  'auth_forgot_password_invalid_code';
export const AUTH_FORGOT_PASSWORD_INVALID_NEW_PASS_FORMAT =
  'auth_forgot_password_invalid_new_pass_format';
export const AUTH_FORGOT_PASSWORD_MISSING_DETAILS =
  'auth_forgot_password_missing_details';
export const AUTH_FORGOT_PASSWORD_USER_DOES_NOT_EXIST =
  'auth_forgot_password_user_does_not_exist';
export const AUTH_FORGOT_PASSWORD_EXPIRED_CODE =
  'auth_forgot_password_expired_code';
export const AUTH_UPDATE = 'auth_update';
export const AUTH_EXPIRY = 'auth_expiry';

//REFRESH TOKEN
export const AUTH_REFRESH_TOKEN_SUCCESS = 'auth_refresh_token_success';
export const AUTH_REFRESH_TOKEN_FAILED = 'auth_refresh_token_failed';
export const AUTH_REFRESH_TOKEN_MISSING_REFRESH_TOKEN =
  'auth_refresh_token_missing_refresh_token';
export const AUTH_REFRESH_TOKEN_BLANK_REFRESH_TOKEN =
  'auth_refresh_token_blank_refresh_token';
export const AUTH_REFRESH_TOKEN_INVALID_USER_CREDENTIALS =
  'auth_refresh_token_invalid_user_credentials';
//Campaign Actions
export const CAMPAIGNS_CREATE_SUCCESS = 'campaigns_create_success';
export const CAMPAIGNS_READ_SUCCESS = 'campaigns_read_success';
export const CAMPAIGN_VIEW_SUCCESS = 'campaigns_view_success';
export const CAMPAIGN_ERROR = 'campaign_error';
export const CAMPAIGN_CREATE_VALIDATE_SUCCESS =
  'campaign_create_validate_success';
export const CAMPAIGN_UPDATE = 'campaign_update';
export const CAMPAIGN_BILLING = 'campaign_billing';
//Organization Actions
export const ORGANIZATION_CREATE_SUCCESS = 'organization_create_success';
export const ORGANIZATION_READ_SUCCESS = 'organization_read_success';
export const ORGANIZATION_UPDATE_SUCCESS = 'organization_update_success';
export const ORGANIZATION_DELETE_SUCCESS = 'organization_delete_success';
export const ORGANIZATION_VIEW_SUCCESS = 'organization_view_success';
export const ORGANIZATION_ERROR = 'organization_error';
export const ORGANIZATION_CREATE_ERROR = 'organization_create_error';
export const ORGANIZATION_EXIST_ERROR = 'organization_exist_error';
export const ORGANIZATION_INACTIVE = 'organization_inactive';
//Dashboard Actions
export const DASHBOARD_READ_CAMPAIGN_SMS_COUNT_SUCCESS =
  'dashboard_read_campaign_sms_count_success';
export const DASHBOARD_READ_SGM_SMS_COUNT_SUCCESS =
  'dashboard_read_sgm_sms_count_success';
export const DASHBOARD_ERROR = 'dashboard_error';
//Invoice Actions
export const INVOICE_READ_SUCCESS = 'invoice_read_success';
export const INVOICE_PRICE = 'invoice_price';
export const INVOICE_ERROR = 'invoice_error';
export const INVOICE_UNBILLED_TOTAL = 'invoice_unbilled_total';
export const INVOICE_READ_LIST_SUCCESS = 'invoice_read_list_success';
export const INVOICE_READ_LIST_ERROR = 'invoice_read_list_error';
export const INVOICE_DOWNLOAD_SUCCESS = 'invoice_download_success';
export const VIEW_INVOICE_SUCCESS = 'view_invoice_success';
export const VIEW_INVOICE_ERROR = 'view_invoice_error';
export const GET_OUTSTANDING_BALANCE_SUCCESS =
  'get_outstanding_balance_success';
export const GET_OUTSTANDING_BALANCE_ERROR = 'get_outstanding_balance_error';
//SGM Actions
export const SGM_READ_SUCCESS = 'sgm_read_success';
export const SGM_ERROR = 'sgm_error';
export const SGM_FILTER_SUCCESS = 'sgm_filter_success';
//USER Actions
export const USER_READ_SUCCESS = 'user_read_success';
export const USER_VIEW_SUCCESS = 'user_view_success';
export const USER_CREATE_SUCCESS = 'user_create_success';
export const USER_UPDATE_SUCCESS = 'user_update_success';
export const USER_DELETE_SUCCESS = 'user_delete_success';
export const USER_EMAIL_EXISTS = 'user_email_exists';
export const USER_DELETE_ERROR = 'user_delete_error';
export const USER_NOT_FOUND = 'user_not_found';
export const USER_ERROR = 'user_error';
// Other Error Action Handlers
export const SYSTEM_ERROR = 'system_error';
export const UNAUTHORIZED_ERROR = 'unauthorized_error';
export const SESSION_EXPIRY_MESSAGE = 'Session Expiry';
