/**
 * Style Name: LoginPageStyle
 * Description: Styles for Login Page Layout
 * Param: void
 * Return: class styles
 * Author: Jeremiah
 * Last Update By: Jeremiah
 */

const SideDrawerLoaderStyles = {
    loader:{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)"
    }
};

export { SideDrawerLoaderStyles };
