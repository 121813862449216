export const config = {
  // API URLs
  SMS_ORGANIZATION_API_URL: process.env.REACT_APP_SMS_ORGANIZATION_API_URL,
  SMS_CAMPAIGN_API_URL: process.env.REACT_APP_SMS_CAMPAIGN_API_URL,
  SMS_AUTH_API_URL: process.env.REACT_APP_SMS_AUTH_API_URL,
  SMS_REPORT_API_URL: process.env.REACT_APP_SMS_REPORT_API_URL,
  SMS_INVOICE_API_URL: process.env.REACT_APP_SMS_INVOICE_API_URL,
  SMS_SGM_API_URL: process.env.REACT_APP_SMS_REPORT_API_URL,
  SMS_SGM_REPORT_API_URL: process.env.REACT_APP_SMS_SGM_API_URL,
  SMS_API_VERSION: process.env.REACT_APP_SMS_API_VERSION,
  SMS_AIRBRAKE_PROJECT_ID: process.env.REACT_APP_SMS_AIRBRAKE_PROJECT_ID,
  SMS_AIRBRAKE_PROJECT_KEY: process.env.REACT_APP_SMS_AIRBRAKE_PROJECT_KEY,
};
