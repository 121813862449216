/**
 * Style Name: CampaignFormStyles
 * Description: Styles for Campaign form Component
 * Param: void
 * Return: class styles
 * Author: Gian
 * Last Update By: Everard
 */
import { makeStyles } from '@material-ui/core/styles';
import {
  pp_blue_color,
  gray_color,
  white_color,
} from '../../assets/styles/types';

const CampaignFormStyles = makeStyles(() => ({
  button: {
    margin: '1rem 0',
    color: white_color,
    textTransform: 'capitalize',
    backgroundColor: pp_blue_color,
    width: '9rem',
    height: '2.2rem',
    '&:hover': {
      backgroundColor: pp_blue_color,
      borderColor: pp_blue_color,
    },
  },
  form: {
    padding: '0 3.5rem',
    display: 'block',
  },
  gridMargin: {
    margin: '1rem 0',
    width: '100%',
  },
  gridMarginBtn: {
    margin: '-.5rem 0',
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  label: {
    fontWeight: 'bold',
    color: gray_color,
  },
  span: {
    fontWeight: 'bold',
    color: 'red',
    textDecoration: 'underline',
    marginLeft: '5px',
  },
  errorCsv: {
    color: 'red',
    marginLeft: '2px',
    fontWeight: 'bold',
  },
  data: {
    fontWeight: 'bold',
    padding: '1rem 0',
  },
  buttonLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  errorMessage: {
    margin: '0',
  },
  divBtn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  errorDivBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '.5rem',
    marginTop: '-1rem',
  }, 
  CancelBtn: {
    backgroundColor: '#c9c9c9',
    marginLeft: '1.5rem',
    margin: '1rem 0',
    color: white_color,
    textTransform: 'capitalize',
    width: '9rem',
    height: '2.2rem',
    '&:hover': {
      backgroundColor: '#c9c9c9',
      borderColor: '#c9c9c9',
    },
  },
  link: {
    fontSize: '12px',
    color: 'red',
    marginTop: '0.25rem',
    display: 'block',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  templateLink: {
    paddingLeft: '23px',
    paddingRight: '28px',
    paddingTop: '7px',
    fontSize: '12px',
    color: white_color,
    marginTop: '1rem',
    marginBottom: '.4rem',
    display: 'relative',
    backgroundColor: pp_blue_color,
    textTransform: 'capitalize',
    height: '2.2rem',
    '&:hover': {
      backgroundColor: pp_blue_color,
      borderColor: pp_blue_color,
    },
  },
 
}));

export default CampaignFormStyles;
