/**
 * Style Name: EmptyTablePlaceholderStyles
 * Description: Styles for EmptyTablePlaceholder Component
 * Param: void
 * Return: class styles
 * Author: Shirwyn
 * Last Update By: Shirwyn
 */
import { makeStyles } from '@material-ui/core/styles';
import { pp_blue_color, white_color } from '../../assets/styles/types';

const EmptyTablePlaceholderStyles = makeStyles(() => ({
    button: {
        margin: '1rem 0',
        color: white_color,
        textTransform: 'capitalize',
        backgroundColor: pp_blue_color,
        width: '9rem',
        height: '2.2rem',
        '&:hover': {
            backgroundColor: pp_blue_color,
            borderColor: pp_blue_color
        }
    },
    content: {
        marginTop: '15rem'
    }
}));

export default EmptyTablePlaceholderStyles;