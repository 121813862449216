import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  login,
  logout,
  formUpdate,
  changePassword,
  forceChangePassword,
  forgotPasswordRequest,
  forgotPasswordConfirm,
  authRefreshToken,
} from '../src/actions/AuthActions';
import { ContentView } from '../src/components';
import {
  white_color_loader,
  pp_blue_color_loader,
} from '../src/assets/styles/types';
import LoginPage from '../src/containers/LoginPage';
import './style.css';
import './App.css';
import { Loader } from './components/Loader';
import { persistor } from './store';
import { SESSION_EXPIRY_MESSAGE } from './actions/types';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewSideDrawer: false,
      viewDropDown: null,
      isAuthenticating: false,
      isLoading: false,
      password: null,
      oldPass: null,
      newPass: null,
      confirmPass: null,
      regExp: true,
      session: null,
      loaderColor: pp_blue_color_loader,
      isClicked: false,
      isEmailEmpty: false,
      isEmailSubmitted: false,
      isCodeSubmitted: false,
      errorEmail: false,
      isPassCodeInvalid: false,
      isNewPassInvalid: false,
      isConfirmNewPassInvalid: false,
      permissions: [],
      email: '',
      selectedOrgId: '',
      forgotPasswordEmail: '',
      errorEmailMessage: '',
      code: '',
      loaderColorWhite: white_color_loader,
      codeError: '',
      newPassError: '',
      confirmNewPassError: '',
      new_password: '',
      confirm_new_password: '',
      buttonLabel: '',
    };

    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
    this.onForceChangePassword = this.onForceChangePassword.bind(this);
    this.handleForceChangePassword = this.handleForceChangePassword.bind(this);
    this.handleViewSideDrawer = this.handleViewSideDrawer.bind(this);
    this.handleViewDropDown = this.handleViewDropDown.bind(this);
    this.onInputChangePassword = this.onInputChangePassword.bind(this);
    this.renderForgotPassword = this.renderForgotPassword.bind(this);
    this.onChangeEmailValue = this.onChangeEmailValue.bind(this);
    this.onChangeCodeInput = this.onChangeCodeInput.bind(this);
    this.onChangeNewPass = this.onChangeNewPass.bind(this);
    this.onChangeConfirmNewPass = this.onChangeConfirmNewPass.bind(this);
    this.handleRegExpForgotPassword =
      this.handleRegExpForgotPassword.bind(this);
  }

  componentDidMount() {
    if (this.props.AccessToken) {
      this.setState({
        isAuthenticating: true,
        permissions: this.props.permissions,
        selectedOrgId: this.props.organizationId,
        viewDropDown: null,
      });
    } else {
      setTimeout(() => {
        persistor.purge();
      }, 1000);
      this.setState({
        isAuthenticating: false,
      });
    }
  }

  /**
   * Function Name: handleEmailInput
   * Description: handler for the status of email input field
   * Param: event
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  handleEmailInput(event) {
    this.props.formUpdate({
      prop: 'authStatus',
      value: '',
    });
    this.props.formUpdate({
      prop: 'ChallengeName',
      value: '',
    });
    this.props.formUpdate({
      prop: 'Session',
      value: '',
    });
    this.props.formUpdate({
      prop: 'IsForgotPassword',
      value: false,
    });
    this.setState({ email: event.target.value });
  }

  /**
   * Function Name: onForceChangePassword
   * Description: handler for the status of the submitted user force change password
   * Param: event
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  onForceChangePassword(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    this.props.formUpdate({
      prop: 'error',
      value: '',
    });
    event.preventDefault();
  }

  /**
   * Function Name: onInputChangePassword
   * Description: handler for the status of the old and new password field
   * Param: event
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  onInputChangePassword(event) {
    this.setState({
      [event.target.name]: event.target.value,
      isClicked: false,
    });
    this.props.formUpdate({
      prop: 'error',
      value: '',
    });
    event.preventDefault();
  }

  /**
   * Function Name: handlePasswordInput
   * Description: handler for the status of password input field
   * Param: event
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  handlePasswordInput(event) {
    this.setState({ password: event.target.value });
    this.props.formUpdate({
      prop: 'authStatus',
      value: '',
    });
    this.props.formUpdate({
      prop: 'ChallengeName',
      value: '',
    });
    this.props.formUpdate({
      prop: 'Session',
      value: '',
    });
    this.props.formUpdate({
      prop: 'IsForgotPassword',
      value: false,
    });
  }

  /**
   * Function Name: handleLogin
   * Description: handler for the status of the submitted user credentials
   * Param: void
   * Return: component
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  handleLogin() {
    const payload = {
      email: this.state.email,
      password: this.state.password,
    };
    if (
      payload.email === null ||
      payload.password === null ||
      payload.email === '' ||
      payload.password === ''
    ) {
      this.setState({
        isAuthenticating: false,
      });
    } else {
      this.props.login(payload).then(() => {
        if (
          this.props.authStatus === 'authorized' &&
          !this.props.ChallengeName
        ) {
          this.setState(
            {
              isAuthenticating: true,
            },
            () => {
              this.setState({
                permissions: this.props.permissions,
                selectedOrgId: this.props.organizationId,
              });
            }
          );
          return <Redirect to='/dashboard' from='/login' />;
        } else {
          this.setState({
            isAuthenticating: false,
          });
        }
      });
    }
  }

  /**
   * Function Name: handleChangePassword
   * Description: handler for changing the old password
   * Param: void
   * Return: component
   * Author: Jeremiah
   * Last Update By: Everard
   */
  handleChangePassword(event) {
    event.preventDefault();
    let strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\\w\\s])(?=.{6,98})'
    );
    let isCorrectFormat = strongRegex.test(this.state.newPass);

    const payload = {
      password: this.state.oldPass,
      new_password: this.state.newPass,
    };

    this.setState({
      isClicked: true,
      regExp: isCorrectFormat,
    });

    if (
      this.state.newPass !== this.state.confirmPass ||
      !this.state.oldPass ||
      !this.state.newPass ||
      !this.state.confirmPass ||
      !isCorrectFormat
    ) {
      this.setState({
        isAuthenticating: true,
      });
    } else {
      this.setState({
        isLoading: true,
      });
      setTimeout(() => {
        this.props.changePassword(payload).then(() => {
          if (this.props.authStatus === '' && !this.props.error) {
            this.setState({
              isAuthenticating: false,
              isLoading: false,
              regExp: false,
              oldPass: false,
              newPass: false,
              confirmPass: false,
              viewSideDrawer: false,
              isClicked: true,
              permissions: [],
              viewDropDown: null,
            });
            this.props.history.push('login');
          } else if (this.props.error === 'Session Expiry') {
            this.setState({
              isAuthenticating: false,
              isLoading: false,
              regExp: false,
              oldPass: false,
              newPass: false,
              confirmPass: false,
              viewSideDrawer: false,
              isClicked: true,
              permissions: [],
              viewDropDown: null,
            });
          } else {
            this.setState({
              isAuthenticating: true,
              isLoading: false,
            });
          }
        });
      }, 500);
    }
  }

  /**
   * Function Name: handleForceChangePassword
   * Description: handler for the status of the submitted new password
   * Param: void
   * Return: component
   * Author: Jeremiah
   * Last Update By: Everard
   */
  handleForceChangePassword() {
    const payload = {
      email: this.state.email,
      new_password: this.state.confirmPass,
      session: this.props.Session,
    };
    if (
      payload.email === null ||
      this.state.newPass === null ||
      this.state.confirmPass === null
    ) {
      this.setState({
        isAuthenticating: false,
      });
    } else {
      this.props.forceChangePassword(payload).then(() => {
        if (this.props.authStatus === 'authorized' && !this.props.error) {
          this.setState({
            permissions: this.props.permissions,
            newPass: null,
            confirmPass: null,
            isAuthenticating: true,
          });
          return <Redirect to='/dashboard' from='/login' />;
        } else {
          this.setState({
            isAuthenticating: false,
          });
        }
      });
    }
  }

  /**
   * Function Name: onChangeEmailValue
   * Description: handler for the status of the email value of forgot password
   * Param: isEmailSubmitted
   * Return: disabled button if email address field is empty
   * Author: Gian
   * Last Update By: Everard
   */
  onChangeEmailValue(event) {
    this.setState({
      email: event.target.value,
    });
    if (event.target.value === '' || event.target.value === null) {
      this.setState({
        isEmailSubmitted: true,
      });
    }
    if (event.target.value !== '' || event.target.value !== null) {
      this.setState({
        isEmailSubmitted: false,
        errorEmailMessage: '',
      });
    }
  }

  /**
   * Function Name: onChangeCodeInput
   * Description: handler for the status of the code value of forgot password
   * Param: void
   * Return: none
   * Author: Gian
   * Last Update By: Gian
   */
  onChangeCodeInput(event) {
    this.setState({
      code: event.target.value,
    });
    if (!event.target.value) {
      this.setState({
        isPassCodeInvalid: true,
        codeError: 'Pass Code is required',
      });
    }
    if (event.target.value !== '') {
      this.setState({
        isPassCodeInvalid: false,
        codeError: '',
      });
    }
  }

  /**
   * Function Name: onChangeNewPass
   * Description: handler for the status of the new password value of forgot password
   * Param: void
   * Return: none
   * Author: Gian
   * Last Update By: Gian
   */
  onChangeNewPass(event) {
    this.setState({
      new_password: event.target.value,
    });
    if (!event.target.value) {
      this.setState({
        isNewPassInvalid: true,
        newPassError: 'New Password is required',
      });
    }
    if (event.target.value !== '') {
      this.setState({
        isNewPassInvalid: false,
        newPassError: '',
      });
    }
    event.preventDefault();
  }

  /**
   * Function Name: onChangeConfirmNewPass
   * Description: handler for the status of the confirm new password value of forgot password
   * Param: void
   * Return: none
   * Author: Gian
   * Last Update By: Gian
   */
  onChangeConfirmNewPass(event) {
    this.setState({
      confirm_new_password: event.target.value,
    });
    if (!event.target.value) {
      this.setState({
        isConfirmNewPassInvalid: true,
        confirmNewPassError: 'Confirm New Password is required',
      });
    }
    if (event.target.value !== '') {
      if (event.target.value !== this.state.new_password) {
        this.setState({
          isConfirmNewPassInvalid: true,
          confirmNewPassError: 'Passwords do not match',
        });
      } else {
        this.setState({
          isConfirmNewPassInvalid: false,
          confirmNewPassError: '',
        });
      }
    }
    event.preventDefault();
  }

  /**
   * Function Name: renderEnterCode
   * Description: onClick function of the forgot password component if the code sent is valid
   * Param: email
   * Return: forgot password change component
   * Author: Gian
   * Last Update By: Everard
   */
  renderEnterCode(event) {
    const payload = {
      email: this.state.email,
    };
    if (this.state.email === '') {
      this.setState({
        errorEmailMessage: 'Email address is required',
      });
    } else {
      this.setState({
        isEmailSubmitted: true,
      });
      this.props.forgotPasswordRequest(payload).then(() => {
        if (this.props.authStatus === 'forgot_password_invalid_email') {
          this.setState({
            isEmailSubmitted: false,
            errorEmailMessage: 'Email address is invalid',
          });
        } else if (
          this.props.authStatus === 'forgot_password_maximum_attempt'
        ) {
          this.setState({
            isEmailSubmitted: false,
            errorEmailMessage:
              'Maximum forgot password attempts has been reached',
          });
        } else {
          this.setState({
            isEmailSubmitted: false,
            errorEmailMessage: '',
          });
        }
      });
    }
    event.preventDefault();
  }

  /**
   * Function Name: renderPassChange
   * Description: onClick function of the forgot password component if the new password and confirm new password is valid
   * Param: code, new_password, confirm_new_password
   * Return: redirects back to login if parameters are valid
   * Author: Gian
   * Last Update By: Gian
   */
  renderPassChange() {
    if (this.state.code === '') {
      this.setState({
        isPassCodeInvalid: true,
        codeError: 'Pass Code is required',
      });
    }
    if (this.state.new_password === '') {
      this.setState({
        isNewPassInvalid: true,
        newPassError: 'New Password is required',
      });
    }
    if (this.state.confirm_new_password === '') {
      this.setState({
        isConfirmNewPassInvalid: true,
        confirmNewPassError: 'Confirm New Password is required',
      });
    }
    if (
      this.state.confirm_new_password !== '' &&
      this.state.code !== '' &&
      this.state.new_password !== ''
    ) {
      if (this.state.confirm_new_password !== this.state.new_password) {
        this.setState({
          isConfirmNewPassInvalid: true,
          confirmNewPassError: 'Passwords do not match',
        });
      } else if (this.state.new_password.length > 98) {
        this.setState({
          isConfirmNewPassInvalid: true,
          confirmNewPassError: 'New Password exceeded maximum length.',
        });
      } else {
        this.handleRegExpForgotPassword(
          this.state.new_password,
          this.state.confirm_new_password
        );
      }
    }
  }

  /**
   * Function Name: renderForgotPassword
   * Description: onClick function of link in the login page
   * Param: none
   * Return: Forgot password component
   * Author: Gian
   * Last Update By: Gian
   */
  renderForgotPassword() {
    this.props.formUpdate({
      prop: 'authStatus',
      value: 'forgot_password_change',
    });
    this.props.formUpdate({ prop: 'IsForgotPassword', value: true });
  }

  /**
   * Function Name: handleLogout
   * Description: handler for logging out
   * Param: event
   * Return: void
   * Author: Jeremiah
   * Last Update By: RJ
   */
  handleLogout(event, expired = false) {
    event && event.preventDefault();
    this.props.logout(expired).then(() => {
      const ISVALID =
        !this.props.error || this.props.error === SESSION_EXPIRY_MESSAGE;
      if (ISVALID) {
        this.setState({
          isAuthenticating: false,
          email: null,
          password: null,
          permissions: [],
          viewDropDown: null,
        });
        this.props.history.push('login');
      }
    });
  }

  /**
   * Function Name: handleRegExpForgotPassword
   * Description: handles checking of password format
   * Param: void
   * Return: void
   * Author: Jeremiah
   * Last Update By: Everard
   */
  handleRegExpForgotPassword() {
    const payload = {
      code: this.state.code,
      email: this.state.email,
      new_password: this.state.new_password,
    };
    let strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\\w\\s])(?=.{6,98})'
    );
    let isCorrectFormat = strongRegex.test(this.state.new_password);

    if (isCorrectFormat === false) {
      this.setState({
        isNewPassInvalid: true,
        newPassError: 'Invalid Password format',
        isConfirmNewPassInvalid: true,
        confirmNewPassError: 'Invalid Password format',
      });
    }
    if (isCorrectFormat === true) {
      this.setState({
        isCodeSubmitted: true,
      });
      this.props.forgotPasswordConfirm(payload).then(() => {
        if (this.props.authStatus === 'forgot_password_invalid_code') {
          this.setState(
            {
              isPassCodeInvalid: true,
              codeError: 'Invalid code',
            },
            () => {
              setTimeout(() => {
                this.setState({ isCodeSubmitted: false });
              }, 500);
            }
          );
        } else if (this.props.authStatus === 'forgot_password_expired_code') {
          this.setState(
            {
              isPassCodeInvalid: true,
              codeError: 'Expired code',
            },
            () => {
              setTimeout(() => {
                this.setState({ isCodeSubmitted: false });
              }, 500);
            }
          );
        } else {
          this.setState(
            {
              code: '',
              new_password: '',
              confirm_new_password: '',
              isCodeSubmitted: false,
              isEmailSubmitted: false,
              errorEmailMessage: '',
              email: '',
            },
            () => {
              setTimeout(() => {
                this.setState({ isCodeSubmitted: false });
              }, 500);
            }
          );
        }
      });
    }
  }

  /**
   * Function Name: handleViewSideDrawer
   * Description: handler for rendering the side drawer
   * Param: event
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  handleViewSideDrawer(event) {
    event.preventDefault();
    this.setState({
      viewSideDrawer: true,
      viewDropDown: null,
      isClicked: false,
      newPass: null,
      oldPass: null,
      confirmPass: null,
    });
  }

  /**
   * Function Name: handleViewDropDown
   * Description: handler for rendering the drop down menu
   * Param: event
   * Return: void
   * Author: Jeremiah
   * Last Update By: Jeremiah
   */
  handleViewDropDown(event) {
    this.setState({
      viewDropDown: event.currentTarget,
    });
  }

  forceRefreshToken(event) {
    this.props.authRefreshToken({
      refresh_token: this.props.RefreshToken,
    });
  }

  render() {
    const {
      authStatus,
      ChallengeName,
      IsForgotPassword,
      Session,
      error,
      AccessToken,
    } = this.props;
    if (!this.state.isAuthenticating) {
      return (
        <LoginPage
          type={'Login'}
          onLogin={(e) => this.handleLogin(e)}
          onEmailChange={this.handleEmailInput}
          onPasswordChange={this.handlePasswordInput}
          forceChangePass={(e) => this.handleForceChangePassword(e)}
          newPass={this.state.newPass}
          confirmPass={this.state.confirmPass}
          authStatus={authStatus}
          auth={this.state.isAuthenticating}
          ChallengeName={ChallengeName}
          IsForgotPassword={IsForgotPassword}
          Session={Session}
          permissions={this.state.permissions}
          onForceChangePassword={(event) => this.onForceChangePassword(event)}
          email={this.state.email}
          password={this.state.password}
          clicked={this.state.isClicked}
          onForgotPassword={this.renderForgotPassword}
          isEmailSubmitted={this.state.isEmailSubmitted}
          handleEmailChange={(event) => this.onChangeEmailValue(event)}
          loaderForButton={<Loader size={80} color={this.state.loaderColor} />}
          loaderForChangePass={
            <Loader size={80} color={this.state.loaderColor} />
          }
          errorEmail={this.state.errorEmail}
          errorEmailMessage={this.state.errorEmailMessage}
          onChangeCodeInput={(event) => this.onChangeCodeInput(event)}
          onPassChange={(event) => this.renderPassChange(event)}
          renderEnterCode={(event) => this.renderEnterCode(event)}
          new_password={this.state.new_password}
          onChangeNewPass={(event) => this.onChangeNewPass(event)}
          confirm_new_password={this.state.confirm_new_password}
          onChangeConfirmNewPass={(event) => this.onChangeConfirmNewPass(event)}
          isCodeSubmitted={this.state.isCodeSubmitted}
          code={this.state.code}
          isPassCodeInvalid={this.state.isPassCodeInvalid}
          codeError={this.state.codeError}
          isNewPassInvalid={this.state.isNewPassInvalid}
          newPassError={this.state.newPassError}
          isConfirmNewPassInvalid={this.state.isConfirmNewPassInvalid}
          confirmNewPassError={this.state.confirmNewPassError}
        />
      );
    } else {
      return (
        <>
          <ContentView
            AccessToken={AccessToken}
            oldPassword={this.state.oldPass}
            newPassword={this.state.newPass}
            confirmPassword={this.state.confirmPass}
            hasError={error}
            loaderColor={this.state.loaderColor}
            onUserLogout={({ event, expired = false }) => {
              this.handleLogout(event, expired);
            }}
            onInputChangePassword={(e) => this.onInputChangePassword(e)}
            submitChangePassword={(e) => this.handleChangePassword(e)}
            viewSideDrawer={this.state.viewSideDrawer}
            viewDropDown={this.state.viewDropDown}
            closeDrawer={() => {
              this.setState({
                viewSideDrawer: false,
              });
            }}
            isViewSideDrawer={(e) => this.handleViewSideDrawer(e)}
            isViewDropDown={(e) => this.handleViewDropDown(e)}
            isCloseDropDown={() => {
              this.setState({
                viewDropDown: null,
              });
            }}
            username={this.props.firstName}
            currentUserRole={this.props.currentUserRole}
            permissions={this.state.permissions}
            isChangePassword={this.state.isClicked}
            regExp={this.state.regExp}
            isLoading={this.state.isLoading}
            forceRefreshToken={(e) => this.forceRefreshToken(e)}
          />
        </>
      );
    }
  }
}

const mapStatetoProps = ({ auth }) => {
  const {
    email,
    password,
    firstName,
    currentUserRole,
    AccessToken,
    RefreshToken,
    authStatus,
    ChallengeName,
    Session,
    error,
    organizationId,
    permissions,
    IsForgotPassword,
    creatableUserRoles,
  } = auth;

  return {
    email,
    password,
    firstName,
    currentUserRole,
    AccessToken,
    RefreshToken,
    authStatus,
    ChallengeName,
    Session,
    error,
    organizationId,
    permissions,
    IsForgotPassword,
    creatableUserRoles,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    login,
    logout,
    formUpdate,
    changePassword,
    forceChangePassword,
    forgotPasswordRequest,
    forgotPasswordConfirm,
    authRefreshToken,
  })(App)
);
