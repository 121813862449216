import {
  INVOICE_READ_SUCCESS,
  INVOICE_PRICE,
  INVOICE_UNBILLED_TOTAL,
  INVOICE_ERROR,
  INVOICE_READ_LIST_SUCCESS,
  INVOICE_READ_LIST_ERROR,
  VIEW_INVOICE_SUCCESS,
  VIEW_INVOICE_ERROR,
  GET_OUTSTANDING_BALANCE_SUCCESS,
  GET_OUTSTANDING_BALANCE_ERROR,
  SYSTEM_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
  invoiceInfo: [],
  invoices: [],
  error: null,
  system_error: false,
  expected_cost: '',
  unbilled_total: '',
  invoice_url: '',
  outstanding_balance: 0.0,
  status: { billStatus: '', invoiceStatus: '' },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case INVOICE_READ_LIST_SUCCESS:
      return {
        ...state,
        invoice_url: '',
        invoices: action.payload,
        system_error: false,
        error: null,
        status: {
          ...state.status,
          invoiceStatus: 200,
        },
      };
    case INVOICE_READ_LIST_ERROR:
      return {
        ...state,
        invoice_url: '',
        invoices: [],
        error: action.payload.error,
        system_error: false,
        status: {
          ...state.status,
          invoiceStatus: action.payload.status,
        },
      };
    case INVOICE_READ_SUCCESS:
      return {
        ...state,
        invoice_url: '',
        invoiceInfo: action.payload,
        system_error: false,
        error: null,
      };
    case INVOICE_PRICE:
      return {
        ...state,
        invoice_url: '',
        expected_cost: action.payload.expected_cost,
        error: null,
        system_error: false,
      };
    case INVOICE_UNBILLED_TOTAL:
      return {
        ...state,
        invoice_url: '',
        unbilled_total: action.payload.unbilled_total,
        error: null,
        system_error: false,
      };
    case INVOICE_ERROR:
      return {
        ...state,
        invoice_url: '',
        invoiceInfo: [],
        error: action.payload.error,
        status: 'invalid_invoice',
        system_error: false,
      };
    case VIEW_INVOICE_SUCCESS:
      return {
        ...state,
        invoice_url: action.payload.invoice_url,
        error: null,
        system_error: false,
      };
    case VIEW_INVOICE_ERROR:
      return {
        ...state,
        invoice_url: '',
        error: action.payload.error,
        system_error: false,
      };
    case GET_OUTSTANDING_BALANCE_SUCCESS:
      return {
        ...state,
        outstanding_balance: action.payload.outstanding_balance,
        error: null,
        system_error: false,
        status: {
          ...state.status,
          billStatus: 200,
        },
      };
    case GET_OUTSTANDING_BALANCE_ERROR:
      return {
        ...state,
        outstanding_balance: 0.0,
        error: action.payload.error,
        status: {
          ...state.status,
          billStatus: action.payload.status,
        },
      };
    case SYSTEM_ERROR: {
      return {
        ...state,
        system_error: true,
        error: action.payload.error,
      };
    }
    default:
      return state;
  }
}
