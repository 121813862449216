/**
 * Class Name: CustomSelect
 * Description: Renders CustomSelect component
 * Param:
 *      customSelectID,
 *      customName
 *      selectedValue,
 *      onChangeHandler,
 *      customOptions,
 *      customClassName,
 *      searchable
 * Return: CustomSelect component
 * Author: Shirwyn
 * Last Update By: Shirwyn
 */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { pp_blue_color } from '../../assets/styles/types';
import { CustomSelectStyle } from './CustomSelectStyle';

const CustomSelect = ({
  customSelectID,
  customName,
  selectedValue,
  onChangeHandler,
  customOptions,
  customClassName,
  searchable,
}) => {
  return (
    <Select
      styles={CustomSelectStyle}
      id={customSelectID}
      name={customName}
      value={selectedValue}
      onChange={onChangeHandler}
      options={customOptions}
      className={customClassName}
      isSearchable={searchable}
      noOptionsMessage={() => 'Organization not found'}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: pp_blue_color,
        },
      })}
    />
  );
};

CustomSelect.propTypes = {
  customSelectID: PropTypes.string,
  customName: PropTypes.string,
  selectedValue: PropTypes.object,
  onChangeHandler: PropTypes.func,
  customOptions: PropTypes.array,
  customClassName: PropTypes.any,
  searchable: PropTypes.bool,
};

export { CustomSelect };
