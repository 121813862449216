/**
 * Class Name: BarGraph
 * Description: Renders BarGraph component
 * Param: void
 * Return: BarGraph component
 * Author: Shirwyn
 * Last Update By: RJ
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { CustomSelect } from '../../components';
import { pp_blue_color, black_light_color } from '../../assets/styles/types';
import { BarGraphStyles } from '../../assets/styles';
import { Grid } from '@material-ui/core';

const BarGraph = ({
  sortSelect,
  selectedSort,
  label,
  sentCount,
  failedCount,
  graphChange,
  selectedGraph,
}) => {
  const classes = BarGraphStyles();
  const sortType = [
    {
      value: 'Daily',
      label: 'Daily',
    },
    {
      value: 'Weekly',
      label: 'Weekly',
    },
    {
      value: 'Monthly',
      label: 'Monthly',
    },
  ];

  const sortView = [
    {
      value: 'View Campaign',
      label: 'View Campaign',
    },
    {
      value: 'View SGM',
      label: 'View SGM',
    },
    {
      value: 'View Both',
      label: 'View Both',
    },
  ];

  const options = {
    tooltips: {
      mode: 'label',
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  const data = {
    labels: label,
    datasets: [
      {
        label: 'Sent',
        backgroundColor: pp_blue_color,
        borderColor: pp_blue_color,
        data: sentCount,
      },
      {
        label: 'Failed',
        backgroundColor: black_light_color,
        borderColor: black_light_color,
        data: failedCount,
      },
    ],
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item lg={3} container>
          <CustomSelect
            id='bar-graph-select'
            customSelectID='bar-graph-select'
            customName='selectedSort'
            customClassName={classes.customSelect}
            selectedValue={selectedSort}
            onChangeHandler={sortSelect}
            customOptions={sortType}
            searchable={false}
          />
        </Grid>
        <Grid item lg={4} container>
          <CustomSelect
            id='sgm-graph-select'
            customSelectID='sgm-graph-select'
            customName='selectedSorts'
            customClassName={classes.customSelect}
            selectedValue={selectedGraph}
            onChangeHandler={graphChange}
            customOptions={sortView}
            searchable={false}
          />
        </Grid>
      </Grid>
      <div className={classes.root}>
        <Bar id='bar-graph' data={data} options={options} />
      </div>
    </div>
  );
};

BarGraph.propTypes = {
  sortSelect: PropTypes.func,
  selectedSort: PropTypes.object,
  label: PropTypes.array,
  sentCount: PropTypes.array,
  failedCount: PropTypes.array,
};

export { BarGraph };
