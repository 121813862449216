/**
 * Style Name: BillingPageStyles
 * Description: Styles for Billing Layout
 * Param: void
 * Return: class styles
 * Author: Jeremiah
 * Last Update By: Jeremiah
 */
import { makeStyles } from '@material-ui/core/styles';

const BillingPageStyles = makeStyles(theme => ({
    graphOrder: {
        order: 2,
        [theme.breakpoints.up("sm")]: {
            order: 1
        }
    },
    billOrder: {
        order: 1,
        [theme.breakpoints.up("sm")]: {
            order: 2
        }
    },
    card: {
        padding: theme.spacing(3, 2),
    },
    select: {
        display: 'block'

    }
}));

export { BillingPageStyles };
