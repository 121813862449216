/**
 * Class Name: Pagination
 * Description: Pagination component.
 * Param: N/A
 * Return: Pagination Component
 * Author: Shirwyn
 * Last Update: Everard
 */
import React from 'react';
import {
  Grid,
  Typography,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { ArrowRight, ArrowLeft, Search } from '@material-ui/icons';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CustomSelect } from '../../components';
import PaginationStyles from './PaginationStyles';
import PaginationStylesOverride from './PaginationStylesOverride';
import PropTypes from 'prop-types';

const Pagination = ({
  keyword,
  searchHandler,
  customSelectID,
  filterList,
  startingNumber,
  endingNumber,
  selectedFilter,
  filterChangeHandler,
  isPreviousButtonDisabled,
  isNextButtonDisabled,
  previousButtonHandler,
  nextButtonHandler,
  // for filtering
  filterOptions = [],
  filterSelectedOption,
  filterOnChangeHandler,
}) => {
  const classes = PaginationStyles();
  const theme = PaginationStylesOverride;
  const toDo = true;
  return (
    <MuiThemeProvider theme={theme}>
      <Grid container className={classes.container}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1} direction='row' justify='flex-start'>
            {filterOptions.length > 0 ? (
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography
                      variant='subtitle2'
                      className={classes.filterLabel}
                    >
                      Filter:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CustomSelect
                      id='active-filter'
                      customSelectID={customSelectID}
                      customName='paginationActiveFilter'
                      selectedValue={filterSelectedOption}
                      onChangeHandler={filterOnChangeHandler}
                      customOptions={filterOptions}
                      customClassName={classes.selectActiveDropdown}
                      searchable={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {toDo ? null : (
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <CustomSelect
                      id='campaign-filter'
                      customSelectID='campaign-select-filter'
                      customClassName={classes.filterWidth}
                      selectedValue={selectedFilter}
                      onChangeHandler={filterChangeHandler}
                      customName='campaign-filter'
                      customOptions={filterList}
                      searchable={false}
                    />
                  </Grid>
                  <Grid item>
                    <OutlinedInput
                      id='search-box'
                      type='text'
                      className={classes.searchField}
                      value={keyword}
                      labelWidth={0}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            id='search-button'
                            onClick={searchHandler}
                            className={classes.searchButton}
                          >
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container direction='row' justify='flex-end'>
            <Grid item>
              <Typography variant='subtitle2' className={classes.pageNumber}>
                Viewing {startingNumber}-{endingNumber}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={classes.previousButton}
                disabled={isPreviousButtonDisabled}
                onClick={previousButtonHandler}
                id='table-nav-previous'
              >
                <ArrowLeft />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                className={classes.nextButton}
                disabled={isNextButtonDisabled}
                onClick={nextButtonHandler}
                id='table-nav-next'
              >
                <ArrowRight />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

Pagination.propTypes = {
  keyword: PropTypes.string,
  searchHandler: PropTypes.func,
  filterList: PropTypes.array,
  totalCount: PropTypes.number,
  startingNumber: PropTypes.number,
  endingNumber: PropTypes.number,
  selectedFilter: PropTypes.object,
  filterChangeHandler: PropTypes.func,
  isPreviousButtonDisabled: PropTypes.bool,
  isNextButtonDisabled: PropTypes.bool,
  previousButtonHandler: PropTypes.func,
  nextButtonHandler: PropTypes.func,
  // for filter
  filterOptions: PropTypes.array,
  filterSelectedOption: PropTypes.object,
  filterOnChangeHandler: PropTypes.func,
};

export { Pagination };
