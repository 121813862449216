import { makeStyles } from '@material-ui/core/styles';
import { green_color, black_color } from './types';

const ConfirmationModalStyles = makeStyles(() => ({
    confirmButton: {
        textTransform: 'capitalize',
        backgroundColor: green_color,
        color: '#fff',
        whiteSpace: 'nowrap',
        width: window.screen.width <= 720 ? '55vw': '20rem',
        '&:hover': {
            backgroundColor: green_color,
            borderColor: green_color
        }
    },
    cancelButton: {
        textTransform: 'capitalize',
        color: black_color,
        opacity: '0.5',
        whiteSpace: 'nowrap',
        backgroundColor: '#c0c0c0',
        width: window.screen.width <= 720 ? '55vw': '20rem',
        margin: '1rem 0',
        '&:hover': {
            backgroundColor: '#c0c0c0',
        }
    },
    dialogTitle: {
        color: black_color,
        textAlign: 'center',
        padding: '0.5rem 1rem',
    },
    content: {
        color: black_color,
        padding: '0.75rem',
        opacity: '0.5',
        maxWidth: '23.5rem',
        fontSize: '14px',
        textAlign: 'center',
        alignSelf: 'center'
    },
    container: {
        justifyContent: 'center',
        overflowX: 'hidden',
        padding: '1rem'
    },
    buttonContainer: {
        margin: '1rem 0',
        textAlign: 'center'
    },
    closeButton: {
        padding: '0!important'
    }
  }));

  export { ConfirmationModalStyles };