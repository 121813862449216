import React from 'react'
import { 
    Dialog, 
    DialogTitle,
    IconButton, 
    Grid, 
    Typography,
    Button
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ConfirmationModalStyles } from '../../assets/styles';
import { Loader } from '../../components';
import propTypes from 'prop-types';

const ConfirmationModal = ({ isOpen, activePage, confirmCreate, cancelCreate, priceCampaign, closeModal, confirmModalDisable, loaderColor }) => {
    const classes = ConfirmationModalStyles();
    return (
        <Dialog open={isOpen} maxWidth='xs' id='confirmation-modal'>
            <Grid container direction='row' className={classes.container}>
                <Grid item xs={1}/>
                    <Grid item xs={10}>
                        <DialogTitle className={classes.dialogTitle} id='confirmation-modal-title'>Confirm {activePage}</DialogTitle>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton className={classes.closeButton} onClick={closeModal} id='confirmation-modal-close-button'>
                            <Close />
                        </IconButton>
                    </Grid>
            </Grid>
            <Typography variant='body1' className={classes.content} id='confirmation-modal-content'>
                Your total fee for this campaign is {priceCampaign}. Would you like to proceed?
            </Typography>
            {
                confirmModalDisable ? <Loader size={150} color={loaderColor}/> :
                    <Grid container direction='row' className={classes.buttonContainer}>
                        <Grid item xs>
                            <Button onClick={() => confirmCreate()} disabled={confirmModalDisable} className={classes.confirmButton}
                                    id='confirmation-modal-confirm-button'>Yes</Button>
                        </Grid>
                        <Grid item xs>
                            <Button onClick={() => cancelCreate()} disabled={confirmModalDisable} className={classes.cancelButton}
                                    id='confirmation-modal-cancel-button'>No</Button>
                        </Grid>
                    </Grid>
            }
        </Dialog>
    );
}

ConfirmationModal.propTypes = {
    isOpen: propTypes.bool,
    activePage: propTypes.string,
    priceCampaign: propTypes.string,
    closeModal: propTypes.func,
    handleDisabled: propTypes.bool,
    confirmCreate: propTypes.func,
    cancelCreate: propTypes.func
}
export { ConfirmationModal }