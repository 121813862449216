import axios from 'axios';
import {
  DASHBOARD_READ_CAMPAIGN_SMS_COUNT_SUCCESS,
  DASHBOARD_READ_SGM_SMS_COUNT_SUCCESS,
  CAMPAIGN_SERVER_ADDRESS,
  SGM_REPORT_SERVER_ADDRESS,
} from './types';
import { stringToDate, errorHandler } from '../helpers';
import { getHeaders } from './AuthActions';

const CAMPAIGN_ROOT_URL = CAMPAIGN_SERVER_ADDRESS;
const SGM_ROOT_URL = SGM_REPORT_SERVER_ADDRESS;
const TIMEZONE = 'Asia/Manila';

/**
 * Action Name: readCampaignReports
 * Description:
 *      retrieve reports for bar graph depending on interval and interval count for Campaign
 * Param: id, interval, interval_count
 * Return: label, sentCount, failedCount
 * Author: Shirwyn
 * Last Update By: RJ
 */
export const readCampaignReports =
  (id, interval, interval_count) => async (dispatch) => {
    try {
      const config = {
        headers: (await getHeaders()).headers,
        params: {
          interval,
          interval_count,
          report_type: 'count_summary',
        },
      };
      const response = await axios.get(
        `${CAMPAIGN_ROOT_URL}/organizations/${id}/campaigns-report/`,
        config
      );
      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_READ_CAMPAIGN_SMS_COUNT_SUCCESS,
          payload: response.data.map((reports) => {
            Object.assign(reports, {
              group_name: stringToDate(reports.group_name, interval),
            });
            return reports;
          }),
        });
      }
    } catch (e) {
      dispatch(errorHandler('Dashboard', e));
    }
  };

/**
 * Action Name: readSGMReports
 * Description:
 *      retrieve reports for bar graph depending on interval and interval count for SGM
 * Param: id, interval, interval_count
 * Return: label, sentCount, failedCount
 * Author: RJ
 * Last Update By: RJ
 */
export const readSGMReports =
  (id, interval, interval_count) => async (dispatch) => {
    try {
      const config = {
        headers: (await getHeaders()).headers,
        params: {
          interval,
          interval_count,
          time_zone: TIMEZONE,
        },
      };
      const response = await axios.get(
        `${SGM_ROOT_URL}/organizations/${id}/sgm-report/`,
        config
      );
      if (response.status === 200) {
        dispatch({
          type: DASHBOARD_READ_SGM_SMS_COUNT_SUCCESS,
          payload: response.data.map((reports) => {
            Object.assign(reports, {
              group_name: stringToDate(reports.group_name, interval),
            });
            return reports;
          }),
        });
      }
    } catch (e) {
      dispatch(errorHandler('Dashboard', e));
    }
  };

/**
 * Action Name: readBothReports
 * Description:
 *      retrieve reports for bar graph depending on interval and interval count for both SGM and Campaign
 * Param: id, interval, interval_count
 * Return: calls both readSGMReports and readCampaignReports functions
 * Author: RJ
 * Last Update By: RJ
 */
export const readBothReports =
  (id, interval, interval_count) => async (dispatch) => {
    try {
      dispatch(readSGMReports(id, interval, interval_count));
      dispatch(readCampaignReports(id, interval, interval_count));
    } catch (e) {
      dispatch(errorHandler('Dashboard', e));
    }
  };
