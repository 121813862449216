import React from 'react';
import moment from 'moment';
import { Typography, Grid } from '@material-ui/core';
import CampaignViewStyles from './CampaignViewStyles';

const CampaignView = ({
  campaign_Name,
  createdBy,
  createdTimestamp,
  completedTimestamp,
  campaignScheduledTime,
  status,
}) => {
  const classes = CampaignViewStyles();

  return (
    <Grid container direction='column' className={classes.sideDrawer}>
      <Grid item xs className={classes.campaignAlign}>
        <Typography
          className={classes.sideDrawerTitle}
          variant='body1'
          id='campaign-name-label'
        >
          Campaign Name
        </Typography>
        <Typography
          className={classes.sideDrawerData}
          style={{ paddingRight: '1rem' }}
          variant='body1'
          id='campaign-name-data'
        >
          {campaign_Name}
        </Typography>
      </Grid>
      <Grid item xs className={classes.campaignAlign}>
        <Typography
          className={classes.sideDrawerTitle}
          variant='body1'
          id='campaign-created-label'
        >
          Created By
        </Typography>
        <Typography
          className={classes.sideDrawerData}
          variant='body1'
          id='campaign-created-data'
        >
          {createdBy}
        </Typography>
      </Grid>
      <Grid item xs className={classes.campaignAlign}>
        <Typography
          className={classes.sideDrawerTitle}
          variant='body1'
          id='campaign-datecreated-label'
        >
          Date Created
        </Typography>
        <Typography
          className={classes.sideDrawerData}
          variant='body1'
          id='campaign-datecreated-data'
        >
          {moment(createdTimestamp).format('llll')}
        </Typography>
      </Grid>
      <Grid item xs className={classes.campaignAlign}>
        <Typography
          className={classes.sideDrawerTitle}
          variant='body1'
          id='campaign-datecreated-label'
        >
          Date Completed
        </Typography>
        <Typography
          className={classes.sideDrawerData}
          variant='body1'
          id='campaign-datecreated-data'
        >
          {completedTimestamp
            ? moment(completedTimestamp)
                .add(1, 'day')
                .subtract(16, 'hours')
                .format('llll')
            : '---'}
        </Typography>
      </Grid>
      <Grid item xs className={classes.campaignAlign}>
        <Typography
          className={classes.sideDrawerTitle}
          variant='body1'
          id='campaign-scheduled-date-label'
        >
          Campaign Scheduled Date
        </Typography>
        <Typography
          className={classes.sideDrawerData}
          variant='body1'
          id='campaign-datecreated-data'
        >
          {campaignScheduledTime
            ? moment(campaignScheduledTime).format('llll')
            : 'Unscheduled'}
        </Typography>
      </Grid>
      <Grid item xs className={classes.campaignAlign}>
        <Typography
          className={classes.sideDrawerTitle}
          variant='body1'
          id='campaign-status-label'
        >
          Status
        </Typography>
        <Typography
          className={classes.sideDrawerData}
          variant='body1'
          id='campaign-status-data'
        >
          {status}
        </Typography>
      </Grid>
    </Grid>
  );
};

export { CampaignView };
